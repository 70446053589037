import { LANG, SHOW_ON } from 'global';
import { MENU_TYPE } from '../HeaderDropdown';

const ItemList = [
  // GOLF COURSE
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.LINK,
    label: 'Booking golf course',
    name: 'booking_golf_course',
    href: `/products/golf-course`,
  },

  // GOLF TOUR
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.DRAWER_DROPDOWN,
    label: 'Golf Tour',
    name: 'booking_golf_tour',
    href: `/products/golf-tour`,
  },

  // TRAVEL
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.DRAWER_DROPDOWN,
    label: 'Travel',
    name: 'booking_travel',
    href: `/products/travel`,
  },

  // BLOG
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.LINK,
    label: 'Blog',
    name: 'blog',
    href: `/products/blog`,
  },

  // CONTACT
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.HASH_LINK,
    label: 'Contact',
    name: 'contact',
    hash: 'footer',
  },

  // LOGIN
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.LINK,
    label: 'Login',
    name: 'login',
    href: `/login`,
  },

  // LANGUAGE
  {
    showOn: [SHOW_ON.DESKTOP, SHOW_ON.MOBILE],
    menuType: MENU_TYPE.POPPER_DROPDOWN,
    name: 'language',
    dropdownList: [
      {
        name: LANG.EN,
        label: 'English',
      },
      {
        name: LANG.VI,
        label: 'Vietnamese',
      },
      {
        name: LANG.ZH,
        label: 'Chinese',
      },
    ],
  },
];

export default ItemList;
