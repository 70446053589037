import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { GEO_GET_LOCATION } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryGeoLocation(request) {
  try {
    const response = yield call(query, apis.getGeoLocation);
    yield put({
      type: request.response.success,
      payload: response.data,
    });
  } catch (error) {
    console.error('Query geo location fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryGeoLocation() {
  yield takeLatest(REQUEST(GEO_GET_LOCATION), doQueryGeoLocation);
}
