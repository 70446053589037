import { makeStyles } from '@material-ui/core/styles';
import HTMLReactParser from 'html-react-parser';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// Core compoents
import Parallax from 'components/Parallax/Parallax';

// REDUX
import { Container, Typography } from '@material-ui/core';
import MainFrame from 'components/MainFrame';
import ReducerHelmet from 'components/ReducerHelmet';
import SuggestedProducts from 'components/suggestedProducts';
import { ReducerStatus } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import { useDispatch, useSelector } from 'react-redux';
import { queryBlogDetail } from 'reduxs/global-actions';
import { getLangString } from 'utils/common';
import styles from './styles';
import './styles.scss';

const useStyles = makeStyles(styles);

function BlogDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const blogList = useSelector(state => state.blogList);
  const blogDetail = useSelector(state => state.blogDetail);
  const { lang } = window;
  const { productPath } = useParams();
  const [blogId, setBlogId] = React.useState(null);

  React.useEffect(() => {
    dispatch(
      queryBlogDetail(
        { customPath: productPath },
        {
          handleSuccess: response => {
            setBlogId(response.data.id);
          },
        }
      )
    );
  }, [window.location.pathname]);

  const suggestedProducts = useMemo(() => {
    if (blogList.data == null || blogDetail.data == null) return null;
    return blogList.data
      .filter(blog => blog.id !== blogDetail.data.id)
      .map(blog => ({
        avatarImageUrl: blog.image,
        title: blog.title,
        subtitle: HTMLReactParser(getLangString(blog.subTitle, lang)),
        href: `product-detail/blog/${blog.customPath[lang]}`,
      }));
  }, [blogList.data, blogDetail.data]);

  return (
    <>
      {blogId && (
        <ReducerHelmet
          option={META_TAG_OPTION.PAGE}
          productType={META_TAG_PRODUCT_TYPE.HOME_PAGE}
          id={blogId}
        />
      )}

      <Parallax image={blogDetail?.image} />
      {blogDetail.status === ReducerStatus.SUCCESS && blogDetail.data ? (
        <MainFrame className={classes.main}>
          <Container className='BlockSection'>
            <Typography variant='h1' className={classes.title}>
              {getLangString(blogDetail.data.title, lang)}
            </Typography>
            <h4 className={classes.txtSubTitle}>{getLangString(blogDetail.data.subTitle, lang)}</h4>
            {HTMLReactParser(getLangString(blogDetail.data.content, lang))}
          </Container>

          <SuggestedProducts products={suggestedProducts} />
        </MainFrame>
      ) : null}
    </>
  );
}

export default BlogDetail;
