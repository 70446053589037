export default {
  filterCheckbox__container: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: '0 !important',
  },
  filterCheckbox__summary: {
    padding: 0,
    minHeight: '25px',
  },
  filterCheckbox__detail: {
    padding: 0,
  },
  filterCheckbox__detail__formControlLabel: {
    marginRight: '10px',
  },
  filterCheckbox__detail__formControlLabel__checkbox: {
    color: '#000',
  },
};
