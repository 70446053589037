import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { cachingParam } from 'utils/DOM';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const authData = useSelector(state => state.authData);

  return (
    <Route
      {...rest}
      render={props => {
        // eslint-disable-next-line react/prop-types
        cachingParam(props?.match?.params);
        return authData.data?.accessToken ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

RestrictedRoute.propTypes = {
  component: PropTypes.elementType,
};

export default RestrictedRoute;
