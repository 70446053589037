import {
  dangerBoxShadow,
  dangerColor,
  defaultFont,
  infoBoxShadow,
  infoColor,
  primaryBoxShadow,
  primaryColor,
  roseBoxShadow,
  roseColor,
  successBoxShadow,
  successColor,
  warningBoxShadow,
  warningColor,
} from 'assets/jss/global-styles';
import tooltip from 'assets/jss/material-kit-react/cores/tooltipsStyle';

const styles = theme => ({
  ...tooltip,
  list: {
    ...defaultFont,
    fontSize: '14px',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '""',
        display: 'block',
        height: '1px',
        marginLeft: '15px',
        backgroundColor: '#e5e5e5',
      },
    },
  },
  listItemText: {
    padding: '0 !important',
  },
  navLink: {
    color: 'black',
    position: 'relative',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'black',
      background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  notificationNavLink: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    top: '4px',
  },
  registerNavLink: {
    top: '3px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
  },
  navLinkActive: {
    color: 'inherit',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  icons: {
    width: '20px',
    height: '20px',
    marginRight: '3px',
  },
  socialIcons: {
    position: 'relative',
    fontSize: '20px !important',
    marginRight: '4px',
  },
  dropdownLink: {
    '&,&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 20px',
    },
  },
  marginRight5: {
    marginRight: '5px',
  },
  blackHover: {
    '&:hover': {
      boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)',
      backgroundColor: '#212121',
      color: '#fff',
    },
  },
  primaryHover: {
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      ...primaryBoxShadow,
    },
  },
  infoHover: {
    '&:hover': {
      backgroundColor: infoColor,
      color: '#FFFFFF',
      ...infoBoxShadow,
    },
  },
  successHover: {
    '&:hover': {
      backgroundColor: successColor,
      color: '#FFFFFF',
      ...successBoxShadow,
    },
  },
  warningHover: {
    '&:hover': {
      backgroundColor: warningColor,
      color: '#FFFFFF',
      ...warningBoxShadow,
    },
  },
  dangerHover: {
    '&:hover': {
      backgroundColor: dangerColor,
      color: '#FFFFFF',
      ...dangerBoxShadow,
    },
  },
  roseHover: {
    '&:hover': {
      backgroundColor: roseColor,
      color: '#FFFFFF',
      ...roseBoxShadow,
    },
  },
});

export default styles;
