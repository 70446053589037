import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sagaMiddlewareFactory from 'redux-saga';
import rootReducer from './global-reducers';
import sagas from './sagas';

const persistConfig = {
  storage,
  key: 'root',
  whiteList: ['authData'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = sagaMiddlewareFactory({
  // sagaMonitor: {
  //   actionDispatched(action) {
  //     console.log('Saga', action.type, action.payload);
  //   },
  // },
});

const composeEnhancers =
  ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

export default store;
