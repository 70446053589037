import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { TRAVEL_QUERY_DETAIL_DATA, TRAVEL_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

export const TravelList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(TRAVEL_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(TRAVEL_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(TRAVEL_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const TravelDetail = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(TRAVEL_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(TRAVEL_QUERY_DETAIL_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(TRAVEL_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
