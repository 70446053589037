import { APP_PRODUCT } from 'constants/Enums';
import i18next from 'i18next';

export const getProductTitle = (appProduct, subName) => {
  const { lang } = window;
  const standardLang = lang.toUpperCase();
  const pathNameMap = {
    [APP_PRODUCT.GOLF_COURSE[standardLang]]: 'Golf Courses',
    [APP_PRODUCT.GOLF_TOUR[standardLang]]: 'Golf Tours',
    [APP_PRODUCT.BLOG[standardLang]]: 'Blogs',
    [APP_PRODUCT.TRAVEL[standardLang]]: 'Travels',
  };
  const langTitle = i18next.t(pathNameMap[appProduct]) + (subName ? ` - ${subName}` : '');

  return langTitle || i18next.t('Product List');
};
