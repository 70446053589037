import { ProductTypeCode } from 'constants/Enums';

export default {
  [ProductTypeCode.GOLF_TEE_TIME]: {
    productField: 'TEE_TIME_UUID',
    idField: 'uuid',
    showVehicleOptions: true,
    title: 'Golf booking',
  },
  [ProductTypeCode.GOLF_PACKAGE]: {
    productField: 'PACKAGE_UUID',
    idField: 'uuid',
    showVehicleOptions: true,
    title: 'Golf package booking',
  },
  [ProductTypeCode.GOLF_TOUR]: {
    productField: 'GOLF_TOUR_DEPARTURE_ID',
    idField: 'id',
    showVehicleOptions: false,
    title: 'Golf tour booking',
  },
  [ProductTypeCode.TRAVEL]: {
    productField: 'TRAVEL_ID',
    idField: 'id',
    showVehicleOptions: false,
    title: 'Travel booking',
  },
};
