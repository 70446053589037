import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { PACKAGE_QUERY_DETAIL_DATA, PACKAGE_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = {
  data: null,
  status: ReducerStatus.REQUEST,
};

// eslint-disable-next-line import/prefer-default-export
export const GolfPackageList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(PACKAGE_QUERY_LIST_DATA):
      return initialState;
    case SUCCESS(PACKAGE_QUERY_LIST_DATA):
      return {
        data: action.payload,
        status: ReducerStatus.SUCCESS,
      };
    case FAILURE(PACKAGE_QUERY_LIST_DATA):
      return {
        data: null,
        status: ReducerStatus.FAILURE,
      };
    default:
      return state;
  }
};

export const GolfPackageDetail = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(PACKAGE_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(PACKAGE_QUERY_DETAIL_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(PACKAGE_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
