import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import {
  LOCATION_CITY_QUERY_LIST_DATA,
  LOCATION_COUNTRY_QUERY_LIST_DATA,
  LOCATION_REGION_QUERY_LIST_DATA,
  LOCATION_RESET_CITY,
  LOCATION_RESET_COUNTRY,
  LOCATION_RESET_STATE,
  LOCATION_STATE_QUERY_LIST_DATA,
} from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

export const RegionList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(LOCATION_REGION_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(LOCATION_REGION_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(LOCATION_REGION_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const CountryList = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_RESET_COUNTRY:
    case REQUEST(LOCATION_COUNTRY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(LOCATION_COUNTRY_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(LOCATION_COUNTRY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const StateList = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_RESET_STATE:
    case REQUEST(LOCATION_STATE_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(LOCATION_STATE_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(LOCATION_STATE_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const CityList = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_RESET_CITY:
    case REQUEST(LOCATION_CITY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(LOCATION_CITY_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(LOCATION_CITY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
