import GridContainer from 'components/Grid/GridContainer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';

import RegularButton from 'components/CustomButtons';

// Import Swiper React components
import { EffectCreative, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/css';
// import 'swiper/css/effect-creative';
// Import Swiper styles
import { Dialog, Hidden, Typography } from '@material-ui/core';
import 'swiper/modules/effect-creative/effect-creative.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/swiper.min.css';

// Icon
import BurstModeIcon from '@material-ui/icons/BurstMode';
import CloseIcon from '@material-ui/icons/Close';
import ThumbsGalleryCarousel from 'components/ThumbsGalleryCarousel';

const creativeEffect = {
  prev: {
    shadow: true,
    translate: ['-20%', 0, -1],
  },
  next: {
    translate: ['100%', 0, 0],
  },
};

const swiperStyle = {
  '--swiper-navigation-color': '#fff',
  '--swiper-pagination-color': '#fff',
};

const ImageCarousel = ({ media }) => {
  const [open, setOpen] = useState(false);
  // const { t } = useTransition();

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GridContainer className='ImageCarousel'>
        <div className='ImageCarousel__Carousel'>
          <Swiper
            grabCursor
            effect='creative'
            creativeEffect={creativeEffect}
            modules={[EffectCreative, Navigation]}
            className='ImageCarousel__Carousel__Swiper'
            navigation
            style={swiperStyle}
            watchSlidesProgress
          >
            {media?.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt='banner-img' />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <Hidden xsDown>
          <div className='ImageCarousel__FullScreenLibrary'>
            {media?.slice(0, 4)?.map((image, index) => (
              <div key={index} className='ImageCarousel__FullScreenLibrary__Item'>
                <img src={image} alt='banner-img' />
              </div>
            ))}
            {media?.slice(5, media.length)?.length > 0 && (
              <RegularButton
                color='white'
                size='sm'
                className='ImageCarousel__FullScreenLibrary__SeeAllButton'
                onClick={onOpen}
              >
                <BurstModeIcon />
                <Typography variant='button' color='textPrimary'>
                  {media?.length} Photos
                </Typography>
              </RegularButton>
            )}
          </div>
        </Hidden>
      </GridContainer>

      <Dialog
        open={open}
        fullScreen
        className='Dialog'
        PaperProps={{
          className: 'Dialog__Paper',
        }}
      >
        <div className='Dialog__Paper__CarouselContainer'>
          <ThumbsGalleryCarousel media={media} />
        </div>
        <RegularButton
          color='github'
          size='lg'
          className='Dialog__Paper__CloseButton'
          onClick={onClose}
        >
          <CloseIcon />
        </RegularButton>
      </Dialog>
    </>
  );
};

ImageCarousel.propTypes = {
  media: PropTypes.arrayOf(PropTypes.string),
};

export default ImageCarousel;
