import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { LOCATION_RESET_CITY, LOCATION_STATE_QUERY_LIST_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryState(request) {
  try {
    yield put({
      type: LOCATION_RESET_CITY,
    });
    const response = yield call(query, apis.getStateList, request.payload);
    const { data } = response;
    yield put({
      type: request.response.success,
      payload: data,
    });
  } catch (error) {
    console.error('Query state data fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryState() {
  yield takeLatest(REQUEST(LOCATION_STATE_QUERY_LIST_DATA), doQueryState);
}
