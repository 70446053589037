import { queryTravelList } from 'reduxs/global-actions';

export default {
  initializeState: {
    search: {
      keyword: '',
      type: 'name',
    },
    filters: {},
  },
  searchTypes: [
    {
      label: 'Name',
      name: 'name',
    },
  ],
  filters: {},
  specialField: {
    product: {
      id: 'id',
      name: 'name',
      avatarUrl: 'avatarImageUrl',
      queryProductBy: 'customPath',
      buttonConfig: {
        label: 'Book Now',
        path: 'travel',
      },
      // getSubTitle: (product, classes, lang) =>
      //   HTMLReactParser(getLangString(product.description, lang)),
      getRegularPrice: product => product.regularPrice.toLocaleString('en'),
      getSalePrice: product => product.salePrice.toLocaleString('en'),
      href: 'product-detail/travel',
    },
    props: {},
  },
  dataKey: 'travelList',
  actions: { queryData: queryTravelList },
};
