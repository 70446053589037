import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';

import { PAYMENT_GET_INFO } from 'reduxs/actions';

// eslint-disable-next-line import/prefer-default-export
export const PaymentInfo = (state = {}, action) => {
  switch (action.type) {
    case REQUEST(PAYMENT_GET_INFO):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(PAYMENT_GET_INFO):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(PAYMENT_GET_INFO):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
