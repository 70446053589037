import ReducerHelmet from 'components/ReducerHelmet';
import { APP_PRODUCT } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import { createContext, useMemo } from 'react';
import useScreenLogic from './ProductListPage.Logic';
import ProductListPageView from './ProductListPage.View';

export const ProductListPageContext = createContext({});

const product = {
  [APP_PRODUCT.GOLF_COURSE.EN]: META_TAG_PRODUCT_TYPE.GOLF_COURSE,
  [APP_PRODUCT.GOLF_COURSE.VI]: META_TAG_PRODUCT_TYPE.GOLF_COURSE,
  [APP_PRODUCT.GOLF_COURSE.ZH]: META_TAG_PRODUCT_TYPE.GOLF_COURSE,

  [APP_PRODUCT.GOLF_TOUR.EN]: META_TAG_PRODUCT_TYPE.GOLF_TOUR,
  [APP_PRODUCT.GOLF_TOUR.VI]: META_TAG_PRODUCT_TYPE.GOLF_TOUR,
  [APP_PRODUCT.GOLF_TOUR.ZH]: META_TAG_PRODUCT_TYPE.GOLF_TOUR,

  [APP_PRODUCT.BLOG.EN]: META_TAG_PRODUCT_TYPE.BLOG,
  [APP_PRODUCT.BLOG.VI]: META_TAG_PRODUCT_TYPE.BLOG,
  [APP_PRODUCT.BLOG.ZH]: META_TAG_PRODUCT_TYPE.BLOG,

  [APP_PRODUCT.TRAVEL.EN]: META_TAG_PRODUCT_TYPE.TRAVEL,
  [APP_PRODUCT.TRAVEL.VI]: META_TAG_PRODUCT_TYPE.TRAVEL,
  [APP_PRODUCT.TRAVEL.ZH]: META_TAG_PRODUCT_TYPE.TRAVEL,
};

function ProductListPage(props) {
  const screenLogic = useScreenLogic(props);
  const mergedProviderValue = useMemo(() => screenLogic, [screenLogic]);

  return (
    <>
      <ReducerHelmet
        option={META_TAG_OPTION.PAGE}
        page={META_TAG_PAGE.PRODUCTS}
        productType={product[screenLogic.appProduct]}
        id={screenLogic.currentCategory?.id || product[screenLogic.appProduct]}
      />
      <ProductListPageContext.Provider value={mergedProviderValue}>
        <ProductListPageView />
      </ProductListPageContext.Provider>
    </>
  );
}

export default ProductListPage;
