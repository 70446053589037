import classNames from 'classnames';
import React from 'react';

// styles
import { makeStyles } from '@material-ui/core/styles';
import { cardTitle } from 'assets/jss/global-styles';
import modalStyle from 'assets/jss/material-kit-react/components/bookingModalStyle';
import imagesStyles from 'assets/jss/material-kit-react/cores/imagesStyle';

// Core compoents
import Button from 'components/CustomButtons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// REDUX
import { Dialog, Divider, Slide } from '@material-ui/core';
import Card from 'components/Card/Card';
import { ProductTypeCode, ReducerStatus } from 'constants/Enums';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDayCodeFromDate } from 'utils';

// icons
import DateRangeIcon from '@material-ui/icons/DateRange';
import TimerIcon from '@material-ui/icons/Timer';
import { useLocation } from 'react-router-dom';
import { getPaymentInfo } from 'reduxs/global-actions';
import styles from './styles';

const useStyles = makeStyles({
  ...imagesStyles,
  ...modalStyle,
  ...styles,
  ...cardTitle,
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
));

// http://localhost:3000/vi/thong-tin-thanh-toan?vpc_Amount=115500000&vpc_Card=970436&vpc_CardNum=970436***0002&vpc_CardUid=INS-ml6Ylv1KjgLgUAB_AQAZLA&vpc_Command=pay&vpc_MerchTxnRef=124&vpc_Merchant=TESTONEPAY31&vpc_Message=Approved&vpc_OrderInfo=GOLF+TOUR+TH%C3%81I+LAN+-+3+NG%C3%80Y+2+%C4%90%C3%8AM+&vpc_PayChannel=WEB&vpc_TransactionNo=PAY-UOql0AF6St-eTCGnTIRJfA&vpc_TxnResponseCode=0&vpc_Version=2&vpc_SecureHash=3C32F0E7BF5A54D07AAF6BCC34D3F01AAE7CF28B6769788F8286D43123992498
function PaymentSuccessPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paymentInfo = useSelector(state => state.paymentInfo);
  const paymentSuccessfully = params.get('vpc_TxnResponseCode') === '0';

  React.useEffect(() => {
    if (params.size > 0 && paymentSuccessfully) {
      const queryParams = {};
      params.forEach((value, key) => {
        queryParams[key] = value;
      });
      dispatch(getPaymentInfo(queryParams));
    }
  }, []);

  const renderProductDetail = item => {
    if (item != null) {
      const isPackageBooking = item.productTypeCode === ProductTypeCode.GOLF_PACKAGE;
      const productItemField = isPackageBooking ? 'productPackage' : 'productTeetime';
      const productItem = item.bookingItem.find(it => it[productItemField] != null)[
        productItemField
      ];

      return (
        <div className='DetailBody'>
          <div className='BookingDetail'>
            <div className='DateChoose'>
              <DateRangeIcon />
              {new Date(item.bookingEndDate) > new Date(item.bookingStartDate) ? (
                <div>
                  <p className='bold'>
                    {getDayCodeFromDate(item.bookingStartDate)} -{' '}
                    {getDayCodeFromDate(item.bookingEndDate)}
                  </p>
                  <p>
                    {moment(item.bookingStartDate).format('DD/MM/YYYY')} -{' '}
                    {moment(item.bookingEndDate).format('DD/MM/YYYY')}
                  </p>
                </div>
              ) : (
                <div>
                  <p className='bold'>{getDayCodeFromDate(item.bookingStartDate)}</p>
                  <p>{moment(item.bookingStartDate).format('DD/MM/YYYY')}</p>
                </div>
              )}
            </div>
            <div className='TimeChoose'>
              <TimerIcon />
              <div>
                <p className='bold'>{isPackageBooking ? 'NO TIME' : productItem.time}</p>
                <p>
                  {isPackageBooking && item.numberOfGuest === 2
                    ? `1 ${t('player')} & 1 ${t('guest')}`
                    : `1 ${t('player')}`}
                </p>
              </div>
            </div>
          </div>
          <div className='Receipt'>
            <table>
              <tbody>
                <tr>
                  <td>Product type:</td>
                  <td className='alignRight'>{item.productTypeCode.replace(/_/g, ' ')}</td>
                </tr>
                {isPackageBooking && item.numOfGuest === 2 && (
                  <tr>
                    <td>Guest:</td>
                    <td className='alignRight'>{1}</td>
                  </tr>
                )}
                <tr>
                  <td>Quantity:</td>
                  <td className='alignRight'>{item.quantity}</td>
                </tr>
                <tr>
                  <td>Unit price:</td>
                  <td className='alignRight'>
                    {productItem.price.toLocaleString('en')} {item.currencyCode}
                  </td>
                </tr>
                {item.bookingItem
                  .filter(it => !it.productTeetime && !it.productPackage)
                  .map((val, idx) => (
                    <tr className='surchargeItem' key={idx}>
                      <td>
                        {isPackageBooking ? '' : val.productTeetimeSurcharge.teetimeSurcharge.name}:
                      </td>
                      <td className='alignRight'>
                        {val.price.toLocaleString('en')} {item.currencyCode}
                      </td>
                    </tr>
                  ))}
                <tr className='receiptLineBlock'>
                  <td colSpan={2}>
                    <Divider aria-label='receiptLine' variant='fullWidth' />
                  </td>
                </tr>
                <tr className='totalBlock'>
                  <td>Total</td>
                  <td className='alignRight'>
                    {item.price.toLocaleString('en')} {item.currencyCode}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      TransitionComponent={Transition}
    >
      <div className={classes.section}>
        <div className={classes.logoContainer}>
          <a href={`${window.location.origin}`}>
            <img
              width='70px'
              src={`${window.location.origin}/img/Global/golfadventure.png`}
              alt='Logo'
            />
          </a>
        </div>
        <Card className={classes.billingContainer}>
          {paymentInfo.status !== ReducerStatus.LOADING && (
            <GridContainer className='CompleteBooking'>
              {paymentInfo.status === ReducerStatus.SUCCESS && paymentInfo.data?.invoice != null ? (
                <>
                  <GridItem
                    md={5}
                    className={classNames({
                      left: true,
                      [classes.paymentStatusContainer]: true,
                    })}
                  >
                    {!paymentSuccessfully && (
                      <h3 className={classNames(classes.paymentTxt, classes.PaymentFailedTxt)}>
                        {t('Payment failed')}
                      </h3>
                    )}

                    <img
                      className={classes.gifImg}
                      src={`${window.location.origin}/gif/payment-${
                        paymentSuccessfully ? 'success' : 'failed'
                      }.gif`}
                      alt='payment gif'
                    />
                  </GridItem>
                  {paymentInfo.data.invoice.vpc_TxnResponseCode === '0' && (
                    <GridItem
                      md={paymentSuccessfully ? 7 : 0}
                      className={classNames({
                        right: true,
                      })}
                      style={{ margin: 0 }}
                    >
                      <GridContainer>
                        <h3 className={classes.SummaryTxt}>{t('Order summary')}</h3>
                      </GridContainer>
                      {renderProductDetail(paymentInfo.data?.booking)}
                    </GridItem>
                  )}
                </>
              ) : (
                <div>
                  <h3>{t('Transaction not found')}</h3>
                </div>
              )}
            </GridContainer>
          )}
        </Card>
        <div className={classes.ButtonContainer}>
          <Button color='info' href={`${window.location.origin}`}>
            {t('Back to homepage')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default PaymentSuccessPage;
