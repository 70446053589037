import { Container, Divider, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Fragment, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductListPageContext } from 'views/ProductListPage';
import { FILTER_INPUT_TYPE } from 'views/ProductListPage/enum';
import FilterCheckbox from '../Picker/FilterCheckboxPicker';
import FilterDatePicker from '../Picker/FilterDatePicker';
import FilterLocationPicker from '../Picker/FilterLocationPicker';
import FilterSliderPicker from '../Picker/FilterSliderPicker';
import styles from './styles';

const useStyles = makeStyles(styles);

const minDistance = 1;

const FilterSideBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, handlers, productMap } = useContext(ProductListPageContext);

  if (productMap.filters == null || Object.keys(productMap.filters).length === 0) return null;

  return (
    <Container className={classes.container}>
      <h3>{t('Filter by')}</h3>
      <Divider />

      {Object.keys(productMap.filters).map((filterKey, index) => {
        const { inputType, label, options, range, valueLabelFormat, getValueFormat } =
          productMap.filters[filterKey];

        switch (inputType) {
          case FILTER_INPUT_TYPE.CHECKBOX:
            return (
              <Fragment key={filterKey}>
                <FilterCheckbox
                  label={label}
                  options={options}
                  onChange={(event, checked) => {
                    const { value } = event.target;
                    const filterValue = state.filters[filterKey];

                    if (checked) {
                      handlers.onFilterChange(
                        filterKey,
                        filterValue != null ? [...filterValue, value] : [value]
                      );
                    } else {
                      handlers.onFilterChange(
                        filterKey,
                        filterValue.filter(filter => filter !== value)
                      );
                    }
                  }}
                  defaultExpanded={index === 0}
                />
                <Divider />
              </Fragment>
            );

          case FILTER_INPUT_TYPE.DATE_PICKER:
            return (
              <Fragment key={filterKey}>
                <FilterDatePicker
                  label={label}
                  value={moment(state.filters[filterKey])}
                  onChange={date => handlers.onFilterChange(filterKey, new Date(date).getTime())}
                />
                <Divider />
              </Fragment>
            );

          case FILTER_INPUT_TYPE.LOCATION_PICKER:
            return (
              <Fragment key={filterKey}>
                <FilterLocationPicker label={label} />
                <Divider />
              </Fragment>
            );

          case FILTER_INPUT_TYPE.SLIDER:
            return (
              <Fragment key={filterKey}>
                <FilterSliderPicker
                  label={label}
                  value={state.filters[filterKey]}
                  max={range.max}
                  min={range.min}
                  step={range.step}
                  valueLabelFormat={valueLabelFormat}
                  getValueFormat={getValueFormat}
                  onChange={(_event, newValue, activeThumb) => {
                    if (!Array.isArray(newValue)) {
                      return;
                    }

                    let result = newValue;

                    if (newValue[1] - newValue[0] < minDistance) {
                      if (activeThumb === 0) {
                        const clamped = Math.min(newValue[0], 100 - minDistance);
                        result = [clamped, clamped + minDistance];
                      } else {
                        const clamped = Math.max(newValue[1], minDistance);
                        result = [clamped - minDistance, clamped];
                      }
                    }

                    handlers.onFilterChange(filterKey, result);
                  }}
                />
                <Divider />
              </Fragment>
            );

          default:
            return null;
        }
      })}
    </Container>
  );
};

export default memo(FilterSideBar);
