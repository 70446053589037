import { Card, CardActions, CardMedia, Typography } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import CardBody from 'components/Card/CardBody';
import RegularButton from 'components/CustomButtons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const ProductFlexCard = ({
  backgroundColor,
  contentChildren,
  regularPrice,
  salePrice,
  avatarImgUrl,
  buttonLabel,
  buttonProps,
}) => {
  const { t } = useTranslation();

  return (
    <Card className='ProductFlexCard' style={{ backgroundColor: backgroundColor || '#e9ecef' }}>
      {avatarImgUrl ? (
        <CardMedia
          component='img'
          image={avatarImgUrl ?? `${process.env.PUBLIC_URL}/images/placeholder.png`}
          alt='Live from space album cover'
          className='ProductFlexCard__Media'
        />
      ) : (
        <Skeleton variant='rect' className='ProductFlexCard__Media' />
      )}

      <CardBody className='ProductFlexCard__Body'>{contentChildren}</CardBody>
      <CardActions className='ProductFlexCard__Actions'>
        <div className='ProductFlexCard__Actions__PriceContainer'>
          {regularPrice != null ? (
            <Typography
              variant='body1'
              className={classNames('ProductFlexCard__Actions__Price', {
                oldPrice: salePrice != null,
              })}
            >
              {regularPrice.toLocaleString('en-US')} VND
            </Typography>
          ) : null}

          {salePrice != null ? (
            <Typography variant='body1' className='ProductFlexCard__Actions__Price newPrice'>
              {salePrice.toLocaleString('en-US')} VND
            </Typography>
          ) : null}
        </div>

        <RegularButton
          className='ProductFlexCard__Actions__Button'
          color='warning'
          round
          size='sm'
          {...buttonProps}
        >
          <Typography variant='button' className='ProductFlexCard__Actions__Button__Text'>
            {t(buttonLabel ?? 'Book Now')}
          </Typography>
          <div className='ProductFlexCard__Actions__Button__IconContainer'>
            <DoubleArrowIcon
              className='ProductFlexCard__Actions__Button__IconContainer__Icon'
              fontSize='small'
            />
          </div>
        </RegularButton>
      </CardActions>
    </Card>
  );
};

ProductFlexCard.propTypes = {
  backgroundColor: PropTypes.string,
  contentChildren: PropTypes.node,
  regularPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  avatarImgUrl: PropTypes.string,
};

export default ProductFlexCard;
