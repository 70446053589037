import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import {
  AUTHENTICATION_QUERY_ACTIVATED,
  AUTHENTICATION_QUERY_REFRESH_TOKEN,
  AUTHENTICATION_QUERY_SIGN_IN,
  AUTHENTICATION_QUERY_SIGN_OUT,
  AUTHENTICATION_QUERY_SIGN_UP,
  AUTHENTICATION_SET_AUTH_TOKEN,
} from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

export const AuthData = (state = initialState, action) => {
  switch (action.type) {
    /* REQUEST */
    case REQUEST(AUTHENTICATION_QUERY_SIGN_UP):
    case REQUEST(AUTHENTICATION_QUERY_SIGN_IN):
    case REQUEST(AUTHENTICATION_QUERY_SIGN_OUT):
    case REQUEST(AUTHENTICATION_QUERY_REFRESH_TOKEN):
      return initialState;
    case REQUEST(AUTHENTICATION_SET_AUTH_TOKEN):
      return {
        ...state,
        data:
          state.data != null
            ? { ...state.data, accessToken: action.payload }
            : { accessToken: action.payload },
      };

    /* FAILED */
    case FAILURE(AUTHENTICATION_QUERY_SIGN_UP):
    case FAILURE(AUTHENTICATION_QUERY_SIGN_IN):
    case FAILURE(AUTHENTICATION_QUERY_REFRESH_TOKEN):
      return {
        status: ReducerStatus.FAILURE,
        data: null,
      };

    /* SUCCESS */
    case SUCCESS(AUTHENTICATION_QUERY_SIGN_UP):
    case SUCCESS(AUTHENTICATION_QUERY_SIGN_IN):
    case SUCCESS(AUTHENTICATION_QUERY_REFRESH_TOKEN):
      return {
        status: ReducerStatus.SUCCESS,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const ActiveUser = (state = { isActive: false, status: ReducerStatus.REQUEST }, action) => {
  switch (action.type) {
    case REQUEST(AUTHENTICATION_QUERY_ACTIVATED):
      return { isActive: false, status: ReducerStatus.REQUEST };

    case FAILURE(AUTHENTICATION_QUERY_ACTIVATED):
      return { isActive: false, status: ReducerStatus.FAILURE };

    case SUCCESS(AUTHENTICATION_QUERY_ACTIVATED):
      return { isActive: true, status: ReducerStatus.SUCCESS };

    default:
      return state;
  }
};
