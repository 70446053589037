import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import {
  GOLF_QUERY_DETAIL_DATA,
  GOLF_QUERY_LIST_BY_CITY_DATA,
  GOLF_QUERY_LIST_BY_CONTINENT_DATA,
  GOLF_QUERY_LIST_BY_COUNTRY_DATA,
  GOLF_QUERY_LIST_BY_STATE_DATA,
  GOLF_QUERY_LIST_DATA,
  GOLF_RESET_LIST_DATA,
} from 'reduxs/actions';
import { generateAction } from 'utils/createApiSaga';

export const queryGolfList = generateAction(GOLF_QUERY_LIST_DATA);

export const queryGolfDetail = generateAction(GOLF_QUERY_DETAIL_DATA);

export const queryGolfListByContinent = payload => ({
  type: REQUEST(GOLF_QUERY_LIST_BY_CONTINENT_DATA),
  payload,
  response: {
    success: SUCCESS(GOLF_QUERY_LIST_BY_CONTINENT_DATA),
    failed: FAILURE(GOLF_QUERY_LIST_BY_CONTINENT_DATA),
  },
});

export const queryGolfListByCountry = payload => ({
  type: REQUEST(GOLF_QUERY_LIST_BY_COUNTRY_DATA),
  payload,
  response: {
    success: SUCCESS(GOLF_QUERY_LIST_BY_COUNTRY_DATA),
    failed: FAILURE(GOLF_QUERY_LIST_BY_COUNTRY_DATA),
  },
});

export const queryGolfListByState = payload => ({
  type: REQUEST(GOLF_QUERY_LIST_BY_STATE_DATA),
  payload,
  response: {
    success: SUCCESS(GOLF_QUERY_LIST_BY_STATE_DATA),
    failed: FAILURE(GOLF_QUERY_LIST_BY_STATE_DATA),
  },
});

export const queryGolfListByCity = payload => ({
  type: REQUEST(GOLF_QUERY_LIST_BY_CITY_DATA),
  payload,
  response: {
    success: SUCCESS(GOLF_QUERY_LIST_BY_CITY_DATA),
    failed: FAILURE(GOLF_QUERY_LIST_BY_CITY_DATA),
  },
});

export const resetGolfData = () => ({
  type: REQUEST(GOLF_RESET_LIST_DATA),
});
