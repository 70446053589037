import en from 'assets/i18n/en.json';
import vi from 'assets/i18n/vi.json';
import zh from 'assets/i18n/zh.json';
import { LANG, LOCAL_LANG } from 'global';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { put, takeLatest } from 'redux-saga/effects';
import { LOCALIZATION_I18N, LOCALIZATION_INIT } from 'reduxs/actions';
import { getKey, setKey } from 'utils/localStorage';

export const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
  zh: {
    translation: zh,
  },
};

const isLanguagePath = langPath =>
  langPath === LANG.VI || langPath === LANG.EN || langPath === LANG.ZH;

const getLangInPath = async () => {
  const langPath = window.location.pathname.split('/')[1];
  if (isLanguagePath(langPath)) {
    return langPath;
  }
  return getKey(LOCAL_LANG) || LANG.VI;
};

function* doInitI18n() {
  const lang = yield getLangInPath();
  window.loadTawkWidget(lang);
  window.lang = lang;
  setKey(LOCAL_LANG, lang);
  yield put({ type: LOCALIZATION_I18N });
  i18n.use(initReactI18next).init(
    {
      lng: lang,
      fallbackLng: LANG.EN,
      resources,
      compatibilityJSON: 'v3', // temporary, see more here https://www.i18next.com/misc/migration-guide
    },
    err => {
      if (err) {
        console.log(err);
      }
    }
  );
}

export default function* watchQueryInitI18n() {
  yield takeLatest(LOCALIZATION_INIT, doInitI18n);
}
