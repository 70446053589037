import { Typography } from '@material-ui/core';
import { queryGolfTourList } from 'reduxs/global-actions';
import { getLangString } from 'utils/common';
import { FILTER_INPUT_TYPE } from '../enum';

export default {
  initializeState: {
    search: {
      keyword: '',
      type: 'name',
    },
    filters: {
      category: null,
      numOfDay: null,
      // departureDate: new Date().getTime(),
    },
  },
  searchTypes: [
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Tour code',
      name: 'tourCode',
    },
  ],
  filters: {
    // transport: {
    //   inputType: FILTER_INPUT_TYPE.CHECKBOX,
    //   label: 'Transport',
    //   options: [
    //     { label: 'Car', value: 'car' },
    //     { label: 'Bus', value: 'bus' },
    //     { label: 'Train', value: 'train' },
    //     { label: 'Airplane', value: 'airplane' },
    //   ],
    // },
    numOfDay: {
      inputType: FILTER_INPUT_TYPE.CHECKBOX,
      label: 'Number of Days',
      options: [
        { label: 'day', value: 1 },
        { label: 'days', value: 2 },
        { label: 'days', value: 3 },
        { label: 'days', value: 4 },
        { label: 'days', value: 5 },
        { label: 'days', value: 6 },
        { label: 'week', value: 7 },
      ],
    },
    // departureDate: {
    //   inputType: FILTER_INPUT_TYPE.DATE_PICKER,
    //   label: 'Departure Date',
    // },
  },
  specialField: {
    product: {
      id: 'id',
      name: 'name',
      avatarUrl: 'avatarImageUrl',
      buttonConfig: {
        label: 'Book Now',
      },
      getSubTitle: (product, classes) => (
        <>
          <Typography variant='caption' className={classes.productContent}>
            {`${product.numOfDay} days ${product.numOfNight} nights`}
          </Typography>
          <Typography variant='caption' className={classes.productContent}>
            {`Available: ${product.golfTourDeparture[0].availableSeats}`}
          </Typography>
          <Typography variant='caption' className={classes.productContent}>
            {`Transport: ${getLangString(product.transport)}`}
          </Typography>
        </>
      ),
      getRegularPrice: product => product?.golfTourDeparture[0]?.price,
      href: 'product-detail/golf-tour',
    },
    props: {},
  },
  dataKey: 'golfTourList',
  actions: { queryData: queryGolfTourList },
};
