import watchQuerySignUp from './QuerySignUp';
import watchQuerySignIn from './QuerySignIn';
import watchQuerySignOut from './QuerySignOut';
import watchQueryRefreshToken from './QueryRefreshToken';
import watchQueryActivated from './QueryActivated';
import watchQueryCheckUsername from './QueryCheckUsername';
import watchQueryCheckEmail from './QueryCheckEmail';

export default {
  watchQuerySignUp,
  watchQuerySignIn,
  watchQuerySignOut,
  watchQueryRefreshToken,
  watchQueryActivated,
  watchQueryCheckUsername,
  watchQueryCheckEmail,
};
