import { Typography } from '@material-ui/core';
import RegularButton, { RegularButtonPropTypes } from 'components/CustomButtons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

// ICONS
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MainFrame from 'components/MainFrame';
import './styles.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ImageCardCluster = ({ title, background, productCard, buttonProps }) => {
  const { t } = useTranslation();

  return (
    <div
      className='ImageCardCluster'
      style={{
        backgroundImage: background && `url(${window.location.origin}/img/Banner/${background})`,
      }}
    >
      <MainFrame className='ImageCardCluster__Container'>
        <Typography variant='h4' className='ImageCardCluster__Container__Title'>
          {t(title)}
        </Typography>

        <Carousel
          responsive={responsive}
          ssr
          infinite
          keyBoardControl
          renderButtonGroupOutside
          arrows
          pauseOnHover
          containerClass='ImageCardCluster__Container__Carousel'
          sliderClass='ImageCardCluster__Container__Carousel__Slider'
          itemClass='ImageCardCluster__Container__Carousel__Slider__Item'
        >
          {productCard ?? <></>}
        </Carousel>

        <div className='ImageCardCluster__Container__ButtonContainer'>
          <RegularButton
            className='ImageCardCluster__Container__ButtonContainer__Button'
            color='Green'
            round
            {...buttonProps}
          >
            <Typography
              variant='button'
              className='ImageCardCluster__Container__ButtonContainer__Button__Text'
            >
              {t('More Products')}
            </Typography>
            <div className='ImageCardCluster__Container__ButtonContainer__Button__IconContainer'>
              <NavigateNextIcon className='ImageCardCluster__Container__ButtonContainer__Button__IconContainer__Icon' />
            </div>
          </RegularButton>
        </div>
      </MainFrame>
    </div>
  );
};

ImageCardCluster.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  productCard: PropTypes.node,
  buttonProps: PropTypes.shape(RegularButtonPropTypes),
};

export default ImageCardCluster;
