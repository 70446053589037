import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  queryCityList,
  queryCountryList,
  queryRegionList,
  queryStateList,
} from 'reduxs/global-actions';
import { ProductListPageContext } from 'views/ProductListPage';
import AutoCompleteComponent from './AutoCompleteComponent';

const FilterLocationPicker = ({ label }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    state: { filters },
    handlers,
  } = useContext(ProductListPageContext);
  const regionList = useSelector(reduxState => reduxState.regionList);
  const countryList = useSelector(reduxState => reduxState.countryList);
  const stateList = useSelector(reduxState => reduxState.stateList);
  const cityList = useSelector(reduxState => reduxState.cityList);

  useEffect(() => {
    dispatch(queryRegionList());
  }, []);

  return (
    <>
      <Typography variant='body1'>{t(label)}</Typography>

      {regionList.status === 'SUCCESS' && regionList.data != null ? (
        <AutoCompleteComponent
          label='Region'
          options={regionList.data}
          defaultValue={regionList.data[0]}
          value={regionList.data.find(it => it.region === filters.region) ?? regionList.data[0]}
          getOptionLabel={option => option.region}
          onChange={(_event, value) => {
            if (value == null) return;
            handlers.onFilterChange('region', value.region);
            dispatch(queryCountryList({ region: value.region }));
          }}
        />
      ) : null}

      {countryList.status === 'SUCCESS' && countryList.data != null ? (
        <AutoCompleteComponent
          label='Country'
          options={countryList.data}
          defaultValue={countryList.data[0]}
          value={
            countryList.data.find(it => it.name.common === filters.country) ?? countryList.data[0]
          }
          getOptionLabel={option => option.name.common}
          onChange={(_event, value) => {
            if (value == null) return;
            handlers.onFilterChange('country', value.name.common);
            dispatch(queryStateList({ country: value.name.common }));
          }}
        />
      ) : null}

      {stateList.status === 'SUCCESS' && stateList.data != null ? (
        <AutoCompleteComponent
          label='State'
          options={stateList.data}
          defaultValue={stateList.data[0]}
          value={stateList.data.find(it => it.name === filters.state) ?? stateList.data[0]}
          getOptionLabel={option => option.name}
          onChange={(_event, value) => {
            if (value == null) return;
            handlers.onFilterChange('state', value.name);
            dispatch(queryCityList({ country: filters.country, state: value.name }));
          }}
        />
      ) : null}

      {cityList.status === 'SUCCESS' && cityList.data != null ? (
        <AutoCompleteComponent
          label='City'
          options={cityList.data}
          defaultValue={cityList.data[0]}
          value={cityList.data.find(it => it === filters.city) ?? cityList.data[0]}
          getOptionLabel={option => option}
          onChange={(_event, value) => {
            if (value == null) return;
            handlers.onFilterChange('city', value);
          }}
        />
      ) : null}
    </>
  );
};

FilterLocationPicker.propTypes = {
  label: PropTypes.string,
};

export default FilterLocationPicker;
