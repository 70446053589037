import React from 'react';
// Import Swiper React components
// import required modules
import PropTypes from 'prop-types';
import 'swiper/swiper.scss';
import FullScreenVideo from './FullScreenVideo';
import ImageCarousel from './ImageCarousel';
import SplitImage from './SplitImage';
import { BannerTypes } from './enum';

const Banner = ({ bannerType, media }) => {
  if (bannerType === BannerTypes.FULL_SCREEN_VIDEO) {
    return <FullScreenVideo />;
  }

  if (bannerType === BannerTypes.SPLIT_IMAGE) {
    return <SplitImage />;
  }

  if (bannerType === BannerTypes.IMAGE_CAROUSEL) {
    return <ImageCarousel media={media} />;
  }

  return null;
};

Banner.defaultProps = {
  bannerType: BannerTypes.FULL_SCREEN_VIDEO,
};

Banner.propTypes = {
  bannerType: PropTypes.oneOf([
    BannerTypes.FULL_SCREEN_VIDEO,
    BannerTypes.SPLIT_IMAGE,
    BannerTypes.IMAGE_CAROUSEL,
  ]),
  media: PropTypes.arrayOf(PropTypes.string),
};

export default Banner;
