import { useRef, useState } from 'react';

const useMergingState = (initialState, propsRef) => {
  const [state, setState] = useState(() => {
    if (propsRef) {
      // eslint-disable-next-line no-param-reassign
      propsRef.current = { ...propsRef.current, ...initialState };
    }
    return initialState;
  });
  const ref = useRef(state);

  const setMergeState = newState => {
    ref.current = {
      ...ref.current,
      ...(typeof newState === 'function' ? newState(ref.current) : newState),
    };
    if (propsRef) {
      // eslint-disable-next-line no-param-reassign
      propsRef.current = { ...propsRef.current, ...ref.current };
    }
    setState(ref.current);
  };

  return [state, setMergeState];
};

export default useMergingState;
