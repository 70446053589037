import { ProductTypeCode } from 'constants/Enums';
import { useFormik } from 'formik';
import useHandlers from 'hooks/useHandlers';
import useLangHistory from 'hooks/useLangHistory';
import useMergingState from 'hooks/useMergingState';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postCreateBooking } from 'reduxs/global-actions/Booking';
import { getVehicleTripList } from 'reduxs/global-actions/Vehicle';
import { calculateTotalWithTaxFee, getLangString } from 'utils/common';
import mapping from './mapping';
import bookingSchema from './schema';

const initialState = {
  vehicleExpanded: false,
};

const useCompleteBookingLogic = () => {
  const dispatch = useDispatch();
  const authData = useSelector(state => state.authData);
  const geoLocation = useSelector(state => state.geoLocation);
  const vehicleTripList = useSelector(state => state.vehicleTripList);
  const { lang } = window;
  const location = useLocation();
  const propState = location.state;
  const { getLangPath } = useLangHistory();

  const propsRef = useRef(initialState);

  const [state, setState] = useMergingState(initialState, propsRef);

  const formik = useFormik({
    initialValues: {
      firstName: authData?.data?.firstName ?? '',
      lastName: authData?.data?.lastName ?? '',
      email: authData?.data?.email ?? '',
      telephone: '',
      handicap: 0,
      surcharges: {
        items: [],
        totalSurchargePrice: 0,
      },
      hotDeal: {
        items: [],
        totalHotDealPrice: 0,
      },
      vehicleTrip: undefined,
      ...calculateTotalWithTaxFee(propState.unitPrice, 5, 10),
    },
    validationSchema: bookingSchema,
    onSubmit: values => {
      const params = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.telephone,
        productTypeCode: propState.productTypeCode,
        bookingStartDate: propState.startDate,
        bookingEndDate: propState.endDate,
        numOfGuest: propState.numOfGuest,
        player: propState.player,
        nonPlayer: propState.nonPlayer,
        quantity: propState.quantity,
        price: values.totalPrice,
        currencyCode: propState.currencyCode ?? 'VND',
        bookingItem: [
          {
            field: mapping[propState.productTypeCode].productField,
            uuid: propState.product[mapping[propState.productTypeCode].idField],
            price: propState.unitPrice,
            quantity: 1,
          },
          ...(values.vehicleTrip != null
            ? [
                {
                  field: values.vehicleTrip.field,
                  uuid: values.vehicleTrip.uuid,
                  price: values.vehicleTrip.price,
                  quantity: values.vehicleTrip.quantity,
                },
              ]
            : []),
          ...values.surcharges.items.map(el => ({
            field: el.field,
            uuid: el.uuid,
            price: el.price,
            quantity: el.quantity,
          })),
          ...values.hotDeal.items.map(el => ({
            field: el.field,
            uuid: el.uuid,
            price: el.price,
            quantity: el.quantity,
          })),
        ],
        Title: mapping[propState.productTypeCode].title,
        vpc_OrderInfo: getLangString(propState.product.productName)?.slice(0, 34),
        AgainLink: window.location.href,
        vpc_ReturnURL: window.location.origin + `/${lang}${getLangPath('payment-info')}`,
        vpc_Locale: (lang === 'vi' && 'vn') || 'en',
        vpc_TicketNo: geoLocation?.data?.IPv4 || '',
      };

      dispatch(postCreateBooking(params));
    },
  });

  useEffect(() => {
    if (mapping[propState.productTypeCode].showVehicleOptions) {
      dispatch(getVehicleTripList());
    }
  }, [propState, dispatch]);

  const handlers = useHandlers({
    onChange: e => {
      const { name } = e.target;
      let { value } = e.target;
      if (name === 'telephone') {
        if (value.substring(0, 1) === '(') value = value.replace(value.substring(0, 6), 0);
        if (value.substring(0, 2) === '00') value = value.replace('00', '0');
        value = value.replace(/ /g, '');
      }
      if (name === 'handicap') {
        if (Number(value) <= 0) {
          value = 0;
        } else {
          value = Number(value) > 54 ? 54 : Number(value);
        }
      }
      formik.setFieldValue(name, value);
    },

    onSurchargeCheckedChange: (e, checked) => {
      const surchargeValues = formik.values.surcharges;
      let currUnitPrice = propState.unitPrice + surchargeValues.totalSurchargePrice;

      const value = JSON.parse(e.target.value);
      if (checked) {
        currUnitPrice += value.price;
        surchargeValues.items.push({
          label: value.teetimeSurcharge.name,
          field:
            propState.productTypeCode === ProductTypeCode.GOLF_TEE_TIME
              ? 'TEE_TIME_SURCHARGE_PRICE_UUID'
              : 'GOLF_PACKAGE_CLASS_UUID',
          uuid: value.uuid,
          price: value.price,
          quantity: 1,
        });
        surchargeValues.totalSurchargePrice += value.price;
      } else {
        currUnitPrice -= value.price;
        surchargeValues.items = surchargeValues.items.filter(item => item.uuid !== value.uuid);
        surchargeValues.totalSurchargePrice -= value.price;
      }

      formik.setValues({
        ...formik.values,
        ...calculateTotalWithTaxFee(currUnitPrice, 5, 10),
        surcharge: surchargeValues,
      });
    },

    onVehicleTripChange: (_event, value, reason) => {
      if (reason === 'select-option') {
        const currUnitPrice = propState.unitPrice + value.price;

        formik.setValues({
          ...formik.values,
          ...calculateTotalWithTaxFee(currUnitPrice, 5, 10),
          vehicleTrip: {
            label: 'Vehicle trip',
            field: 'VEHICLE_TRIP_ID',
            uuid: value.id,
            price: value.price,
            quantity: 1,
            vehicle: value.vehicle,
            pickupLocation: value.pickupLocation,
            dropoffLocation: value.dropoffLocation,
          },
        });
      } else {
        formik.setFieldValue('vehicleTrip', undefined);
      }
    },

    onHotDealCheckedChange: (e, checked) => {
      const hotDealValues = formik.values.hotDeal;
      let currUnitPrice = propState.unitPrice + hotDealValues.totalHotDealPrice;
      const value = JSON.parse(e.target.value);

      if (checked) {
        currUnitPrice += value.price;
        hotDealValues.items.push({
          label: getLangString(value.content, lang),
          field: 'HOT_DEAL_DISCOUNT_CONTENT_UUID',
          uuid: value.uuid,
          price: value.price,
          quantity: 1,
        });
        hotDealValues.totalHotDealPrice += value.price;
      } else {
        currUnitPrice -= value.price;
        hotDealValues.items = hotDealValues.items.filter(item => item.uuid !== value.uuid);
        hotDealValues.totalHotDealPrice -= value.price;
      }

      formik.setFieldValue({
        ...formik.values,
        ...calculateTotalWithTaxFee(currUnitPrice, 5, 10),
        hotDeal: hotDealValues,
      });
    },

    onVehicleExpandedChange: () => {
      setState(!propsRef.current.vehicleExpanded);
    },
  });

  return {
    state,
    formik,
    handlers,
    propState,
    store: {
      authData,
      geoLocation,
      vehicleTripList,
      lang,
    },
  };
};

export default useCompleteBookingLogic;
