import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { AUTHENTICATION_QUERY_SIGN_UP } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQuerySignUp(request) {
  try {
    const response = yield call(query, apis.postSignUp, request.payload);
    const { data } = response;

    if (data != null) {
      yield put({
        type: request.response.success,
        payload: request.payload,
      });
    }
    yield request.callback?.handleSuccess != null && request.callback.handleSuccess();
  } catch (error) {
    console.error('Query sign up failed', error);
    yield put({
      type: request.response.failed,
    });
    yield request.callback?.handleFailed != null &&
      request.callback.handleFailed({ message: 'INTERNAL_SERVER_ERROR' });
  }
}

export default function* watchQuerySignUp() {
  yield takeLatest(REQUEST(AUTHENTICATION_QUERY_SIGN_UP), doQuerySignUp);
}
