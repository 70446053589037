const Styles = {
  productTitle: {
    fontWeight: 'bold',
  },

  productContent: {
    display: 'flex',
  },
};

export default Styles;
