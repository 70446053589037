export default {
  // baseURI:
  //   process.env.NODE_ENV === 'production'
  //   ? 'https://api.golfadventurebooking.com'
  //   : 'http://localhost:4000',
  baseURI: 'https://api.golfadventurebooking.com',
  apiPath: '/api/v1',
  defaultTimezone: 'Asia/Ho_Chi_Minh',
  geo: 'https://geolocation-db.com/json/',
  ga: 'G-ECYNWJ97C2',
  tawk: {
    propertyId: '61eec1dab9e4e21181bba555',
    widgetIdMap: {
      en: '1hlb3b596',
      vi: '1fq6aafjl',
      zh: '1hlb3esn6',
    },
  },
  regex: {
    // eslint-disable-next-line no-useless-escape
    email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  },
};
