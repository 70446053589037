import { LANG } from 'global';
import _ from 'lodash';
import moment from 'moment';

export const getBaseLang = () => {
  const lang = window.location.pathname.split('/')[1];

  if (lang == null || (lang !== LANG.EN && lang !== LANG.VI && lang !== LANG.ZH)) {
    return null;
  }

  return `/${lang}`;
};

export const allSagaImport = exportWatchers =>
  Object.keys(exportWatchers).map(key => exportWatchers[key]());

export const generateLabel = (name, mappingArr) => {
  let result = name;

  const arrItem =
    name != null &&
    mappingArr != null &&
    Array.isArray(mappingArr) &&
    mappingArr.find(el => Array.isArray(el) && el[0] === name);

  if (arrItem) result = arrItem[arrItem.length - 1];

  return _.capitalize(result);
};

export const getAddress = data => {
  let result = data.address || '';
  if (data.city) {
    result += ', ' + data.city;
  }

  if (data.state) {
    result += ', ' + data.state;
  }

  if (data.country) {
    result += ', ' + data.country;
  }
  return result;
};

export const calculatePercentage = (price, percent) => {
  const numPrice = Number(price);
  const numPerCent = Number(percent);
  if (!Number.isNaN(numPrice) && !Number.isNaN(numPerCent)) {
    return Number((numPrice * numPerCent) / 100);
  }
  return 0;
};

export const calculateTotalWithTaxFee = (priceParam, feeParam, taxParam) => {
  const fee = calculatePercentage(priceParam, feeParam);
  const tax = calculatePercentage(priceParam + fee, taxParam);

  return { tax, totalPrice: priceParam + tax };
};

export const getLangString = (str, lang) => {
  if (!str) return '';

  try {
    if (lang || window.lang) {
      return JSON.parse(str)[lang || window.lang];
    }
    return str;
  } catch (error) {
    return str;
  }
};

export const isWeekend = () => moment().day() === 0 || moment().day() === 6;

export function stringToSlug(str) {
  if (!str) return '';

  let result = str;
  const from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ';
  const to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < from.length; i++) {
    result = result.replace(RegExp(from[i], 'gi'), to[i]);
  }

  return result
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\\-]/g, '-')
    .replace(/-+/g, '-');
}

export const getActionId = (...args) => args.map(it => it.toLowerCase()).join('-');
