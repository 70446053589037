import {
  AUTHENTICATION_CHECK_EMAIL,
  AUTHENTICATION_CHECK_USERNAME,
  AUTHENTICATION_QUERY_ACTIVATED,
  AUTHENTICATION_QUERY_SIGN_IN,
  AUTHENTICATION_QUERY_SIGN_OUT,
  AUTHENTICATION_QUERY_SIGN_UP,
  AUTHENTICATION_SET_AUTH_TOKEN,
} from 'reduxs/actions';
import { generateAction } from 'utils/createApiSaga';

export const postCheckEmail = generateAction(AUTHENTICATION_CHECK_EMAIL);

export const postCheckUsername = generateAction(AUTHENTICATION_CHECK_USERNAME);

export const postSignUp = generateAction(AUTHENTICATION_QUERY_SIGN_UP);

export const postSignIn = generateAction(AUTHENTICATION_QUERY_SIGN_IN);

export const postSignOut = generateAction(AUTHENTICATION_QUERY_SIGN_OUT);

export const postActivated = generateAction(AUTHENTICATION_QUERY_ACTIVATED);

export const setAuthToken = generateAction(AUTHENTICATION_SET_AUTH_TOKEN);
