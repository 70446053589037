import { Typography } from '@material-ui/core';
import RegularButton from 'components/CustomButtons';
import MainFrame from 'components/MainFrame';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export default function SplitImageFrame({
  imgUrl,
  position,
  title,
  buttonIcon,
  buttonText,
  buttonProps,
}) {
  const { t } = useTranslation();
  return (
    <div className='SplitImageBanner__ImageContainer'>
      <img
        className='SplitImageBanner__ImageContainer__Image'
        src={`${window.location.origin}${imgUrl}`}
        alt='banner-split-img-top'
      />

      <MainFrame style={{ height: '100%' }}>
        <div className={`SplitImageBanner__ImageContainer__Frame--${position}`}>
          <div className={`SplitImageBanner__ImageContainer__Frame--${position}__Container`}>
            <Typography
              className={`SplitImageBanner__ImageContainer__Frame--${position}__Container__Text`}
              variant='h3'
            >
              {t(title)}
            </Typography>

            <RegularButton
              className={`SplitImageBanner__ImageContainer__Frame--${position}__Container__Button`}
              color='white'
              size='lg'
              round
              {...buttonProps}
            >
              <img
                className={`SplitImageBanner__ImageContainer__Frame--${position}__Container__Button__Icon`}
                src={`${window.location.origin}${buttonIcon}`}
                alt='banner-split-button-icon'
              />
              <Typography
                variant='body1'
                className={`SplitImageBanner__ImageContainer__Frame--${position}__Container__Button__Text`}
              >
                {t(buttonText)}
              </Typography>
            </RegularButton>
          </div>
        </div>
      </MainFrame>
    </div>
  );
}

SplitImageFrame.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
};
