import { Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import ProductFlexCard from 'components/ProductFlexCard';
import useLangHistory from 'hooks/useLangHistory';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { getLangString } from 'utils/common';
import './styles.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const SuggestedProducts = ({ products }) => {
  const { t } = useTranslation();
  const { lang } = window;
  const { push } = useLangHistory();

  return products?.length ? (
    <GridContainer className='suggested-product-block'>
      <Typography variant='subtitle2'>{t('Suggested Products')}</Typography>
      <Carousel
        responsive={responsive}
        ssr
        infinite
        keyBoardControl
        renderButtonGroupOutside
        arrows
        pauseOnHover
        containerClass='suggested-carousel'
        sliderClass='suggested-slider'
        itemClass='suggested-item'
      >
        {products.map(product => (
          <ProductFlexCard
            key={product.title}
            avatarImgUrl={product.avatarImageUrl}
            contentChildren={
              <>
                <Typography variant='subtitle1' className='product-title'>
                  {getLangString(product.title, lang)}
                </Typography>
                {product.subtitle}
              </>
            }
            regularPrice={product.regularPrice}
            salePrice={product.salePrice}
            buttonProps={{
              onClick: () => {
                push(product.href);
              },
            }}
          />
        ))}
      </Carousel>
    </GridContainer>
  ) : null;
};

SuggestedProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      avatarImageUrl: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.node,
      regularPrice: PropTypes.number,
      salePrice: PropTypes.number,
      href: PropTypes.string,
    })
  ),
};

export default SuggestedProducts;
