import React from 'react';
import { useTranslation } from 'react-i18next';

// styles

// material-ui components
import InputAdornment from '@material-ui/core/InputAdornment';

// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons';
import CustomInput from 'components/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// icons
import EmailIcon from '@material-ui/icons/Email';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';

// REDUX
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ProductTypeCode } from 'constants/Enums';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';

import { getLangString } from 'utils/common';
import useCompleteBookingLogic from 'views/CompleteBookingPage/CompleteBooking.logic';
import mapping from 'views/CompleteBookingPage/mapping';

// styles
import { cardTitle } from 'assets/jss/global-styles';
import modalStyle from 'assets/jss/material-kit-react/components/bookingModalStyle';
import imagesStyles from 'assets/jss/material-kit-react/cores/imagesStyle';
import './styles.scss';

const useStyles = makeStyles({
  ...imagesStyles,
  ...modalStyle,
  ...cardTitle,
});

const BookingInformation = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { state, formik, handlers, propState, store, lang } = useCompleteBookingLogic();

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <div className='customer-information-block'>
            <h5 className='Title'>{t('Personal detail')}</h5>
            <GridContainer>
              <GridItem md={6}>
                <CustomInput
                  labelText='First name'
                  id='firstName'
                  formControlProps={{
                    fullWidth: true,
                    className: 'm-0',
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <PeopleOutlineIcon />
                      </InputAdornment>
                    ),
                    value: formik.values.firstName,
                    onChange: handlers.onChange,
                    name: 'firstName',
                  }}
                  success={formik.touched.firstName && !formik.errors.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.errors.firstName}
                />
              </GridItem>
              <GridItem md={6}>
                <CustomInput
                  labelText='Last name'
                  id='lastName'
                  formControlProps={{
                    fullWidth: true,
                    className: 'm-0',
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <PeopleIcon />
                      </InputAdornment>
                    ),
                    value: formik.values.lastName,
                    onChange: handlers.onChange,
                    name: 'lastName',
                  }}
                  success={formik.touched.lastName && !formik.errors.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.errors.lastName}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem md={6}>
                <CustomInput
                  labelText='Email'
                  id='email'
                  formControlProps={{
                    fullWidth: true,
                    className: 'm-0',
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <EmailIcon />
                      </InputAdornment>
                    ),
                    value: formik.values.email,
                    onChange: handlers.onChange,
                    name: 'email',
                  }}
                  success={formik.touched.email && !formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                />
              </GridItem>
              <GridItem md={6}>
                <CustomInput
                  labelText='Phone'
                  id='telephone'
                  numberFormat
                  // numberFormatProps={{
                  //   format: '(+84) ### ### ####',
                  // }}
                  formControlProps={{
                    fullWidth: true,
                    className: 'm-0',
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <PhoneEnabledIcon />
                      </InputAdornment>
                    ),
                    value: formik.values.telephone,
                    onChange: handlers.onChange,
                    name: 'telephone',
                  }}
                  success={formik.touched.telephone && !formik.errors.telephone}
                  error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                  helperText={formik.errors.telephone}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText='Handicap'
                  id='handicap'
                  formControlProps={{
                    fullWidth: true,
                    className: 'm-0',
                  }}
                  inputProps={{
                    type: 'number',
                    step: 0.1,
                    value: String(formik.values.handicap),
                    onChange: handlers.onChange,
                    min: 0,
                    max: 54,
                    name: 'handicap',
                  }}
                />
              </GridItem>
            </GridContainer>
            {propState.product.surcharges?.length > 0 && (
              <div className='CompleteBooking__Left__PersonInfo__SurchargeContainer'>
                <h5 className='Title'>Surcharge</h5>
                <GridContainer>
                  {propState.product.surcharges.map(val => (
                    <GridItem md={6} key={val.teetimeSurcharge.name}>
                      <FormControlLabel
                        control={<Checkbox />}
                        value={JSON.stringify(val)}
                        onChange={handlers.onSurchargeCheckedChange}
                        label={`${val.teetimeSurcharge.name} (${val.price.toLocaleString('en')} ${
                          propState.product.currency.code
                        })`}
                        className='CompleteBooking__Left__PersonInfo__SurchargeContainer__Checkbox'
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </div>
            )}
            {propState.hotDealOptions?.length > 0 && (
              <div className='CompleteBooking__Left__PersonInfo__HotDealContainer'>
                <h5>Hot Deal</h5>
                <GridContainer>
                  {propState.hotDealOptions.map(val => (
                    <GridItem md={6} key={val.content}>
                      <FormControlLabel
                        control={<Checkbox />}
                        value={JSON.stringify(val)}
                        onChange={handlers.onHotDealCheckedChange}
                        label={`${getLangString(val.content, lang)} (${val.price.toLocaleString(
                          'en'
                        )} ${propState.currencyCode})`}
                      />
                    </GridItem>
                  ))}
                </GridContainer>
              </div>
            )}
          </div>

          {propState.productTypeCode !== ProductTypeCode.GOLF_TOUR}
          {mapping[propState.productTypeCode].showVehicleOptions ? (
            <Accordion expanded={state.vehicleExpanded} onChange={handlers.onVehicleExpandedChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography variant='body1'>
                  {t('Do you want to use the vehicle service ?')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction='column'
                  className='CompleteBooking__Left__PersonInfo__Vehicle'
                >
                  <Grid className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer'>
                    {formik.values.vehicleTrip?.vehicle?.vehicleCompanyBranchLocation
                      ?.vehicleCompany?.name && (
                      <>
                        <Typography
                          variant='inherit'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          Company name:{' '}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          {
                            formik.values.vehicleTrip.vehicle.vehicleCompanyBranchLocation
                              .vehicleCompany.name
                          }
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer'>
                    {formik.values.vehicleTrip?.pickupLocation && (
                      <>
                        <Typography
                          variant='inherit'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          Pickup:{' '}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          {formik.values.vehicleTrip.pickupLocation.address},{' '}
                          {formik.values.vehicleTrip.pickupLocation.state},{' '}
                          {formik.values.vehicleTrip.pickupLocation.city},{' '}
                          {formik.values.vehicleTrip.pickupLocation.country},{' '}
                          {formik.values.vehicleTrip.pickupLocation.region}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer'>
                    {formik.values.vehicleTrip?.dropoffLocation && (
                      <>
                        <Typography
                          variant='inherit'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          Dropoff:{' '}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          {formik.values.vehicleTrip.dropoffLocation.address},{' '}
                          {formik.values.vehicleTrip.dropoffLocation.state},{' '}
                          {formik.values.vehicleTrip.dropoffLocation.city},{' '}
                          {formik.values.vehicleTrip.dropoffLocation.country},{' '}
                          {formik.values.vehicleTrip.dropoffLocation.region}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer'>
                    {formik.values.vehicleTrip?.dropoffLocation && (
                      <>
                        <Typography
                          variant='inherit'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          Price:{' '}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='CompleteBooking__Left__PersonInfo__Vehicle__LocationValueContainer__LocationValue'
                        >
                          {formik.values.vehicleTrip.price.toLocaleString('en')} VND
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Autocomplete
                    id='vehicle-trip-option'
                    options={store.vehicleTripList.data}
                    fullWidth
                    renderInput={params => <TextField {...params} label='Vehicle trip' />}
                    getOptionLabel={option => {
                      const companyName =
                        option.vehicle?.vehicleCompanyBranchLocation?.vehicleCompany?.name ?? '';
                      const price = `${option.price.toLocaleString('en')} VND`;

                      let locationValue = ``;
                      if (option?.pickupLocation?.address) {
                        locationValue += option.pickupLocation.address + ', ';
                      }

                      if (option?.pickupLocation?.state) {
                        locationValue += option.pickupLocation.state + ' <-> ';
                      }

                      if (option?.dropoffLocation?.address) {
                        locationValue += option.dropoffLocation.address + ', ';
                      }

                      if (option?.dropoffLocation?.state) {
                        locationValue += option.dropoffLocation.state;
                      }

                      return `${companyName} - ${locationValue} - ${price}`;
                    }}
                    onChange={handlers.onVehicleTripChange}
                    value={formik.values.vehicleTrip}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </CardBody>
      </Card>

      <div className='complete-booking-action-block'>
        <Button id='btn-complete-booking-book-now' type='submit' color='success'>
          {t('Book Now')}
        </Button>
      </div>
    </form>
  );
};

export default BookingInformation;
