import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';

const BannerItem = ({ slider }) => (
  <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer'>
    <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__SwiperContainer'>
      <Swiper
        centeredSlides
        loop
        navigation
        noSwiping
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        modules={[Autoplay, Pagination, Navigation]}
        className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__Swiper'
      >
        {slider.slides.map((el, key) => (
          <SwiperSlide
            className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__Swiper__Slide'
            key={key}
          >
            <img
              alt=''
              src={el}
              className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__Swiper__Slide__Image'
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__ButtonGroup'>
      <h6 className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__ButtonGroup__Text'>
        {slider.title}
      </h6>
      <IconButton
        size='small'
        className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer__ButtonGroup__Button'
      >
        <ArrowForwardIcon color='primary' fontSize='small' />
      </IconButton>
    </div>
  </div>
);

BannerItem.propTypes = {
  slider: PropTypes.shape({
    title: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default BannerItem;
