import watchQueryRegionData from './QueryRegionData';
import watchQueryCountryData from './QueryCountryData';
import watchQueryStateData from './QueryStateData';
import watchQueryCityData from './QueryCityData';

export default {
  watchQueryRegionData,
  watchQueryCountryData,
  watchQueryStateData,
  watchQueryCityData,
};
