import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { AUTHENTICATION_QUERY_SIGN_OUT } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQuerySignOut(request) {
  try {
    yield call(query, apis.postSignOut, request.payload);
    yield put({
      type: request.response.success,
    });
  } catch (error) {
    console.error('Query sign out failed', error);
    yield put({
      type: request.response.failed,
    });
  }
}

export default function* watchQuerySignOut() {
  yield takeLatest(REQUEST(AUTHENTICATION_QUERY_SIGN_OUT), doQuerySignOut);
}
