import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { CATEGORY_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

// eslint-disable-next-line import/prefer-default-export
export const Categories = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(CATEGORY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(CATEGORY_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(CATEGORY_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
