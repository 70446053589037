import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

// core
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

// components
import radioStyle from 'assets/jss/material-kit-react/cores/customCheckboxRadioSwitchStyle';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import Button from 'components/CustomButtons';
import CustomInput from 'components/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useFormik } from 'formik';

// @material-ui/icons
import Check from '@material-ui/icons/Check';

// REDUX
import { FormHelperText } from '@material-ui/core';
import ReducerHelmet from 'components/ReducerHelmet';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import useLangHistory from 'hooks/useLangHistory';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postSignIn } from 'reduxs/global-actions';
import styles from '../styles';
import { signInSchema } from './schema';

const useStyles = makeStyles({ ...styles, ...radioStyle });

const SIGN_UP = 'SIGN_UP';
function SignIn({ onSwitchLayout }) {
  const { t } = useTranslation();
  const { push } = useLangHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden');
  const wrapperDiv = classNames(classes.checkboxAndRadio, classes.checkboxAndRadioHorizontal);
  const [visibility, setVisibility] = useState(false);

  setTimeout(() => {
    setCardAnimation('');
  }, 700);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: false,
    },
    validationSchema: signInSchema,
    onSubmit: values => {
      dispatch(
        postSignIn(values, {
          handleSuccess: () => {
            const { state } = location;
            if (state?.prevLocation != null) {
              const { prevLocation } = state;
              push(prevLocation.pathname, prevLocation.state);
            } else {
              push('/');
            }
          },
          handleFailed: data => {
            data?.message != null && formik.setFieldError('apiErrorMessage', data.message);
          },
        })
      );
    },
  });

  const onHandleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const onSwitchHandle = () => {
    onSwitchLayout(SIGN_UP);
  };

  const handleToggle = () => {
    formik.setFieldValue('remember', !formik.values.remember);
  };

  return (
    <>
      <ReducerHelmet
        option={META_TAG_OPTION.PAGE}
        page={META_TAG_PAGE.LOGIN}
        productType={META_TAG_PRODUCT_TYPE.LOGIN}
        id='login_page'
      />
      <div className={classes.container}>
        <GridContainer justifyContent='center'>
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[cardAnimation]}>
              <form className='authForm' onSubmit={onHandleSubmit}>
                <CardHeader color='success' className={classes.cardHeader}>
                  <h4>{t('Login')}</h4>
                  <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href='#pablo'
                      target='_blank'
                      color='transparent'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-twitter' />
                    </Button>
                    <Button
                      justIcon
                      href='#pablo'
                      target='_blank'
                      color='transparent'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-facebook' />
                    </Button>
                    <Button
                      justIcon
                      href='#pablo'
                      target='_blank'
                      color='transparent'
                      onClick={e => e.preventDefault()}
                    >
                      <i className='fab fa-google-plus-g' />
                    </Button>
                  </div>
                </CardHeader>
                <p className={classes.divider}>{t('Or Be Classical')}</p>
                <CardBody>
                  <CustomInput
                    labelText='Username'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon fontSize='small'>people</Icon>
                        </InputAdornment>
                      ),
                      id: 'input-username',
                      name: 'username',
                      value: formik.values.username,
                      onChange: e => {
                        formik.handleChange(e);
                      },
                    }}
                    success={formik.touched.username && !formik.errors.username}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.errors.username}
                  />

                  <CustomInput
                    labelText='Password'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: visibility ? 'text' : 'password',
                      endAdornment: (
                        <>
                          <InputAdornment
                            position='end'
                            className={classes.cursorPointer}
                            onClick={() => setVisibility(prev => !prev)}
                          >
                            <Icon fontSize='small'>{`visibility${visibility ? '_off' : ''}`}</Icon>
                          </InputAdornment>
                          <InputAdornment position='end'>
                            <Icon fontSize='small'>lock_outline</Icon>
                          </InputAdornment>
                        </>
                      ),
                      id: 'input-password',
                      name: 'password',
                      value: formik.values.password,
                      onChange: formik.handleChange,
                    }}
                    success={formik.touched.password && !formik.errors.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.errors.password}
                  />

                  <div className={`${wrapperDiv} rememberRadio`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={handleToggle}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{ checked: classes.checked }}
                          value={formik.values.remember}
                        />
                      }
                      classes={{ label: `${classes.label} blackLabel` }}
                      label={t('Remember me')}
                    />
                  </div>

                  {formik.errors.apiErrorMessage && (
                    <FormHelperText className='error-message' error>
                      {t(formik.errors.apiErrorMessage)}
                    </FormHelperText>
                  )}
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button disabled={authData.status.isLoading} type='submit' simple color='success'>
                    {authData.status.isLoading ? (
                      <ClipLoader color='#4caf50' loading size={17} />
                    ) : (
                      <span>{t('Sign in')}</span>
                    )}
                  </Button>
                  <Button
                    disabled={authData.status.isLoading}
                    onClick={onSwitchHandle}
                    color='success'
                  >
                    {t('Sign up')}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}

SignIn.propTypes = {
  onSwitchLayout: PropTypes.func,
  // history: PropTypes.func,
};

export default SignIn;
