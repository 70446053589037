import { colors } from 'assets/jss/global-styles';

const styles = {
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  logoContainerDrawer: {
    display: 'flex',
  },
  telContainer: {
    display: 'grid',
    marginLeft: '13px',
  },
  telText: {
    fontWeight: '500',
    fontSize: '15px',
    color: colors.BLACK,
  },
};

export default styles;
