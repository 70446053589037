import { Card, CardActions, CardContent, MenuItem, Select, Typography } from '@material-ui/core';
import RegularButton from 'components/CustomButtons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CustomInput from 'components/CustomInput';

const BookingCard = ({
  bookingData,
  departureOptions,
  onDepartureDateChange,
  onBooking,
  handleMinusQuantity,
  handlePlusQuantity,
  onQuantityChange,
}) => {
  const { t } = useTranslation();

  const onSubmit = e => {
    e.preventDefault();
    onBooking();
  };

  return (
    <Card className='golf-tour-booking-card'>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Typography variant='h6' align='center' className='card-title'>
            {t('Select Date, Player & Tee Time')}
          </Typography>
          <div className='booking-option'>
            {bookingData.product == null ? null : (
              <div className='booking-option__departure-picker'>
                <Typography variant='subtitle2'>{t('Departure')}</Typography>

                <Select
                  labelId='departure-select-label'
                  id='departure-simple-select'
                  value={bookingData.product}
                  defaultValue={bookingData.product}
                  renderValue={value => t(value.departureDate)}
                  onChange={onDepartureDateChange}
                  variant='outlined'
                  className='custom-departure-picker'
                >
                  {departureOptions?.map(departure => (
                    <MenuItem
                      key={departure.tourCode}
                      value={departure}
                      className='picker-menu-item'
                    >
                      <div className='picker-item-container'>
                        <Typography variant='body2' className='picker-item-label'>
                          {t('Departure Date')}:
                        </Typography>
                        <Typography variant='body2' className='picker-item-value'>
                          {departure.departureDate}
                        </Typography>
                      </div>
                      <div className='picker-item-container'>
                        <Typography variant='body2' className='picker-item-label'>
                          {t('Departure Time')}:
                        </Typography>
                        <Typography variant='body2' className='picker-item-value'>
                          {departure.departureTime}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            <div className='booking-option__player-picker'>
              <Typography variant='subtitle2'>{t('Select Player')}</Typography>
              <div>
                <RegularButton
                  justIcon
                  round
                  color='transparent'
                  onClick={handleMinusQuantity}
                  size='sm'
                >
                  <RemoveIcon />
                </RegularButton>
                <CustomInput
                  formControlProps={{
                    className: 'customIncrease',
                  }}
                  inputProps={{
                    type: 'number',
                    value: bookingData.quantity,
                    onChange: onQuantityChange,
                  }}
                />
                <RegularButton
                  justIcon
                  round
                  color='transparent'
                  onClick={handlePlusQuantity}
                  size='sm'
                >
                  <AddIcon />
                </RegularButton>
              </div>
            </div>
          </div>

          <div className='booking-price'>
            <Typography variant='h4'>
              {(bookingData?.unitPrice ?? 0).toLocaleString('en')} {bookingData.currencyCode}
            </Typography>
          </div>
        </CardContent>

        <CardActions className='card-actions'>
          <RegularButton id='btn-golf-tour-detail-book-now' type='submit' color='success'>
            {t('Book Now')}
          </RegularButton>
        </CardActions>
      </form>
    </Card>
  );
};

BookingCard.propTypes = {
  bookingData: PropTypes.shape({
    productTypeCode: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.number,
      golfTourId: PropTypes.number,
      tourCode: PropTypes.string,
      availableSeats: PropTypes.number,
      departureDate: PropTypes.string,
      departureTime: PropTypes.string,
      departureLocation: PropTypes.string,
      price: PropTypes.number,
      productName: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numOfGuest: PropTypes.number,
    player: PropTypes.number,
    nonPlayer: PropTypes.number,
    quantity: PropTypes.number,
    unitPrice: PropTypes.number,
    currencyCode: PropTypes.string,
    hotDealOptions: PropTypes.object,
    avatarImg: PropTypes.string,
  }),
  onDepartureDateChange: PropTypes.func,
  onBooking: PropTypes.func,
  handleMinusQuantity: PropTypes.func,
  handlePlusQuantity: PropTypes.func,
  onQuantityChange: PropTypes.func,
  departureOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      golfTourId: PropTypes.number,
      tourCode: PropTypes.string,
      availableSeats: PropTypes.number,
      departureDate: PropTypes.string,
      departureTime: PropTypes.string,
      departureLocation: PropTypes.string,
      price: PropTypes.number,
    })
  ),
};

export default BookingCard;
