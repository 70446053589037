import { Typography } from '@material-ui/core';
import { queryBlogList } from 'reduxs/global-actions';
import { getLangString } from 'utils/common';

export default {
  initializeState: {
    search: {
      keyword: '',
      type: 'name',
    },
    filters: {},
  },
  searchTypes: [
    {
      label: 'Name',
      name: 'name',
    },
  ],
  filters: {},
  specialField: {
    product: {
      id: 'id',
      name: 'title',
      avatarUrl: 'image',
      buttonConfig: {
        label: 'Read',
      },
      getSubTitle: (product, classes, lang) => (
        <Typography variant='caption' className={classes.productContent}>
          {getLangString(product.subTitle, lang)}
        </Typography>
      ),
      href: 'product-detail/blog',
    },
    props: {
      maxSubTitleLine: 5,
    },
  },
  dataKey: 'blogList',
  actions: { queryData: queryBlogList },
};
