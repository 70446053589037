import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import RegularButton from 'components/CustomButtons';
import MainFrame from 'components/MainFrame';
import { getLangString } from 'utils/common';

// Icons
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SuggestedProducts from 'components/suggestedProducts';
import HTMLReactParser from 'html-react-parser';
import parse from 'style-to-object';
import './styles.scss';

const RegularProductDetail = ({
  title,
  subTitle,
  basicDetails,
  suggestedProducts,
  className,
  children,
}) => {
  const { t } = useTranslation();
  const { lang } = window;
  const [expand, setExpand] = React.useState(false);
  const stickyBlockRef = React.useRef(null);

  const onReadMoreChange = () => {
    setExpand(!expand);
  };

  const replaceElement = domNode => {
    if (domNode.name === 'img') {
      const { src, alt, width, height, style } = domNode.attribs;

      const customStyle = {
        ...parse(style),
        width: '100%',
        height: 'auto',
      };
      if (width) {
        customStyle.maxWidth = `${width}px`;
      }

      if (height) {
        customStyle.maxHeight = `${height}px`;
      }
      return <img src={src} alt={alt} style={customStyle} />;
    }

    return domNode;
  };

  return (
    <MainFrame className='product-detail-page-component'>
      <GridContainer className='title-block'>
        <GridItem md={8} className='title-container'>
          <Typography variant='h1' className='txt-title'>
            {t(getLangString(title, lang))}
          </Typography>

          {typeof subTitle === 'string' ? (
            <Typography variant='h6'>
              {t(typeof subTitle === 'string' ? subTitle : subTitle())}
            </Typography>
          ) : (
            subTitle?.()
          )}
        </GridItem>
      </GridContainer>

      {/* Detail information */}
      <GridContainer className='content-block'>
        <GridItem md={9} className='left'>
          {basicDetails && getLangString(basicDetails, lang) ? (
            <>
              <Typography variant='subtitle2' className='detail-title'>
                {t('Detail Information')}
              </Typography>
              <div
                className='detail-information-container'
                style={{ height: expand ? 'fit-content' : '140px' }}
              >
                <div className='detail-content'>
                  {HTMLReactParser(getLangString(basicDetails, lang), { replace: replaceElement })}
                </div>
                <RegularButton
                  color='white'
                  className='btn-read-more'
                  onClick={onReadMoreChange}
                  size='sm'
                >
                  {expand ? t('Read less') : t('Read more')}
                  {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </RegularButton>
              </div>
            </>
          ) : null}
        </GridItem>

        <GridItem md={3} className='right'>
          <div
            ref={ref => {
              stickyBlockRef.current = ref;
            }}
            className={`sticky-block ${className ?? ''}`}
          >
            {children || null}
          </div>
        </GridItem>
      </GridContainer>

      <SuggestedProducts products={suggestedProducts} />
    </MainFrame>
  );
};

RegularProductDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  basicDetails: PropTypes.string,
  suggestedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      avatarImageUrl: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.node,
      price: PropTypes.number,
      href: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default RegularProductDetail;
