import config from 'configs';
import { METHOD } from 'constants/Enums';

/* 
  Example:
  uri: '/favorite/symbol/delete',
  method: METHOD.DELETE,
  authenticated: true,
  useDeleteWithBody: true, 
*/

export default {
  // Authentication
  postCheckEmail: {
    uri: '/authentication/checkEmail',
    method: METHOD.POST,
  },
  postCheckUsername: {
    uri: '/authentication/checkUsername',
    method: METHOD.POST,
  },
  postSignUp: {
    uri: '/authentication/signUp',
    method: METHOD.POST,
  },
  postSignIn: {
    uri: '/authentication/signIn',
    method: METHOD.POST,
  },
  postSignOut: {
    uri: '/authentication/signOut',
    method: METHOD.POST,
  },
  postRefreshToken: {
    uri: '/authentication/refreshToken',
    method: METHOD.POST,
  },
  postVerify: {
    uri: '/authentication/verify',
    method: METHOD.POST,
  },
  putChangeLanguage: {
    uri: '/authentication/changeLanguage',
    method: METHOD.PUT,
  },

  // Golf Courses
  getGolfCourseList: {
    uri: '/golfCourse/list',
    method: METHOD.GET,
  },

  getGolfCourseDetail: {
    uri: '/golfCourse/detail/{customPath}',
    method: METHOD.GET,
  },

  // Golf Packages
  getGolfPackageList: {
    uri: '/golfPackage/list',
    method: METHOD.GET,
  },
  getGolfPackageDetail: {
    uri: '/golfPackage/detail/{customPath}',
    method: METHOD.GET,
  },
  getGolfPackagesSurcharge: {
    uri: '/golfPackage/surcharge/{customPath}',
    method: METHOD.GET,
  },

  // Tee times
  getTeetimeList: {
    uri: '/teetime/list',
    method: METHOD.POST,
  },

  // Geo locations
  getGeoLocation: {
    uri: config.geo,
    method: METHOD.GET,
    useFullUri: true,
  },

  // Location
  getRegionList: {
    uri: '/location/region/list',
    method: METHOD.GET,
  },
  getCountryList: {
    uri: '/location/country/list',
    method: METHOD.GET,
  },
  getStateList: {
    uri: '/location/state/list',
    method: METHOD.GET,
  },
  getCityList: {
    uri: '/location/city/list',
    method: METHOD.GET,
  },

  // Booking
  postBookingCreate: {
    uri: '/booking/create',
    method: METHOD.POST,
  },

  // Payment
  getPaymentInfo: {
    uri: '/payment/info',
    method: METHOD.GET,
  },

  // Video
  getBannerVideo: {
    uri: '/video/banner.mp4',
    method: METHOD.GET,
    customBasePath: '/libraries',
  },

  // Vehicle
  getVehicleTripList: {
    uri: '/vehicle/trip/list',
    method: METHOD.GET,
  },

  // Blog
  getBlogList: {
    uri: '/blog/list',
    method: METHOD.GET,
  },
  getBlogDetail: {
    uri: '/blog/detail/{customPath}',
    method: METHOD.GET,
  },

  // Golf tour
  getGolfTourList: {
    uri: '/golfTour/list',
    method: METHOD.GET,
  },
  getGolfTourDetail: {
    uri: '/golfTour/detail/{customPath}',
    method: METHOD.GET,
  },

  // Travel
  getTravelList: {
    uri: '/travel/list',
    method: METHOD.GET,
  },
  getTravelDetail: {
    uri: '/travel/detail/{customPath}',
    method: METHOD.GET,
  },

  // Categories
  getCategories: {
    uri: '/categories/list',
    method: METHOD.GET,
  },

  // Metatag
  getMetaTag: {
    uri: `/metaTag/{option}/{page}/{productType}/{id}`,
    method: METHOD.GET,
  },
};
