import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datetime';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

const FilterDatePicker = ({ label, onChange, dateFormat, value }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant='body1'>{t(label)}</Typography>
      <DatePicker
        onChange={onChange}
        inputProps={{
          placeholder: t('Choose your date'),
          className: 'form-control text-center',
        }}
        closeOnSelect
        closeOnClickOutside
        timeFormat={false}
        value={value}
        dateFormat={dateFormat || 'MMMM DD YYYY'}
      />
    </div>
  );
};

FilterDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}).isRequired,
  dateFormat: PropTypes.string,
};

export default FilterDatePicker;
