import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { AUTHENTICATION_QUERY_REFRESH_TOKEN } from 'reduxs/actions';
import { postSignOut } from 'reduxs/global-actions';
import { query } from 'utils/api';
import { getKey, setKey } from 'utils/localStorage';

function* doQueryRefreshToken(request) {
  const refreshToken = getKey('refresh_token');
  if (refreshToken != null) {
    try {
      const response = yield call(query, apis.postRefreshToken, { refreshToken });
      const data = response.data;
      setKey('access_token', data.accessToken);
      yield put({
        type: request.response.success,
        payload: data.accessToken,
      });
    } catch (error) {
      console.error('Query refresh token failed', error);
      if (request.response) {
        yield put({
          type: request.response.failed,
          payload: error.code || error.message,
        });
      }
    }
  } else {
    yield put(postSignOut());
  }
}

export default function* watchQueryRefreshToken() {
  yield takeLatest(REQUEST(AUTHENTICATION_QUERY_REFRESH_TOKEN), doQueryRefreshToken);
}
