import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { ORDER_GOLF_CONFIRM } from 'reduxs/actions';

const queryOrderGolfConfirm = async payload => {
  const url = `/order-golf`;
  // return await apiWorker.create(url, payload);
};

function* doQueryOrderGolfConfirm(request) {
  try {
    const response = yield call(queryOrderGolfConfirm, request.payload);
    const data = response.data;
    yield put({
      type: request.response.success,
      payload: data,
    });
  } catch (error) {
    console.error('Query Tee Times data', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryOrderGolfConfirm() {
  yield takeLatest(REQUEST(ORDER_GOLF_CONFIRM), doQueryOrderGolfConfirm);
}
