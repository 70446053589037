import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import useLangHistory from 'hooks/useLangHistory';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { headerVisibleMobileDraw } from 'reduxs/global-actions';
import styles from './styles';

const useStyles = makeStyles(styles);
export default function HeaderLogo() {
  const { getLangPath } = useLangHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const visibleMobileDrawer = useSelector(state => state.visibleMobileDrawer);

  const containerClasses = classNames({
    [classes.logoContainer]: !visibleMobileDrawer.visible,
    [classes.logoContainerDrawer]: visibleMobileDrawer.visible,
  });

  return (
    <div className={containerClasses}>
      <Link
        to={getLangPath('/')}
        onClick={() => {
          dispatch(headerVisibleMobileDraw({ visible: false }));
        }}
      >
        <img width='70px' src={`${window.location.origin}/img/Global/golfadventure.png`} alt='' />
      </Link>
      <div className={classes.telContainer}>
        <a
          id='btn-header-about-us-phone-0903640601'
          href='tel:0903640601'
          className={classes.telText}
        >
          0903.640.601
        </a>
        <a
          id='btn-header-about-us-phone-0287306684'
          href='tel:02873066684'
          className={classes.telText}
        >
          028.730.6684
        </a>
      </div>
    </div>
  );
}
