import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { META_TAG_QUERY_DETAIL } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryMetaTagDetailData(request) {
  try {
    const response = yield call(query, apis.getMetaTag, { ...request.payload, lang: window.lang });
    const { data } = response;

    yield put({
      type: request.response.success,
      payload: data,
    });
    request.callback?.handleSuccess?.({ data });
  } catch (error) {
    request.callback?.handleFail?.();
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryMetaTagDetailData() {
  yield takeLatest(REQUEST(META_TAG_QUERY_DETAIL), doQueryMetaTagDetailData);
}
