export const UPDATE_DEBUGGING = 'debugging/UPDATE';

// LOCALIZATION
export const LOCALIZATION_INIT = 'localization/INIT';
export const LOCALIZATION_I18N = 'localization/LOCALIZATION_I18N';

// GOLF
export const GOLF_RESET_LIST_DATA = 'golf/RESET_GOLF_DATA';
export const GOLF_QUERY_LIST_DATA = 'golf/QUERY_GOLF_DATA';
export const GOLF_QUERY_DETAIL_DATA = 'golf/GOLF_QUERY_DETAIL_DATA';
export const GOLF_QUERY_LIST_BY_CONTINENT_DATA = 'golf/GOLF_QUERY_LIST_BY_CONTINENT_DATA';
export const GOLF_QUERY_LIST_BY_COUNTRY_DATA = 'golf/GOLF_QUERY_LIST_BY_COUNTRY_DATA';
export const GOLF_QUERY_LIST_BY_STATE_DATA = 'golf/GOLF_QUERY_LIST_BY_STATE_DATA';
export const GOLF_QUERY_LIST_BY_CITY_DATA = 'golf/GOLF_QUERY_LIST_BY_CITY_DATA';

// ORDER
export const ORDER_GOLF_CART = 'order/ORDER_GOLF_CART';
export const ORDER_GOLF_CONFIRM = 'order/ORDER_GOLF_CONFIRM';

// PACKAGE
export const PACKAGE_QUERY_LIST_DATA = 'package/QUERY_PACKAGE_DATA';
export const PACKAGE_QUERY_DETAIL_DATA = 'package/QUERY_PACKAGE_DETAIL_DATA';
export const PACKAGE_QUERY_SURCHARGE_DATA = 'package/QUERY_PACKAGE_SURCHARGE_DATA';

// AUTHENTICATION
export const AUTHENTICATION_QUERY_SIGN_UP = 'authentication/QUERY_SIGNUP';
export const AUTHENTICATION_QUERY_SIGN_IN = 'authentication/QUERY_SIGN_IN';
export const AUTHENTICATION_QUERY_SIGN_OUT = 'authentication/QUERY_SIGN_OUT';
export const AUTHENTICATION_QUERY_ACTIVATED = 'authentication/QUERY_ACTIVATED';
export const AUTHENTICATION_QUERY_REFRESH_TOKEN = 'authentication/QUERY_REFRESH_TOKEN';
export const AUTHENTICATION_CHECK_EMAIL = 'authentication/CHECK_EMAIL';
export const AUTHENTICATION_CHECK_USERNAME = 'authentication/USERNAME';
export const AUTHENTICATION_SET_AUTH_TOKEN = 'authentication/SET_AUTH_TOKEN';

// LOCATION
export const GEO_GET_LOCATION = 'geo/GET_LOCATION';
export const LOCATION_REGION_QUERY_LIST_DATA = 'location/REGION_QUERY_LIST_DATA';
export const LOCATION_COUNTRY_QUERY_LIST_DATA = 'location/COUNTRY_QUERY_LIST_DATA';
export const LOCATION_STATE_QUERY_LIST_DATA = 'location/STATE_QUERY_LIST_DATA';
export const LOCATION_CITY_QUERY_LIST_DATA = 'location/CITY_QUERY_LIST_DATA';
export const LOCATION_RESET_COUNTRY = 'location/LOCATION_RESET_COUNTRY)';
export const LOCATION_RESET_STATE = 'location/LOCATION_RESET_STATE)';
export const LOCATION_RESET_CITY = 'location/LOCATION_RESET_CITY)';

// TEE TIME
export const TEE_TIMES_QUERY_LIST_DATA = 'teetimes/TEE_TIMES_QUERY_LIST_DATA';

// BOOKING
export const BOOKING_CREATE = 'booking/CREATE';

// PAYMENT
export const PAYMENT_GET_INFO = 'payment/GET_INFO';

// HEADER
export const HEADER_VISIBLE_MOBILE_DRAW = 'HEADER_VISIBLE_MOBILE_DRAWER';

// LIBRARIES
export const LIBRARIES_GET_BANNER_VIDEO = 'libraries/GET_BANNER_VIDEO';

// VEHICLE
export const VEHICLE_QUERY_VEHICLE_TRIP_DATA = 'vehicle/QUERY_VEHICLE_TRIP_DATA';

// BLOG
export const BLOG_QUERY_LIST_DATA = 'blog/QUERY_LIST_DATA';
export const BLOG_QUERY_DETAIL_DATA = 'blog/QUERY_DETAIL_DATA';

// GOLF TOUR
export const GOLF_TOUR_QUERY_LIST_DATA = 'golfTour/QUERY_LIST_DATA';
export const GOLF_TOUR_QUERY_DETAIL_DATA = 'golfTour/QUERY_DETAIL_DATA';

// TRAVEL
export const TRAVEL_QUERY_LIST_DATA = 'travel/QUERY_LIST_DATA';
export const TRAVEL_QUERY_DETAIL_DATA = 'travel/QUERY_DETAIL_DATA';

// CATEGORY
export const CATEGORY_QUERY_LIST_DATA = 'category/QUERY_LIST_DATA';

// META TAG
export const META_TAG_QUERY_DETAIL = 'metaTag/QUERY_DETAIL';
