import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import image from 'assets/image/golf-may.jpg';
import styles from './styles';

// Sections
import SignIn from './Sections/SignIn';
import SignUp from './Sections/SignUp';

const useStyles = makeStyles(styles);

const SIGN_IN = 'SIGN_IN';
const SIGN_UP = 'SIGN_UP';

export default function LoginPage() {
  const [layoutForm, setLayoutForm] = React.useState(SIGN_IN);

  const classes = useStyles();

  const onSwitchForm = e => {
    setLayoutForm(e);
  };

  const renderLayout = () => {
    if (layoutForm === SIGN_UP) {
      return <SignUp onSwitchLayout={onSwitchForm} />;
    }

    return <SignIn onSwitchLayout={onSwitchForm} />;
  };

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        {renderLayout()}
      </div>
    </div>
  );
}
