import { container, title } from 'assets/jss/global-styles';
import customCheckboxRadioSwitch from 'assets/jss/material-kit-react/cores/customCheckboxRadioSwitchStyle';

const modalStyle = {
  container,
  modal: {
    width: '100%',
    height: '100%',
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalTitle: {
    margin: '0',
    lineHeight: '1.42857143',
  },
  modalCloseButton: {
    color: '#999999',
    marginTop: '-12px',
    WebkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    fontSize: 'inherit',
    opacity: '.9',
    textShadow: 'none',
    fontWeight: '700',
    lineHeight: '1',
    float: 'right',
  },
  modalClose: {
    width: '16px',
    height: '16px',
  },
  modalBody: {
    padding: '24px 12rem',
    position: 'relative',
    // "@media (max-width: 1450px)": {
    //   padding: "24px 6rem",
    // },
    // "@media (max-width: 1260px)": {
    //   padding: "24px 3rem",
    // },
    // "@media (max-width: 1110px)": {
    //   padding: "24px 3rem",
    // },
    '@media (max-width: 768px)': {
      padding: '24px',
    },
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    paddingTop: '0',
    margin: '0',
  },
  modalFooterCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    ...title,
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  inputLabel: {
    fontSize: '15px',
  },
  icons: {
    width: '17px',
    height: '17px',
    color: '#FFFFFF',
  },
  ...customCheckboxRadioSwitch,
};

export default modalStyle;
