export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const CommonError = {
  TOKEN_ERROR: 'TOKEN_ERROR',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NETWORK_REQUEST_FAILED: 'NETWORK_REQUEST_FAILED',
};

export const ReducerStatus = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const ProductTypeCode = {
  GOLF_TEE_TIME: 'GOLF_TEE_TIME',
  GOLF_PACKAGE: 'GOLF_PACKAGE',
  GOLF_TOUR: 'GOLF_TOUR',
  TRAVEL: 'TRAVEL',
};

export const PackageTypeValue = {
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
};

export const APP_PRODUCT = {
  GOLF_COURSE: {
    EN: 'golf-course',
    VI: 'san-golf',
    ZH: '高尔夫球场',
  },

  GOLF_TOUR: {
    EN: 'golf-tour',
    VI: 'du-lich-choi-golf',
    ZH: '高尔夫之旅',
  },

  BLOG: {
    EN: 'blog',
    VI: 'blog',
    ZH: '博客',
  },

  TRAVEL: {
    EN: 'travel',
    VI: 'du-lich',
    ZH: '旅行',
  },
};
