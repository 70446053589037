import React, { useState } from 'react';

import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

// core
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
// import ClipLoader from 'react-spinners/ClipLoader';

// components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import Button from 'components/CustomButtons';
import CustomInput from 'components/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// redux
import { FormHelperText } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { postCheckEmail, postCheckUsername, postSignUp } from 'reduxs/global-actions';

// more
import modalStyle from 'assets/jss/material-kit-react/cores/modalStyle';
import configs from 'configs';
import styles from '../styles';
import { signUpSchema } from './schema';

const useStyles = makeStyles({ ...modalStyle, ...styles });

const LOGIN = 'LOGIN';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
));

function SignUp(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [openNotiModal, setOpenNotiModal] = useState(false);
  const [visibility, setVisibility] = useState(false);

  setTimeout(() => {
    setCardAnimation('');
  }, 700);

  const onSwitchHandle = () => {
    setOpenNotiModal('false');
    props.onSwitchLayout(LOGIN);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
    },
    validationSchema: signUpSchema,
    onSubmit: (values, formikHelpers) => {
      dispatch(
        postSignUp(values, {
          handleSuccess() {
            setOpenNotiModal(true);
            formikHelpers.resetForm();
          },
          handleFailed(data) {
            data?.message != null && formik.setFieldError('apiErrorMessage', data.message);
          },
        })
      );
    },
  });

  const onUsernameChange = e => {
    formik.handleChange(e);
    e.target.value.length >= 5 &&
      dispatch(
        postCheckUsername(
          { username: e.target.value },
          {
            handleSuccess() {
              const prevTouched = formik.touched;
              const prevErrors = formik.errors;
              if (prevTouched.username) {
                delete prevTouched.username;
                formik.setTouched({ ...prevTouched });
              }
              if (prevErrors.username) {
                delete prevErrors.username;
                formik.setErrors({ ...prevErrors });
              }
            },
            handleFailed(data) {
              if (data?.message != null) {
                formik.setFieldError('apiErrorMessage', data.message);
              } else {
                formik.setFieldTouched('username', true, false);
                formik.setFieldError('username', 'username already exists');
              }
            },
          }
        )
      );
  };

  const onEmailChange = e => {
    formik.handleChange(e);
    e.target.value &&
      configs.regex.email.test(e.target.value) &&
      dispatch(
        postCheckEmail(
          { email: e.target.value },
          {
            handleSuccess() {
              const prevTouched = formik.touched;
              const prevErrors = formik.errors;
              if (prevTouched.email) {
                delete prevTouched.email;
                formik.setTouched(prevTouched);
              }
              if (prevErrors.email) {
                delete prevErrors.email;
                formik.setErrors(prevErrors);
              }
            },
            handleFailed(data) {
              if (data?.message != null) {
                formik.setFieldError('apiErrorMessage', data.message);
              } else {
                formik.setFieldTouched('email', true, false);
                formik.setFieldError('email', 'Email already exists');
              }
            },
          }
        )
      );
  };

  const onFieldChange = e => {
    const { name, value } = e.target;
    // const prevTouched = formik.touched;
    // const prevErrors = formik.errors;

    formik.setFieldValue(name, value);
  };

  return (
    <div className={classes.container}>
      <GridContainer justifyContent='center'>
        <GridItem xs={12} sm={12} md={4}>
          <Card className={classes[cardAnimation]}>
            <form
              className='authForm'
              onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
              }}
              autoComplete='off'
            >
              <CardHeader color='success' className={classes.cardHeader}>
                <h4>Sign up</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText='First name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon fontSize='small'>people</Icon>
                      </InputAdornment>
                    ),
                    name: 'firstName',
                    value: formik.values.firstName,
                    onChange: onFieldChange,
                  }}
                  success={formik.touched.firstName && !formik.errors.firstName}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.errors.firstName}
                />

                <CustomInput
                  labelText='Last name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon fontSize='small'>people</Icon>
                      </InputAdornment>
                    ),
                    name: 'lastName',
                    value: formik.values.lastName,
                    onChange: formik.handleChange,
                  }}
                  success={formik.touched.lastName && !formik.errors.lastName}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.errors.lastName}
                />

                <CustomInput
                  labelText='Email'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'email',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon fontSize='small'>email</Icon>
                      </InputAdornment>
                    ),
                    name: 'email',
                    value: formik.values.email,
                    onChange: onEmailChange,
                  }}
                  success={formik.touched.email && !formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                />

                <CustomInput
                  labelText='Username'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Icon fontSize='small'>people</Icon>
                      </InputAdornment>
                    ),
                    name: 'username',
                    value: formik.values.username,
                    onChange: onUsernameChange,
                  }}
                  success={formik.touched.username && !formik.errors.username}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.errors.username}
                />

                <CustomInput
                  labelText='Password'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: visibility ? 'text' : 'password',
                    endAdornment: (
                      <>
                        <InputAdornment
                          position='end'
                          className={classes.cursorPointer}
                          onClick={() => setVisibility(prev => !prev)}
                        >
                          <Icon fontSize='small'>{`visibility${visibility ? '_off' : ''}`}</Icon>
                        </InputAdornment>
                        <InputAdornment position='end'>
                          <Icon fontSize='small'>lock_outline</Icon>
                        </InputAdornment>
                      </>
                    ),
                    name: 'password',
                    value: formik.values.password,
                    onChange: formik.handleChange,
                  }}
                  success={formik.touched.password && !formik.errors.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.errors.password}
                />
                {formik.errors && formik.errors.apiErrorMessage && (
                  <FormHelperText className='error-message' error>
                    {t(formik.errors.apiErrorMessage)}
                  </FormHelperText>
                )}
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  type='submit'
                  color='success'
                  disabled={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    (formik.touched.username && Boolean(formik.errors.username))
                  }
                >
                  <span>{t('Sign up')}</span>
                </Button>
                <p>
                  Do you have already account?{' '}
                  <a type='button' onClick={onSwitchHandle} style={{ cursor: 'pointer' }}>
                    {t('Login')}
                  </a>
                </p>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center,
        }}
        open={openNotiModal}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='md'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h3 className={classes.modalTitle}>{t('Sign up status')}</h3>
        </DialogTitle>
        <DialogContent id='modal-slide-description' className={classes.modalBody}>
          <h5>{t('Successfully registered a new account, please check your email to confirm')}</h5>
        </DialogContent>
        <DialogActions
          className={classNames({
            [classes.modalFooter]: true,
            [classes.modalFooterCenter]: true,
          })}
        >
          <Button onClick={onSwitchHandle} color='success'>
            {t('Login now')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SignUp.propTypes = {
  onSwitchLayout: PropTypes.func,
};

export default SignUp;
