import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const MainFrame = ({ children, ...rest }) => (
  <div className='MainFrame'>
    <div style={{ padding: '0 15px' }} {...rest}>
      {children}
    </div>
  </div>
);

MainFrame.propTypes = {
  children: PropTypes.node,
};

export default MainFrame;
