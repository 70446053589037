import { LOCAL_LANG } from 'global';
import { LOCALIZATION_I18N } from 'reduxs/actions';
import { getKey } from 'utils/localStorage';

const initialState = {
  i18n: false,
  status: {},
  localLang: getKey(LOCAL_LANG) || 'en',
  changeAccountLangError: '',
};

export const LocalizationData = (state = initialState, action) => {
  switch (action.type) {
    case LOCALIZATION_I18N:
      return {
        ...state,
        i18n: true,
      };

    default:
      return state;
  }
};
