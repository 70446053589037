// @material-ui/core components
import { Hidden, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/CustomButtons';

// redux
import { headerVisibleMobileDraw, postSignOut } from 'reduxs/global-actions';

// @material-ui/icons
import GTranslateIcon from '@material-ui/icons/GTranslate';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import SearchIcon from '@material-ui/icons/Search';

import classNames from 'classnames';
import SmoothLink from 'components/Routes/SmoothLink';
import { SHOW_ON } from 'global';
import useLangHistory from 'hooks/useLangHistory';
import HeaderDropdown, { MENU_TYPE } from '../HeaderDropdown';
import ItemList from './ItemList';
import styles from './styles';

const useStyles = makeStyles(styles);

function HeaderLinks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push, changeLanguagePath } = useLangHistory();
  const classes = useStyles();
  const authData = useSelector(state => state.authData);
  const { lang } = window;

  const hideMobileDraw = () => {
    dispatch(headerVisibleMobileDraw({ visible: false }));
  };

  const onHeaderLinkClick = href => {
    push(href);
    hideMobileDraw();
  };

  const onHandleAuth = e => {
    const value = e.target.getAttribute('value');
    switch (value) {
      case 'information':
        break;
      case 'sign-out':
        dispatch(postSignOut({ refreshToken: authData.data?.refreshToken }));
        break;

      default:
        break;
    }
  };

  const handleChangeLanguage = e => {
    changeLanguagePath(e.target.getAttribute('value'));
  };

  const buttonClasses = classNames({
    [classes.navLink]: true,
  });

  const buttonProps = {
    className: buttonClasses,
    color: 'transparent',
  };

  return (
    <List className={classes.list}>
      {ItemList.map(item => {
        const { name, menuType, label, dropdownList, showOn, href, hash } = item;
        const showOnMobile = showOn.includes(SHOW_ON.MOBILE);
        const showOnDesktop = showOn.includes(SHOW_ON.DESKTOP);

        if (name === 'login') {
          return (
            <Hidden
              key={name}
              mdUp={showOnMobile && !showOnDesktop}
              lgUp={!showOnMobile && showOnDesktop}
            >
              <ListItem className={classes.listItem}>
                {authData.data?.accessToken ? (
                  <HeaderDropdown
                    hoverColor='black'
                    buttonText={t(authData.data?.email)}
                    buttonProps={buttonProps}
                    onClick={onHandleAuth}
                    ButtonIcon={AccountCircleIcon}
                    dropdownList={[
                      {
                        name: 'information',
                        label: 'Information',
                      },
                      {
                        name: 'sign-out',
                        label: 'Sign out',
                      },
                    ]}
                    useEventClick
                  />
                ) : (
                  <Button
                    size='sm'
                    onClick={() => onHeaderLinkClick(href)}
                    className={classes.navLink}
                    color='transparent'
                  >
                    <AccountCircleIcon className={classes.icons} />
                    {t(label)}
                  </Button>
                )}
              </ListItem>
            </Hidden>
          );
        }

        if (name === 'language') {
          return (
            <Hidden
              key={name}
              mdUp={showOnMobile && !showOnDesktop}
              lgUp={!showOnMobile && showOnDesktop}
            >
              <ListItem key={name} className={classes.listItem}>
                <HeaderDropdown
                  hoverColor='black'
                  buttonText={t(dropdownList.find(el => el.name === lang)?.label)}
                  ButtonIcon={GTranslateIcon}
                  buttonProps={buttonProps}
                  onClick={handleChangeLanguage}
                  dropdownList={dropdownList}
                  useEventClick
                />
              </ListItem>
            </Hidden>
          );
        }

        if (menuType === MENU_TYPE.HASH_LINK) {
          return (
            <Hidden
              key={name}
              mdUp={showOnMobile && !showOnDesktop}
              lgUp={!showOnMobile && showOnDesktop}
            >
              <ListItem className={classes.listItem}>
                <SmoothLink to={hash} yOffset={0}>
                  <Typography className={classes.navLink}>{t(label)}</Typography>
                </SmoothLink>
              </ListItem>
            </Hidden>
          );
        }

        if (menuType === MENU_TYPE.DRAWER_DROPDOWN) {
          return (
            <Hidden
              key={name}
              mdUp={showOnMobile && !showOnDesktop}
              lgUp={!showOnMobile && showOnDesktop}
            >
              <ListItem className={classes.listItem}>
                <HeaderDropdown
                  hoverColor='black'
                  buttonText={t(label)}
                  buttonProps={buttonProps}
                  dropdownList={dropdownList}
                  drawer={menuType === MENU_TYPE.DRAWER_DROPDOWN}
                  href={href}
                />
              </ListItem>
            </Hidden>
          );
        }

        return (
          <Hidden
            key={name}
            mdUp={showOnMobile && !showOnDesktop}
            lgUp={!showOnMobile && showOnDesktop}
          >
            <ListItem className={classes.listItem}>
              <Button
                size='sm'
                onClick={() => onHeaderLinkClick(href)}
                className={classes.navLink}
                color='transparent'
              >
                {t(label)}
              </Button>
            </ListItem>
          </Hidden>
        );
      })}
    </List>
  );
}

export default HeaderLinks;
