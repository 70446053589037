export function getKey(key) {
  try {
    let value = null;
    if (localStorage != null) {
      value = localStorage.getItem(key);
    }
    if (value == null) {
      return null;
    }

    return JSON.parse(value);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log('[Local storage] [getItem] failed:', error);
    }
    return null;
  }
}

export function setKey(key, value) {
  try {
    if (value != null && localStorage != null) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log('[Local storage] [setItem] failed:', error);
    }
  }
}

export function removeKey(key) {
  try {
    if (localStorage != null) {
      localStorage.removeItem(key);
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log('[Local storage] [removeItem] failed:', error);
    }
  }
}
