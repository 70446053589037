import React from 'react';

import 'react-multi-carousel/lib/styles.css';

// Components
import ImageCardCluster from 'components/ImageCardCluster';
import ProductFlexCard from 'components/ProductFlexCard';

// Utils

// Redux
import { Typography, makeStyles } from '@material-ui/core';
import useCategories from 'hooks/useCategories';
import useLangHistory from 'hooks/useLangHistory';
import { useSelector } from 'react-redux';
import { getActionId, getLangString } from 'utils/common';
import styles from './styles';

const useStyle = makeStyles(styles);
const EuropeTourTravel = () => {
  const classes = useStyle();
  const { lang } = window;
  const { push } = useLangHistory();
  const { getParentPath } = useCategories();
  const travelList = useSelector(state => state.travelList);

  return (
    <main id='europe-tour-travel'>
      <ImageCardCluster
        title='Korea Tour Travel'
        background='korea-tour-background.jpg'
        buttonProps={{
          id: 'btn-home-europe-tour-more-products',
          onClick: () => {
            push('products/travel');
          },
        }}
        productCard={travelList.data
          ?.filter(it => it.categoryId === 8)
          ?.map(travel => (
            <ProductFlexCard
              key={travel.name}
              avatarImgUrl={travel.avatarImageUrl}
              contentChildren={
                <>
                  <Typography variant='subtitle1' className={classes.productTitle}>
                    {getLangString(travel.name, lang)}
                  </Typography>
                  {/* <Typography variant='caption' className={classes.productContent}>
                  {HTMLReactParser(getLangString(travel.description, lang))}
                </Typography> */}
                </>
              }
              regularPrice={travel.regularPrice}
              salePrice={travel.salePrice}
              buttonProps={{
                id: getActionId('btn', 'europe-tour-book-now', getLangString(travel.name, lang)),
                onClick: () => {
                  push(
                    `product-detail/travel/${getParentPath(travel.categoryId, travel.customPath)}`
                  );
                },
              }}
            />
          ))}
      />
    </main>
  );
};

export default EuropeTourTravel;
