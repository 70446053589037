import i18next from 'i18next';
import { isEqual } from 'lodash';
import { Route } from 'react-router-dom';

export const CACHING_PARAMS = 'CACHING_PARAMS';

export const getRoutePath = (...paths) => '/' + paths?.join('/') || '';

export const cachingParam = params => {
  if (params == null) {
    sessionStorage.removeItem(CACHING_PARAMS);
    return;
  }

  const prevParams = JSON.parse(sessionStorage.getItem(CACHING_PARAMS));
  if (isEqual(prevParams, params)) return;

  sessionStorage.setItem(CACHING_PARAMS, JSON.stringify(params));
};

export const generateRoute = (routes, Component) => {
  if (routes.length === 0) {
    return (
      <Route
        path={getRoutePath()}
        exact
        render={props => {
          cachingParam(props?.match?.params);
          return <Component />;
        }}
      />
    );
  }

  const { t } = i18next;
  const rootPath = t(routes[0]);
  const startIdx = 1;

  if (routes.length === 1) {
    return (
      <Route
        path={getRoutePath(rootPath)}
        exact
        render={props => {
          cachingParam(props?.match?.params);
          return <Component />;
        }}
      />
    );
  }

  return routes.slice(startIdx).map((el, idx) => {
    const subPath = routes
      .slice(startIdx, startIdx + idx + 1)
      .map(it => (it.includes('routes.') ? t(it) : it))
      .join('/');

    return (
      <Route
        key={routes.join('/')}
        path={getRoutePath(rootPath, subPath)}
        exact
        render={props => {
          cachingParam(props?.match?.params);

          return <Component />;
        }}
      />
    );
  });
};
