import { useEffect, useMemo, useState } from 'react';

// Libraries
import momentTimezone from 'moment-timezone';
import { useParams } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { queryTravelDetail, queryTravelList } from 'reduxs/global-actions';

// UTILS
import Banner from 'components/Banner';
import { BannerTypes } from 'components/Banner/enum';
import ReducerHelmet from 'components/ReducerHelmet';
import RegularProductDetail from 'components/RegularProductDetail';
import configs from 'configs';
import { ProductTypeCode, ReducerStatus } from 'constants/Enums';
import { META_TAG_OPTION, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import useCategories from 'hooks/useCategories';
import useConstructor from 'hooks/useContrucstor';
import useLangHistory from 'hooks/useLangHistory';
import BookingCard from './BookingCard';
import './styles.scss';

function TravelDetailPage() {
  const { getParentPath } = useCategories();
  const dispatch = useDispatch();
  const { push } = useLangHistory();
  const { productPath } = useParams();
  const travelList = useSelector(state => state.travelList);
  const travelDetail = useSelector(state => state.travelDetail);
  const [bookingData, setBookingData] = useState({
    productTypeCode: ProductTypeCode.TRAVEL,
    product: null,
    startDate: null,
    endDate: null,
    numOfGuest: 1,
    player: 1,
    nonPlayer: 0,
    quantity: 1,
    unitPrice: null,
    currencyCode: 'VND',
    hotDealOptions: null,
    avatarImg: null,
  });
  const [travelId, setTravelId] = useState(null);

  useConstructor(() => {
    if (travelList.status === ReducerStatus.REQUEST) {
      dispatch(queryTravelList());
    }
  });

  useEffect(() => {
    dispatch(
      queryTravelDetail(
        {
          customPath: productPath,
        },
        {
          handleSuccess(response) {
            setTravelId(response.data.id);
            setBookingData(prev => ({
              ...prev,
              product: { ...response.data, productName: response.data.name },
              startDate: momentTimezone.tz(response.data.timezone).format('yyyy-MM-DD'),
              endDate: momentTimezone.tz(response.data.timezone).format('yyyy-MM-DD'),
              avatarImg: response.data.avatarImageUrl,
              currencyCode: response.data.currencyCode,
              unitPrice: response.data.salePrice ?? response.data.regularPrice,
            }));
          },
        }
      )
    );
  }, [window.location.pathname]);

  const onDateChange = date => {
    const formatDate = momentTimezone(date).tz(configs.defaultTimezone).format('yyyy-MM-DD');
    setBookingData(prev => ({
      ...prev,
      startDate: formatDate,
      endDate: formatDate,
    }));
  };

  const handleMinusQuantity = () => {
    if (bookingData.quantity > 1)
      setBookingData(prev => {
        const newQty = prev.quantity - 1;
        return {
          ...prev,
          quantity: newQty,
          numOfGuest: newQty,
          player: newQty,
          nonPlayer: newQty,
          unitPrice: newQty * (prev.product.salePrice ?? prev.product.regularPrice),
        };
      });
  };

  const handlePlusQuantity = () => {
    setBookingData(prev => {
      const newQty = prev.quantity + 1;
      return {
        ...prev,
        quantity: newQty,
        numOfGuest: newQty,
        player: newQty,
        nonPlayer: newQty,
        unitPrice: newQty * (prev.product.salePrice ?? prev.product.regularPrice),
      };
    });
  };

  const onQuantityChange = e => {
    if (e.target.value >= 1)
      setBookingData(prev => {
        const newQty = e.target.value;
        return {
          ...prev,
          quantity: newQty,
          numOfGuest: newQty,
          player: newQty,
          nonPlayer: newQty,
          unitPrice: newQty * (prev.product.salePrice ?? prev.product.regularPrice),
        };
      });
  };

  const handleBooking = () => {
    push('complete-booking', bookingData);
  };

  const getBannerImages = useMemo(() => {
    let images = [`${window.location.origin}/img/Banner/golfcourse.jpg`];

    if (travelDetail.data?.detailImageUrlList) {
      if (typeof travelDetail.data?.detailImageUrlList === 'string') {
        try {
          const imageList = JSON.parse(travelDetail.data?.detailImageUrlList);
          images = [...imageList];
        } catch (error) {
          if (process.env.NODE_ENV === 'development') console.log('error', error);
        }
      } else if (Array.isArray(travelDetail.data?.detailImageUrlList)) {
        images = travelDetail.data.detailImageUrlList;
      }
    }

    return images;
  }, [travelDetail.data?.detailImageUrlList]);

  const suggestedProducts = useMemo(() => {
    if (travelList.data == null || travelDetail.data == null) return null;
    return travelList.data
      .filter(travel => travel.id !== travelDetail.data.id)
      .map(travel => ({
        avatarImageUrl: travel.avatarImageUrl,
        title: travel.name,
        // subtitle: HTMLReactParser(getLangString(travel.description, lang)),
        regularPrice: travel.regularPrice,
        salePrice: travel.salePrice,
        href: `product-detail/travel/${getParentPath(travel.categoryId, travel.customPath)}`,
      }));
  }, [travelList.data, travelDetail.data, getParentPath]);

  return (
    <>
      {travelId && (
        <ReducerHelmet
          option={META_TAG_OPTION.PAGE}
          productType={META_TAG_PRODUCT_TYPE.HOME_PAGE}
          id={travelId}
        />
      )}

      <Banner bannerType={BannerTypes.IMAGE_CAROUSEL} media={getBannerImages} />

      {travelDetail.data != null && travelDetail.status === ReducerStatus.SUCCESS ? (
        <RegularProductDetail
          title={travelDetail.data.name}
          basicDetails={travelDetail.data.description}
          suggestedProducts={suggestedProducts}
        >
          <BookingCard
            bookingData={bookingData}
            onDateChange={onDateChange}
            handleMinusQuantity={handleMinusQuantity}
            handlePlusQuantity={handlePlusQuantity}
            onQuantityChange={onQuantityChange}
            onBooking={handleBooking}
          />
        </RegularProductDetail>
      ) : null}
    </>
  );
}

export default TravelDetailPage;
