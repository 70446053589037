import { all } from 'redux-saga/effects';
import { allSagaImport } from 'utils/common';
import authenticationSagas from './Authentication';
import BlogSagas from './Blog';
import BookingSagas from './Booking';
import CategorySagas from './Category';
import GeoSagas from './GeoLocation';
import golfSagas from './GolfCourse';
import packageSagas from './GolfPackage';
import GolfToutSagas from './GolfTour';
import LocalizationSagas from './Localization';
import LocationSagas from './Location';
import MetaTagSagas from './MetaTag';
import orderSagas from './Order';
import PaymentSagas from './Payment';
import TeetimeSagas from './Teetime';
import TravelSagas from './Travel';
import VehicleSagas from './Vehicle';

export default function* sagas() {
  yield all([
    ...allSagaImport(LocalizationSagas),
    ...allSagaImport(golfSagas),
    ...allSagaImport(orderSagas),
    ...allSagaImport(packageSagas),
    ...allSagaImport(authenticationSagas),
    ...allSagaImport(TeetimeSagas),
    ...allSagaImport(GeoSagas),
    ...allSagaImport(BookingSagas),
    ...allSagaImport(PaymentSagas),
    ...allSagaImport(LocationSagas),
    ...allSagaImport(VehicleSagas),
    ...allSagaImport(BlogSagas),
    ...allSagaImport(GolfToutSagas),
    ...allSagaImport(TravelSagas),
    ...allSagaImport(CategorySagas),
    ...allSagaImport(MetaTagSagas),
  ]);
}
