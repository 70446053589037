import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import 'react-multi-carousel/lib/styles.css';

// Components
import ImageCardCluster from 'components/ImageCardCluster';
import ProductFlexCard from 'components/ProductFlexCard';

// Utils
import { getActionId, getLangString, isWeekend } from 'utils/common';

// Redux
import useLangHistory from 'hooks/useLangHistory';
import { useSelector } from 'react-redux';
import { queryGolfList } from 'reduxs/global-actions';
import store from 'reduxs/store';
import styles from './styles';

const useStyle = makeStyles(styles);

const GolfCourseBooking = () => {
  const classes = useStyle();
  const { push } = useLangHistory();
  const { lang } = window;
  const golfCourseList = useSelector(state => state.golfCourseList);

  React.useEffect(() => {
    store.dispatch(queryGolfList());
  }, []);

  return (
    <main id='golf-course-booking'>
      <ImageCardCluster
        title='Golf Course Booking'
        background='golfcourse-background.jpg'
        buttonProps={{
          id: 'btn-home-golf-course-more-products',
          onClick: () => {
            push('products/golf-course');
          },
        }}
        productCard={golfCourseList.data?.map(golfCourse => (
          <ProductFlexCard
            key={golfCourse.name}
            avatarImgUrl={golfCourse.avatarImageUrl}
            contentChildren={
              <>
                <Typography variant='subtitle1' className={classes.productTitle}>
                  {getLangString(golfCourse.name, lang)}
                </Typography>
                <Typography variant='caption'>{golfCourse.address}</Typography>
              </>
            }
            regularPrice={(() => {
              if (isWeekend()) {
                return golfCourse.weekendPrice.toLocaleString('en');
              }
              if (golfCourse.twilightPrice) {
                return golfCourse.twilightPrice.toLocaleString('en');
              }
              return golfCourse.weekdayPrice.toLocaleString('en');
            })()}
            buttonProps={{
              id: getActionId('btn', 'golf-course-card', getLangString(golfCourse.name, lang)),
              onClick: () => {
                push(`product-detail/golf-course/${golfCourse.customPath[window.lang]}`, {
                  data: golfCourse,
                });
              },
            }}
          />
        ))}
      />
    </main>
  );
};

export default GolfCourseBooking;
