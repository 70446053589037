import React, { useContext } from 'react';

// @material-ui/core components
import { Grow, MenuItem, MenuList, Paper, Popper, makeStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HeaderDropdownContext } from '../..';
import styles from './styles';

const useStyles = makeStyles(styles);

const HeaderDropdownMobile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const parentContext = useContext(HeaderDropdownContext);
  const {
    dropdownList,
    dropup,
    dropdownHeader,
    hoverColor,
    left,
    rtlActive,
    noLiPadding,
    onClick,
    useEventClick,
    anchorEl,
    setAnchorEl,
  } = parentContext;

  const popperClasses = classNames({
    [classes.popperClose]: !anchorEl,
    [classes.popperResponsive]: true,
  });
  const dropdownItemClasses = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + 'Hover']]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });

  const getPopperPlacement = () => {
    const placementMap = {
      dropup: {
        left: 'top-start',
        right: 'top-end',
      },
      dropdown: {
        left: 'bottom-start',
        right: 'bottom-end',
      },
    };

    return placementMap[dropup ? 'dropup' : 'dropdown'][left ? 'left' : 'right'];
  };

  const handleClose = param => {
    setAnchorEl(null);
    onClick?.(param);
  };

  const handleCloseAway = event => {
    if (!anchorEl?.contains?.(event.target)) {
      setAnchorEl(null);
    }
  };

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement={getPopperPlacement()}
      className={popperClasses}
    >
      <Grow
        in={Boolean(anchorEl)}
        id='menu-list'
        style={{ transformOrigin: dropup ? '0 100% 0' : '0 0 0' }}
      >
        <Paper className={classes.dropdown}>
          <ClickAwayListener onClickAway={handleCloseAway}>
            <MenuList role='menu' className={classes.menuList}>
              {dropdownHeader ? (
                <MenuItem
                  onClick={() => handleClose(dropdownHeader)}
                  className={classes.dropdownHeader}
                >
                  {dropdownHeader}
                </MenuItem>
              ) : null}

              {dropdownList?.map(el => (
                <MenuItem
                  key={el.name}
                  onClick={event => handleClose((useEventClick && event) || el)}
                  className={dropdownItemClasses}
                  value={el.name}
                >
                  {t(el.label)}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    </Popper>
  );
};

export default HeaderDropdownMobile;
