import { OutlinedInput, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

const AutoCompleteComponent = ({
  label,
  defaultValue,
  value,
  onChange,
  options,
  getOptionLabel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.label} variant='caption'>
        {t(label)}
      </Typography>
      <Autocomplete
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        noOptionsText='No labels'
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={params => (
          <OutlinedInput ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
        )}
        autoHighlight
      />
    </div>
  );
};

AutoCompleteComponent.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AutoCompleteComponent;
