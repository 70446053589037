import { combineReducers } from 'redux';
import { UPDATE_DEBUGGING } from 'reduxs/actions';
import * as authenticationReducer from './Authentication';
import * as blogReducer from './Blog';
import * as categoryReducer from './Category';
import * as geoReducer from './GeoLocation';
import * as golfCourseReducer from './GolfCourse';
import * as golfPackageReducer from './GolfPackage';
import * as golfTourReducer from './GolfTour';
import * as headerReducer from './Header';
import * as localizationReducer from './Localization';
import * as locationReducer from './Location';
import * as metaTagReducer from './MetaTag';
import * as orderGolfConfirm from './OrderGolfConfirm';
import * as paymentReducer from './Payment';
import * as teetimeReducer from './Teetime';
import * as travelReducer from './Travel';
import * as vehicleReducer from './Vehicle';

const IsDebugging = (state = window.location.search.includes('debug'), action) => {
  switch (action.type) {
    case UPDATE_DEBUGGING:
      return action.payload;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  // Debugging
  isDebugging: IsDebugging,

  // Localization
  localizationData: localizationReducer.LocalizationData,

  // GOLF
  golfCourseList: golfCourseReducer.GolfCourseList,
  golfCourseDetail: golfCourseReducer.GolfCourseDetail,
  orderGolfData: orderGolfConfirm.OrderGolfData,

  // PACKAGE
  golfPackageList: golfPackageReducer.GolfPackageList,
  golfPackageDetail: golfPackageReducer.GolfPackageDetail,

  // AUTHENTICATION
  authData: authenticationReducer.AuthData,
  activeUser: authenticationReducer.ActiveUser,

  // TEETIMES
  teetimeList: teetimeReducer.TeetimeList,

  // LOCATION
  geoLocation: geoReducer.GeoLocation,
  regionList: locationReducer.RegionList,
  countryList: locationReducer.CountryList,
  stateList: locationReducer.StateList,
  cityList: locationReducer.CityList,

  // PAYMENT
  paymentInfo: paymentReducer.PaymentInfo,

  // HEADER
  visibleMobileDrawer: headerReducer.VisibleMobileDrawer,

  // VEHICLE
  vehicleTripList: vehicleReducer.VehicleTripList,

  // BLOG
  blogList: blogReducer.BlogList,
  blogDetail: blogReducer.BlogDetail,

  // GOLF TOUR
  golfTourList: golfTourReducer.GolfTourList,
  golfTourDetail: golfTourReducer.GolfTourDetail,

  // TRAVEL
  travelList: travelReducer.TravelList,
  travelDetail: travelReducer.TravelDetail,

  // CATEGORY
  categories: categoryReducer.Categories,

  // META TAG
  metaTagDetail: metaTagReducer.MetaTagDetail,
});

export default (state, action) => appReducer(state, action);
