import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { PACKAGE_QUERY_DETAIL_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryPackageDetailData(request) {
  try {
    const response = yield call(query, apis.getGolfPackageDetail, request.payload);
    const { data } = response;
    yield put({
      type: request.response.success,
      payload: data,
    });
    request.callback?.handleSuccess?.({ data });
  } catch (error) {
    request.callback?.handleFail?.();
    console.error('Query package data', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryPackageDetailData() {
  yield takeLatest(REQUEST(PACKAGE_QUERY_DETAIL_DATA), doQueryPackageDetailData);
}
