import { colors } from 'assets/jss/global-styles';

export default {
  container: {
    borderRight: `1px solid ${colors.LIGHT_GRAY}`,
    paddingLeft: 0,
    paddingRight: '10px',

    '@media (max-width: 959px)': {
      backgroundColor: colors.WHITE,
      padding: '1rem !important',
      borderRadius: '1rem !important',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
      borderRight: 'none',
    },
  },

  filterContent: {
    padding: '1rem 0',
  },
};
