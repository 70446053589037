import apis from 'configs/apis';
import { call, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { AUTHENTICATION_CHECK_EMAIL } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryCheckEmail(request) {
  try {
    const response = yield call(query, apis.postCheckEmail, request.payload);
    const { data } = response;

    if (data != null) {
      yield data
        ? request.callback?.handleSuccess != null && request.callback.handleSuccess()
        : request.callback?.handleFailed != null && request.callback.handleFailed();
    }
  } catch (error) {
    console.error('Query check email failed', error);
    yield request.callback?.handleFailed != null &&
      request.callback.handleFailed({ message: 'INTERNAL_SERVER_ERROR' });
  }
}

export default function* watchQueryCheckEmail() {
  yield takeLatest(REQUEST(AUTHENTICATION_CHECK_EMAIL), doQueryCheckEmail);
}
