export const socialList = [
  {
    type: 'link',
    name: 'facebook',
    imgUrl: 'img/SocialNetwork/facebook.png',
    href: 'https://www.facebook.com/Golf-Adventure-Tours-2292871747486514/',
  },
  {
    type: 'link',
    name: 'twitter',
    imgUrl: 'img/SocialNetwork/twitter.png',
    href: 'https://twitter.com/GolfAdventureVN',
  },
  {
    type: 'link',
    name: 'instagram',
    imgUrl: 'img/SocialNetwork/instagram.png',
    href: 'https://www.instagram.com/golfadventurevn/',
  },
  {
    type: 'link',
    name: 'youtube',
    imgUrl: 'img/SocialNetwork/youtube.png',
    href: 'https://www.youtube.com/channel/UCaaSdUfkevdGZ9qqDwom_sQ?view_as=subscriber',
  },
  { type: 'qr', name: 'line', imgUrl: 'img/SocialNetwork/line.png' },
  { type: 'qr', name: 'viber', imgUrl: 'img/SocialNetwork/viber.png' },
  { type: 'qr', name: 'weechat', imgUrl: 'img/SocialNetwork/weechat.png' },
  { type: 'qr', name: 'zalo', imgUrl: 'img/SocialNetwork/zalo.png' },
  { type: 'qr', name: 'whatsapp', imgUrl: 'img/SocialNetwork/whatapp.png' },
];

export const companyServices = [
  [
    {
      groupName: 'Menu',
      items: [
        {
          label: 'Golf Courses',
          href: 'products/golf-course',
        },
        {
          label: 'Golf Tours',
          href: 'products/golf-tour',
        },
        {
          label: 'Travel',
          href: 'products/travel',
        },
        {
          label: 'Blog',
          href: 'products/blog',
        },
        // {
        //   label: 'Promotion',
        //   href: 'promotion',
        // },
      ],
    },
    {
      groupName: 'Policy',
      items: [
        {
          label: 'Privacy Policy',
          href: 'privacy-policy',
          hash: '#privacy-policy',
        },
        {
          label: 'Terms and Conditions of Use',
          href: 'privacy-policy',
          hash: '#terms-and-conditions-of-use',
        },
        {
          label: 'Cookie Policy',
          href: 'privacy-policy',
          hash: '#cookie-policy',
        },
        {
          label: 'Feedback Policy',
          href: 'privacy-policy',
          hash: '#feedback-policy',
        },
        {
          label: 'Security Policy',
          href: 'privacy-policy',
          hash: '#security-policy',
        },
      ],
    },
  ],
  [
    {
      groupName: 'About Us',
      items: [
        {
          label: 'Adventure address saigon 1',
          href: 'https://maps.app.goo.gl/LnLiGG59LzDVJN8o9',
          target: '_blank',
        },
        {
          label: 'Adventure address saigon 2',
          href: 'https://maps.app.goo.gl/dem5rtugey4qiNMb8',
          target: '_blank',
        },
        {
          label: 'Adventure address hanoi',
          href: 'https://maps.app.goo.gl/14bLDE2JnbgQ7h4P7',
          target: '_blank',
        },
        {
          label: 'Email: travel@golfadventurebooking.com',
          href: 'mailto:travel@golfadventurebooking.com',
          target: '_blank',
        },
      ],
    },
  ],
];
