import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';

import { TEE_TIMES_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

// eslint-disable-next-line import/prefer-default-export
export const TeetimeList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(TEE_TIMES_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(TEE_TIMES_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(TEE_TIMES_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
