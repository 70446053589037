import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import RegularButton from 'components/CustomButtons';
import { ReducerStatus } from 'constants/Enums';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datetime';
import { useTranslation } from 'react-i18next';
// icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import classNames from 'classnames';
import CustomInput from 'components/CustomInput';

const TeetimeTable = ({ status, teetimes, selectedId, onItemClick }) => {
  if (status === ReducerStatus.LOADING || status === ReducerStatus.FAILURE || teetimes == null) {
    return [1, 2, 3, 4].map(() => (
      <div className='skeleton-item'>
        <Skeleton variant='rect' width='100%' height={40} />
      </div>
    ));
  }

  return teetimes.length === 0 ? (
    <div className='not-found-item'>
      <Typography variant='body1'>No tee time available</Typography>
    </div>
  ) : (
    teetimes.map((teetime, idx) => (
      <RegularButton
        key={idx}
        className='content-item'
        color={selectedId === teetime.uuid ? 'success' : 'transparent'}
        onClick={() => onItemClick(teetime)}
      >
        <div className='table-block'>
          <Typography variant='subtitle1'>{teetime.time}</Typography>
        </div>
        <div className='table-block'>
          <Typography variant='subtitle1'>{teetime.price.toLocaleString('en')}</Typography>
        </div>
      </RegularButton>
    ))
  );
};

TeetimeTable.propTypes = {
  status: PropTypes.string,
  teetimes: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      time: PropTypes.string,
      price: PropTypes.string,
    })
  ),
  selectedId: PropTypes.string,
  onItemClick: PropTypes.func,
};

const BookingCard = ({
  bookingData,
  onDateChange,
  onBooking,
  handleMinusQuantity,
  handlePlusQuantity,
  onQuantityChange,
}) => {
  const { t } = useTranslation();

  const onSubmit = e => {
    e.preventDefault();
    onBooking();
  };

  return (
    <Card className='travel-booking-card'>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Typography variant='h6' align='center' className='card-title'>
            {t('Select Date, Player & Tee Time')}
          </Typography>
          <div className='booking-option'>
            <div className='booking-option__date-picker'>
              <Typography variant='subtitle2'>{t('Select Date')}</Typography>
              <DatePicker
                onChange={onDateChange}
                inputProps={{
                  placeholder: 'Choose your date',
                  className: 'form-control text-center custom-date-picker',
                }}
                closeOnSelect
                closeOnClickOutside
                timeFormat={false}
                value={moment(bookingData.startDate)}
                dateFormat='MMMM DD YYYY'
              />
            </div>
            <div className='booking-option__player-picker'>
              <Typography variant='subtitle2'>{t('Select Player')}</Typography>
              <div>
                <RegularButton
                  justIcon
                  round
                  color='transparent'
                  onClick={handleMinusQuantity}
                  size='sm'
                >
                  <RemoveIcon />
                </RegularButton>
                <CustomInput
                  formControlProps={{
                    className: 'customIncrease',
                  }}
                  inputProps={{
                    type: 'number',
                    value: bookingData.quantity,
                    onChange: onQuantityChange,
                  }}
                />
                <RegularButton
                  justIcon
                  round
                  color='transparent'
                  onClick={handlePlusQuantity}
                  size='sm'
                >
                  <AddIcon />
                </RegularButton>
              </div>
            </div>
          </div>

          <div className='booking-price'>
            <Typography
              variant='subtitle2'
              className={classNames({
                'old-price': bookingData?.product?.salePrice,
              })}
            >
              {(bookingData?.product?.regularPrice * bookingData.quantity)?.toLocaleString('en') ??
                0}
            </Typography>
            {bookingData?.product?.salePrice && (
              <Typography variant='h4' className='sale-price'>
                {(bookingData.product.salePrice * bookingData.quantity).toLocaleString('en')}
              </Typography>
            )}
          </div>
        </CardContent>

        <CardActions className='card-actions'>
          <RegularButton id='btn-travel-detail-book-now' type='submit' color='success'>
            {t('Book Now')}
          </RegularButton>
        </CardActions>
      </form>
    </Card>
  );
};

BookingCard.propTypes = {
  bookingData: PropTypes.shape({
    productTypeCode: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.number,
      categoryId: PropTypes.number,
      name: PropTypes.string,
      customPath: PropTypes.string,
      description: PropTypes.string,
      regularPrice: PropTypes.number,
      salePrice: PropTypes.number,
      avatarImageUrl: PropTypes.string,
      detailImageUrlList: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numOfGuest: PropTypes.number,
    player: PropTypes.number,
    nonPlayer: PropTypes.number,
    quantity: PropTypes.number,
    unitPrice: PropTypes.number,
    currencyCode: PropTypes.string,
    hotDealOptions: PropTypes.object,
    avatarImg: PropTypes.string,
  }),
  onDateChange: PropTypes.func,
  onBooking: PropTypes.func,
  handleMinusQuantity: PropTypes.func,
  handlePlusQuantity: PropTypes.func,
  onQuantityChange: PropTypes.func,
};

export default BookingCard;
