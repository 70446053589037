import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

// core components

import buttonStyle from 'assets/jss/material-kit-react/components/buttonStyle';

const makeComponentStyles = makeStyles(() => ({
  ...buttonStyle,
}));

const RegularButton = React.forwardRef(
  (
    {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      ...rest
    },
    ref
  ) => {
    const classes = makeComponentStyles();

    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });

    return (
      <Button {...rest} ref={ref} className={btnClasses}>
        {children}
      </Button>
    );
  }
);

export const RegularButtonPropTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf([
    'primary', // #9c27b0
    'info', // #00acc1
    'success', // #4caf50
    'Green', // #40A034
    'warning', // #ff9800
    'danger', // #f44336
    'rose', // #e91e63
    'white', // #fff
    'facebook', // #3b5998
    'twitter', // #55acee
    'google', // #dd4b39
    'github', // #333333
    'transparent', // transparent
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

RegularButton.propTypes = RegularButtonPropTypes;

export default RegularButton;
