export const LANG = {
  VI: 'vi',
  EN: 'en',
  ZH: 'zh',
};

export const SHOW_ON = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const LOCAL_LANG = 'LOCAL_LANG';
