import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import {
  PACKAGE_QUERY_DETAIL_DATA,
  PACKAGE_QUERY_LIST_DATA,
  PACKAGE_QUERY_SURCHARGE_DATA,
} from 'reduxs/actions';
import { generateAction } from 'utils/createApiSaga';

export const queryPackageList = payload => ({
  type: REQUEST(PACKAGE_QUERY_LIST_DATA),
  payload,
  response: {
    success: SUCCESS(PACKAGE_QUERY_LIST_DATA),
    failed: FAILURE(PACKAGE_QUERY_LIST_DATA),
  },
});

export const queryPackageDetail = generateAction(PACKAGE_QUERY_DETAIL_DATA);

export const queryPackageSurcharge = payload => ({
  type: REQUEST(PACKAGE_QUERY_SURCHARGE_DATA),
  payload,
  response: {
    success: SUCCESS(PACKAGE_QUERY_SURCHARGE_DATA),
    failed: FAILURE(PACKAGE_QUERY_SURCHARGE_DATA),
  },
});
