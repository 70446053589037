import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { GOLF_QUERY_DETAIL_DATA, GOLF_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

export const GolfCourseList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(GOLF_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(GOLF_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(GOLF_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const GolfCourseDetail = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(GOLF_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(GOLF_QUERY_DETAIL_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(GOLF_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
