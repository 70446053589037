import config from 'configs';
import apis from 'configs/apis';
import { CommonError, METHOD } from 'constants/Enums';
import i18n from 'i18next';
import { setAuthToken } from 'reduxs/global-actions';
import store from 'reduxs/store';
import 'url-search-params-polyfill';

const { baseURI, apiPath } = config;

let refreshTokenPromise = null;

const headersAIRating = new Headers();
headersAIRating.append('Content-Type', 'application/json');
headersAIRating.append(
  'User-Agent',
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.82 Safari/537.36'
);
headersAIRating.append('Connection', 'keep-alive');
headersAIRating.append('Pragma', 'no-cache');
headersAIRating.append('Cache-Control', 'no-cache');
headersAIRating.append(
  'sec-ch-ua',
  '" Not A;Brand";v="99", "Chromium";v="98", "Google Chrome";v="98"'
);
headersAIRating.append('Accept', 'application/json');
headersAIRating.append('sec-ch-ua-mobile', '?0');
headersAIRating.append('sec-ch-ua-platform', 'Windows');
headersAIRating.append('Origin', 'https://vinance.vn');
headersAIRating.append('Sec-Fetch-Site', 'same-origin');
headersAIRating.append('Sec-Fetch-Mode', 'cors');
headersAIRating.append('Sec-Fetch-Dest', 'empty');
headersAIRating.append('Accept-Language', 'vi,en;q=0.9');
headersAIRating.append(
  'Cookie',
  '_ga=GA1.1.470123729.1637120080; _ga_SG7GLBZ93H=GS1.1.1644482102.8.1.1644482215.0'
);

function refreshTokenWithRetry(refreshTokenRequest, resolve, reject, retry = 0) {
  query(apis.postRefreshToken, refreshTokenRequest)
    .then(response => resolve(response.data))
    .catch(error => {
      if (retry >= 3) {
        reject(error);
      } else {
        refreshTokenWithRetry(refreshTokenRequest, resolve, reject, retry + 1);
      }
    });
}

async function queryRest(
  uri,
  method,
  params,
  baseUri,
  index,
  authenticated,
  otpToken,
  notShowAlert,
  notShowAlertWithAuth,
  notShowAlertWithCodes,
  useBodyOnDeleteMethod
) {
  if (
    authenticated &&
    (!store.getState().authData.data?.accessToken ||
      store.getState().authData.data?.accessToken === '')
  ) {
    throw new Error(CommonError.LOGIN_REQUIRED);
  }

  return queryRestWithToken(
    uri,
    method,
    params,
    baseUri,
    index,
    authenticated ? store.getState().authData.data?.accessToken : undefined,
    otpToken,
    notShowAlert,
    notShowAlertWithAuth,
    notShowAlertWithCodes,
    useBodyOnDeleteMethod
  );
}

async function queryRestWithToken(
  uri,
  method,
  params = {},
  baseUri,
  index,
  accessToken,
  otpToken,
  notShowAlert,
  notShowAlertWithAuth,
  notShowAlertWithCodes,
  useBodyOnDeleteMethod
) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    store.dispatch({ type: 'HIDE_LOADING', hideLoading: true });
    if (notShowAlert !== true) {
      console.error(i18n.t('Connection to server failed'), i18n.t('Please contact administrator'));
    }
  }, 8000); // fetch() timeouts at 8 seconds
  let parsedUri = `${baseUri}${uri}`;
  let localBody = {};
  const searchParams = [];
  let hasBody = false;
  if (params != null) {
    if (
      (method === METHOD.PUT ||
        method === METHOD.POST ||
        (method === METHOD.DELETE && useBodyOnDeleteMethod)) &&
      Array.isArray(params)
    ) {
      hasBody = true;
      localBody = params;
    } else {
      Object.keys(params).forEach(requestKey => {
        const requestValue = params[requestKey];
        const pathParm = `{${requestKey}}`;
        if (parsedUri.indexOf(pathParm) > -1) {
          parsedUri = parsedUri.replace(pathParm, requestValue);
        } else if (method === METHOD.GET || (method === METHOD.DELETE && !useBodyOnDeleteMethod)) {
          if (requestValue == null) {
            return;
          }
          searchParams.push({
            key: requestKey,
            value: requestValue,
          });
        } else {
          localBody[requestKey] = requestValue;
          hasBody = true;
        }
      });
    }
  }

  const urlSearchParams = new URLSearchParams();

  searchParams.forEach(item => {
    if (Array.isArray(item.value)) {
      urlSearchParams.set(item.key, item.value.join(','));
    } else {
      urlSearchParams.set(item.key, item.value);
    }
  });

  const localeHeaders = {
    'accept-language': window.lang,
    // platform: 'web',
    // rid: rId.toString(),
    ...(otpToken != null && { otpToken }),
  };

  if (accessToken) {
    localeHeaders.authorization = `jwt ${accessToken}`;
  }
  const localRequestOptions = {
    method: method.toUpperCase(),
    headers: localeHeaders,
    // signal: controller,
    signal: controller.signal,
  };

  if (
    method === METHOD.POST ||
    method === METHOD.PUT ||
    (method === METHOD.DELETE && useBodyOnDeleteMethod)
  ) {
    localeHeaders['Content-Type'] = 'application/json';
    if (hasBody) {
      localRequestOptions.body = JSON.stringify(localBody);
    }
  }

  let finalUrl = parsedUri;
  if (
    finalUrl === 'https://mastrade.masvn.com/files/resources/symbol_static_data.json' ||
    finalUrl.includes('https://mastrade.masvn.com/rest/api/v2/market')
  ) {
    localeHeaders['User-Agent'] =
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.80 Safari/537.36';
  }
  if (urlSearchParams.toString()) {
    finalUrl = `${finalUrl}?${urlSearchParams}`;
  }

  // const time = new Date().getTime();
  let response;
  try {
    response = await fetch(finalUrl, localRequestOptions);
  } catch (e) {
    // if (__DEV__) console.error('fail to query', finalUrl, e);
    throw new Error(CommonError.NETWORK_REQUEST_FAILED);
  } finally {
    store.dispatch({ type: 'HIDE_LOADING', hideLoading: true });
    clearTimeout(timeoutId);
  }

  if (response.status >= 200 && response.status < 300) {
    const data = await response.json();
    if (data.statusCode && (data.statusCodeValue < 200 || data.statusCodeValue >= 300)) {
      if (notShowAlert !== true) {
        if (notShowAlertWithCodes == null || !notShowAlertWithCodes.includes(data.body.errorCode)) {
          console.error(i18n.t(data.body.errorCode || ''), i18n.t(data.body.errorMsg || ''));
        }
      }
    }

    const resultData =
      data != null ? (data.result && (data.result.data ?? data.result)) ?? data : undefined;
    return {
      data: resultData,
    };
  }
  if (response.status >= 500) {
    if (notShowAlert !== true) {
      console.error(i18n.t('Internal server error'), i18n.t('Please contact administrator'));
    }
    throw new Error(CommonError.INTERNAL_SERVER_ERROR);
  } else if (response.status === 401 || response.status === 403) {
    const storeAccessToken = store.getState().authData.data?.accessToken;
    const storeRefreshToken = store.getState().authData.data?.refreshToken;
    console.log(store.getState().authData.data);
    // If you are not logged in, calling the api will ask for login
    if (storeAccessToken == null && !notShowAlertWithAuth) {
      console.error(i18n.t('Token error'), i18n.t('Login is required'));
    }
    // throw new Error('TOKEN_ERROR'); // consider later
    if (storeRefreshToken != null) {
      // REFRESH ACCESS TOKEN WHEN TOKEN IS EXPIRED
      const creator = refreshTokenPromise == null;
      if (creator) {
        refreshTokenPromise = new Promise((resolve, reject) => {
          const refreshTokenRequest = {
            refresh_token: storeRefreshToken,
          };
          refreshTokenWithRetry(refreshTokenRequest, resolve, reject);
        });
      }
      const refreshResponse = await refreshTokenPromise;
      if (creator) {
        refreshTokenPromise = null;
      }
      store.dispatch(setAuthToken(refreshResponse));
      // return queryRestWithToken(
      //   uri,
      //   method,
      //   params,
      //   baseUri,
      //   index,
      //   undefined,
      //   otpToken,
      //   notShowAlert,
      //   notShowAlertWithAuth,
      //   notShowAlertWithCodes,
      //   useBodyOnDeleteMethod,
      // );
    }
    // return queryRest(
    //   uri,
    //   method,
    //   params,
    //   baseUri,
    //   1,
    //   true,
    //   otpToken,
    //   notShowAlert,
    //   notShowAlertWithAuth,
    //   notShowAlertWithCodes,
    // );
  } else if (response.status === 400) {
    const data = await response.json();
    if (
      !(finalUrl.includes('equity/account/sellable') && data.code === 'INVALID_OWNERSHIP_STOCK')
    ) {
      if (notShowAlert !== true) {
        if (notShowAlertWithCodes == null || !notShowAlertWithCodes.includes(data.code)) {
          console.error(i18n.t(data.code, data.message));
        }
      }
    }
    throw data;
  } else {
    throw new Error(CommonError.INTERNAL_SERVER_ERROR);
  }
}

export async function requester(api, params = {}, customBaseURI) {
  const method = api.method == null ? METHOD.GET : api.method;
  const URI = customBaseURI || (api.useFullUri ? '' : `${baseURI}${api.customBasePath || apiPath}`);

  return queryRest(
    api.uri,
    method,
    params,
    URI,
    undefined,
    api.authenticated,
    undefined,
    api.notShowAlert,
    api.notShowAlertWithAuth,
    api.notShowAlertWithCodes,
    api.useBodyOnDeleteMethod
  );
}

export async function query(api, params = {}, customBaseURI) {
  return requester(api, params, customBaseURI);
}
