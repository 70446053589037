import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { PACKAGE_QUERY_LIST_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryPackageData(request) {
  try {
    const response = yield call(query, apis.getGolfPackageList, request.payload);

    yield put({
      type: request.response.success,
      payload: response.data,
    });
  } catch (error) {
    console.error('Query golf package fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
      });
    }
  }
}

export default function* watchQueryPackageData() {
  yield takeLatest(REQUEST(PACKAGE_QUERY_LIST_DATA), doQueryPackageData);
}
