import { dangerColor, successColor, container, title, mainRaised } from 'assets/jss/global-styles';

const Styles = {
  container,
  title,
  mainRaised,
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised2: {
    margin: '0 30px 0px',
    borderRadius: '20px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  section: {
    width: '100%',
    margin: '70px 0',
    padding: '0 2rem',
    '@media (min-width: 1260px)': {
      padding: '0 8rem',
    },
    '@media (min-width: 1400px)': {
      padding: '0 15rem',
    },
    // '@media (min-width: 959px)': {
    //   padding: '0 10rem',
    // },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  billingContainer: {
    width: '100%',
    marginTop: '70px',
    padding: '30px 50px',
  },
  billingItem: {
    display: 'inline-flex',
  },
  gifImg: {
    width: '100%',
  },
  gifImgFailed: {
    width: '100%',
  },
  margin0: {
    marginLeft: 0,
  },
  ButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  paymentStatusContainer: {
    placeSelf: 'center',
    textAlignLast: 'center',
  },
  paymentTxt: {
    margin: 0,
  },
  PaymentSuccessTxt: {
    color: successColor,
  },
  PaymentFailedTxt: {
    color: dangerColor,
    fontWeight: '500',
  },
  SummaryTxt: {
    fontWeight: '400',
  },
};

export default Styles;
