import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.min.css';
import 'swiper/swiper.min.css';
import './styles.scss';

const ThumbsGalleryCarousel = ({ media }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className='ThumbsGlCarousel'>
      <div className='ThumbsGlCarousel__WrapperContainer'>
        <Swiper
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          spaceBetween={10}
          navigation
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className='ThumbsGlCarousel__WrapperContainer__Wrapper'
        >
          {media?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt='thumbs-gallery-img' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='ThumbsGlCarousel__WatchWrapperContainer'>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={3}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
          className='ThumbsGlCarousel__WatchWrapperContainer__WatchWrapper'
          slideActiveClass='ActiveThumb'
        >
          {media?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt='thumbs-gallery-img' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

ThumbsGalleryCarousel.propTypes = {
  media: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ThumbsGalleryCarousel;
