const headerDropdownStyles = theme => ({
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '4px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  caretRTL: {
    marginRight: '4px',
  },
  ButtonIcon: {
    width: '20px',
    height: '20px',
  },
});

export default headerDropdownStyles;
