import useLangHistory from 'hooks/useLangHistory';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const SmoothLink = ({ id, href, to, target, yOffset, children }) => {
  const { isMatchedPath, push } = useLangHistory();
  const history = useHistory();
  const nextHash = useRef(null);

  const scrollToHash = () => {
    if (nextHash.current == null) return;
    const element = document.getElementById(nextHash.current.replace('#', ''));

    if (element) {
      const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
      const newYOffset = yOffset ?? -4.7 * 16;
      window.scrollTo({ top: yCoordinate + newYOffset, behavior: 'smooth' });
    }
    nextHash.current = null;
  };

  const onClick = () => {
    if (to) nextHash.current = to;

    if (href == null) {
      scrollToHash();
      return;
    }

    if (target === '_blank') {
      window.open(href, '_blank');
      return;
    }

    if (nextHash.current && isMatchedPath(href)) {
      scrollToHash();
      return;
    }

    push(href);
  };

  useEffect(() => {
    scrollToHash();
  }, [history.location.pathname]);

  return (
    <button id={id} type='button' onClick={onClick} className='smooth-custom-link'>
      {children}
    </button>
  );
};

SmoothLink.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  yOffset: PropTypes.number,
};

export default SmoothLink;
