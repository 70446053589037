import { REQUEST } from 'reduxs/action-type-utils';
import { HEADER_VISIBLE_MOBILE_DRAW } from 'reduxs/actions';

export const VisibleMobileDrawer = (state = { visible: false }, action) => {
  switch (action.type) {
    case REQUEST(HEADER_VISIBLE_MOBILE_DRAW):
      return action.payload;
    default:
      return state;
  }
};
