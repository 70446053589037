export const getDayCodeFromDate = date => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[new Date(date).getDay()];
};

export const getDayCodePriceFromDate = date => {
  const days = [
    'sunday_price',
    'monday_price',
    'tuesday_price',
    'wednesday_price',
    'thursday_price',
    'friday_price',
    'saturday_price',
  ];
  return days[new Date(date).getDay()];
};

export const sumArray = (data, key) =>
  data.map(item => item[key]).reduce((prev, curr) => prev + curr, 0);
