const Styles = {
  productTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal', // Sử dụng 'normal' để tránh cắt ngắn và hiển thị '...'
    maxHeight: 60,
    fontWeight: '500',
  },
  productContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    maxHeight: 60,
    fontSize: '12px',
  },
};

export default Styles;
