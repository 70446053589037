import * as yup from 'yup';

export const signUpSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),

  username: yup
    .string()
    .required('Username is required')
    .min(5, 'The length of the username is from 5 to 20 characters.')
    .max(20, 'The length of the username is from 5 to 20 characters.'),

  password: yup
    .string()
    .required('Password is required')
    .max(30, 'The max length of the username is 20 characters.'),
  // .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
  // .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
  // .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
  // .matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character'),
});

export const signInSchema = yup.object({
  username: yup.string().required('Username can not be blank.'),
  password: yup.string().required('Password can not be blank.'),
  apiErrorMessage: yup.string().nullable(),
});
