import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';

import { VEHICLE_QUERY_VEHICLE_TRIP_DATA } from 'reduxs/actions';

const initialState = { data: [], status: ReducerStatus.REQUEST };

export const VehicleTripList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(VEHICLE_QUERY_VEHICLE_TRIP_DATA):
      return initialState;
    case SUCCESS(VEHICLE_QUERY_VEHICLE_TRIP_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(VEHICLE_QUERY_VEHICLE_TRIP_DATA):
      return { data: [], status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
