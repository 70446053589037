// Components
import Banner from 'components/Banner';

// Sections for this page
import { BannerTypes } from 'components/Banner/enum';
import ReducerHelmet from 'components/ReducerHelmet';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { queryGolfTourList, queryTravelList } from 'reduxs/global-actions';
import EuroTourTravel from './Sections/EuropeTourTravel';
import GolfCourseBooking from './Sections/GolfCourseBooking';
import HotProducts from './Sections/HotProducts';
import OurPartner from './Sections/OurPartner';
import VietNamTourTravel from './Sections/VietNamTourTravel';

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(queryGolfTourList());
    dispatch(queryTravelList());
  }, []);

  return (
    <>
      <ReducerHelmet
        option={META_TAG_OPTION.PAGE}
        page={META_TAG_PAGE.HOME}
        productType={META_TAG_PRODUCT_TYPE.HOME_PAGE}
        id='home_page'
      />
      <Banner bannerType={BannerTypes.SPLIT_IMAGE} />
      <HotProducts />
      <GolfCourseBooking />
      <VietNamTourTravel />
      <EuroTourTravel />
      <OurPartner />
    </>
  );
}
