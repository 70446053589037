import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

// styles
import { cardTitle } from 'assets/jss/global-styles';
import modalStyle from 'assets/jss/material-kit-react/components/bookingModalStyle';
import imagesStyles from 'assets/jss/material-kit-react/cores/imagesStyle';

// material-ui components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { getDayCodeFromDate } from 'utils';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// icons
import DateRangeIcon from '@material-ui/icons/DateRange';
import TimerIcon from '@material-ui/icons/Timer';

// REDUX
import { Divider } from '@material-ui/core';
import { ProductTypeCode } from 'constants/Enums';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useCompleteBookingLogic from './CompleteBooking.logic';
import BookingInformation from './srcComponents/BookingInfo';
import './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
));

const useStyles = makeStyles({
  ...imagesStyles,
  ...modalStyle,
  ...cardTitle,
});

function CompleteBookingModal() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { formik, propState } = useCompleteBookingLogic();

  return (
    <div>
      <Dialog
        fullScreen
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ textAlign: 'center' }}>{t('Complete your Booking')}</DialogTitle>
        <DialogContent id='classic-modal-slide-description' className={classes.modalBody}>
          <GridContainer className='complete-booking-dialog-page'>
            <GridItem md={7} className='left-block'>
              <BookingInformation />
            </GridItem>
            <GridItem md={4} className='right-block'>
              <div>
                <img className={classes.imgCard} src={propState.avatarImg} alt='product img' />
                <div className='CompleteBooking__Right__DetailBody'>
                  <div className='CompleteBooking__Right__BookingDetail'>
                    <div className='CompleteBooking__Right__BookingDetail__DateChoose'>
                      <DateRangeIcon />
                      {new Date(propState.endDate) > new Date(propState.startDate) ? (
                        <div>
                          <p className='bold'>
                            {getDayCodeFromDate(propState.startDate)} -{' '}
                            {getDayCodeFromDate(propState.endDate)}
                          </p>
                          <p>
                            {moment(propState.startDate).format('DD/MM/YYYY')} -{' '}
                            {moment(propState.endDate).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p className='bold'>{getDayCodeFromDate(propState.startDate)}</p>
                          <p>{moment(propState.startDate).format('DD/MM/YYYY')}</p>
                        </div>
                      )}
                    </div>
                    <div className='CompleteBooking__Right__BookingDetail_TimeChoose'>
                      <TimerIcon />
                      <div>
                        <p className='bold'>{propState.product.time || 'NO TIME'}</p>
                        <p>{`${propState.player} ${t('player')} ${
                          propState.nonPlayer ? `& ${propState.nonPlayer} ${t('guest')}` : ''
                        }`}</p>
                      </div>
                    </div>
                  </div>
                  <div className='CompleteBooking__Right__Receipt'>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t('Product type')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            {propState.productTypeCode}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('Player')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            {propState.player}
                          </td>
                        </tr>
                        {propState.productTypeCode === ProductTypeCode.GOLF_PACKAGE &&
                          propState.numOfGuest === 2 && (
                            <tr>
                              <td>{t('Guest')}:</td>
                              <td className='CompleteBooking__Right__Receipt__AlignRight'>
                                {propState.numOfGuest}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td>{t('Quantity')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            {propState.quantity}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('Unit price')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            <p>
                              {propState.unitPrice?.toLocaleString('en') || 0}{' '}
                              {propState.currencyCode || ''}
                            </p>
                          </td>
                        </tr>
                        {formik.values.surcharges.items.length > 0 && (
                          <tr>
                            <td>{t('Surcharge')}:</td>
                            <td className='CompleteBooking__Right__Receipt__AlignRight'>
                              <p>
                                {formik.values.surcharges?.totalSurchargePrice?.toLocaleString(
                                  'en'
                                ) || 0}{' '}
                                {propState.currencyCode}
                              </p>
                            </td>
                          </tr>
                        )}
                        {Object.entries(formik.values.surcharges.items).map(([, val], idx) => (
                          <tr className='CompleteBooking__Right__Receipt__SurchargeItem' key={idx}>
                            <td>{val.label}:</td>
                            <td className='CompleteBooking__Right__Receipt__AlignRight'>
                              <p>
                                {val.price.toLocaleString('en')} {propState.currencyCode}
                              </p>
                            </td>
                          </tr>
                        ))}
                        {formik.values.hotDeal.items.length > 0 && (
                          <tr>
                            <td>{t('Hot Deal')}:</td>
                            <td className='CompleteBooking__Right__Receipt__AlignRight'>
                              <p>
                                {formik.values.hotDeal?.totalHotDealPrice?.toLocaleString('en') ||
                                  0}{' '}
                                {propState.currencyCode}
                              </p>
                            </td>
                          </tr>
                        )}
                        {Object.entries(formik.values.hotDeal.items).map(([, val], idx) => (
                          <tr className='CompleteBooking__Right__Receipt__SurchargeItem' key={idx}>
                            <td>{val.label}:</td>
                            <td className='CompleteBooking__Right__Receipt__AlignRight'>
                              <p>
                                {val.price?.toLocaleString('en') || 0} {propState.currencyCode}
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>{t('Fee (5%)')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            <p>
                              {formik.values.fee?.toLocaleString('en') || 0}{' '}
                              {propState.currencyCode}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>{t('Tax (10%)')}:</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            <p>
                              {formik.values.tax?.toLocaleString('en') || 0}{' '}
                              {propState.currencyCode || ''}
                            </p>
                          </td>
                        </tr>
                        <tr className='CompleteBooking__Right__Receipt__ReceiptLineBlock'>
                          <td colSpan={2}>
                            <Divider aria-label='receiptLine' variant='fullWidth' />
                          </td>
                        </tr>

                        <tr className='CompleteBooking__Right__Receipt__TotalBlock'>
                          <td>Total</td>
                          <td className='CompleteBooking__Right__Receipt__AlignRight'>
                            <p>
                              {formik.values.totalPrice?.toLocaleString('en') || 0}{' '}
                              {propState.currencyCode}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CompleteBookingModal;
