export const META_TAG_OPTION = {
  PAGE: 'page',
  POST: 'post',
};

export const META_TAG_PAGE = {
  HOME: 'home',
  PRIVACY_POLICY: 'privacy_policy',
  PRODUCTS: 'products',
  PRODUCT_DETAIL: 'product_detail',
  LOGIN: 'login',
};

export const META_TAG_PRODUCT_TYPE = {
  HOME_PAGE: 'home_page',
  PRIVACY_POLICY_PAGE: 'privacy_policy_page',
  LOGIN: 'login_page',
  GOLF_COURSE: 'golf_course',
  GOLF_TOUR: 'golf_tour',
  TRAVEL: 'travel',
  BLOG: 'blog',
};
