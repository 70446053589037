import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { PAYMENT_GET_INFO } from 'reduxs/actions';
import { query } from 'utils/api';

function* sagaHandling(request) {
  try {
    const response = yield call(query, apis.getPaymentInfo, request.payload);
    if (request.response) {
      yield put({
        type: request.response.success,
        payload: response.data,
      });
    }
  } catch (error) {
    console.error('Get payment info fail:', error.result);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: null,
      });
    }
  }
}

export default function* watchQueryPaymentInfo() {
  yield takeLatest(REQUEST(PAYMENT_GET_INFO), sagaHandling);
}
