import apis from 'configs/apis';
import ENC from 'crypto-js/enc-utf8';
import RC4 from 'crypto-js/rc4';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { BOOKING_CREATE } from 'reduxs/actions';
import { query } from 'utils/api';

function* doCreateBooking(request) {
  try {
    const response = yield call(query, apis.postBookingCreate, request.payload);
    const ds = ENC.stringify(
      RC4.decrypt(response.data.paymentURL, 'FSDLKJFIWOERU8919283I1I2I3IUUFDLAKKWEROIU12384')
    );
    window.open(ds, '_self');
  } catch (error) {
    console.error('Generate payment link fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: null,
      });
    }
  }
}

export default function* watchBookingCreate() {
  yield takeLatest(REQUEST(BOOKING_CREATE), doCreateBooking);
}
