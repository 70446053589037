// Cores
import { MenuItem, MenuList, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

// Components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// Styles
import useLangHistory from 'hooks/useLangHistory';
import { getLangString, stringToSlug } from 'utils/common';
import { HeaderDropdownContext } from '../..';
import styles from './styles';

const useStyles = makeStyles(styles);

const TabPanel = ({ value, index, tabItemData, href }) => {
  const { lang } = window;
  const classes = useStyles();
  const { push } = useLangHistory();
  const parentContext = useContext(HeaderDropdownContext);
  const { setAnchorEl } = parentContext;

  const menuData = useMemo(() => {
    if (tabItemData.length === 0) return [];

    let newTabItemData = [...tabItemData];
    const usedIds = [];

    newTabItemData = newTabItemData.reduce((acc, cur) => {
      if (usedIds.includes(cur.id)) return acc;

      const { id, name, path, ancestorIds } = cur;
      const param = { id, name, path, childItems: [] };

      usedIds.push(id);

      if (ancestorIds.length > 1) {
        const parentIndex = acc.findIndex(it => ancestorIds.includes(it.id));
        if (parentIndex !== -1) {
          acc[parentIndex].childItems = [...(acc[parentIndex].childItems ?? []), param];
        }
      } else {
        acc.push(param);
      }

      return acc;
    }, []);

    return newTabItemData;
  }, [tabItemData]);

  const onMenuClick = (parent, child) => {
    const uri = [href, stringToSlug(parent?.path?.[lang]), stringToSlug(child?.path?.[lang])]
      .filter(Boolean)
      .join('/');

    push(uri);
    setAnchorEl(null);
  };

  return value !== index ? null : (
    <GridContainer className={classes.tabPanelContainer}>
      {menuData.map(item => (
        <GridItem key={item.id} md={3} className={classes.tabPanelItemContainer}>
          <MenuList>
            <button type='button' className={classes.menuLink} onClick={() => onMenuClick(item)}>
              <Typography variant='h6' className={classes.menuTitle}>
                {getLangString(item.name)}
              </Typography>
            </button>

            {item.childItems.map(childItem => (
              <MenuItem key={childItem.id} className={classes.menuItem}>
                <button
                  type='button'
                  className={classes.menuLink}
                  onClick={() => onMenuClick(item, childItem)}
                >
                  <Typography className={classes.menuLinkText}>
                    {getLangString(childItem.name)}
                  </Typography>
                </button>
              </MenuItem>
            ))}
          </MenuList>
        </GridItem>
      ))}
    </GridContainer>
  );
};

TabPanel.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  tabItemData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ancestorIds: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  href: PropTypes.string,
};

export default TabPanel;
