/* MODULES */
import {
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { memo, useContext } from 'react';

/* COMPONENTS */
import Button from 'components/CustomButtons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

/* CONTEXTS */
import { ProductListPageContext } from 'views/ProductListPage';

/* STYLES */
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

const SearchInput = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state, handlers, productMap } = useContext(ProductListPageContext);

  return (
    <GridContainer>
      <GridItem md={2} className={classes.searchTypeContainer}>
        <Select
          labelId='search-type-select-label'
          id='search-type-simple-select'
          value={state.search.type || productMap?.searchTypes?.[0]?.name}
          onChange={handlers.handleSearchTypeChange}
          variant='outlined'
          className={classes.selectStyle}
        >
          {productMap?.searchTypes?.map(type => (
            <MenuItem key={type.name} value={type.name}>
              {t(type.label)}
            </MenuItem>
          ))}
        </Select>
      </GridItem>

      <GridItem md={8} className={classes.searchTxtContainer}>
        <OutlinedInput
          value={state.search.keyword}
          onChange={handlers.onSearchKeywordChange}
          variant='outlined'
          className={classes.searchTxtField}
          placeholder={`${t('Search')}...`}
          endAdornment={
            state.search.keyword ? (
              <InputAdornment position='end'>
                <IconButton onClick={handlers.onRemoveSearchKeyword} edge='end'>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
      </GridItem>

      <GridItem md={2} className={classes.searchBtnContainer}>
        <Button
          fullWidth
          size='lg'
          color='github'
          className={classes.searchButton}
          onClick={handlers.handleToSearch}
        >
          <SearchIcon />
        </Button>
      </GridItem>
    </GridContainer>
  );
};

export default memo(SearchInput);
