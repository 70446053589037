import { ReducerStatus } from 'constants/Enums';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { BLOG_QUERY_DETAIL_DATA, BLOG_QUERY_LIST_DATA } from 'reduxs/actions';

const initialState = { data: null, status: ReducerStatus.REQUEST };

export const BlogList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(BLOG_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(BLOG_QUERY_LIST_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(BLOG_QUERY_LIST_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};

export const BlogDetail = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(BLOG_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.REQUEST };
    case SUCCESS(BLOG_QUERY_DETAIL_DATA):
      return { data: action.payload, status: ReducerStatus.SUCCESS };
    case FAILURE(BLOG_QUERY_DETAIL_DATA):
      return { data: null, status: ReducerStatus.FAILURE };

    default:
      return state;
  }
};
