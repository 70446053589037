import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { ORDER_GOLF_CART } from 'reduxs/actions';

const queryCartGolf = async payload => {
  const url = `/createCart`;
  // return await apiWorker.create(url, payload);
};

function* doQueryCartGolf(request) {
  try {
    const response = yield call(queryCartGolf, request.payload);
    const data = response.data;
    yield put({
      type: request.response.success,
      payload: data,
    });
  } catch (error) {
    console.error('Query Tee Times data', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryCartGolf() {
  yield takeLatest(REQUEST(ORDER_GOLF_CART), doQueryCartGolf);
}
