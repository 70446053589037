import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { GOLF_QUERY_LIST_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryGolfData(request) {
  try {
    const response = yield call(query, apis.getGolfCourseList, request.payload);
    const { data } = response;
    yield put({
      type: request.response.success,
      payload: data,
    });
  } catch (error) {
    console.error('Query golf data fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryGolfData() {
  yield takeLatest(REQUEST(GOLF_QUERY_LIST_DATA), doQueryGolfData);
}
