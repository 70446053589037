/* MODULE */
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

/* HOOKS */
import useCategories from 'hooks/useCategories';
import useHandlers from 'hooks/useHandlers';
import useMergingState from 'hooks/useMergingState';
import lodash from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import mappingFunction from './mappingFunction';

const useScreenLogic = props => {
  const dispatch = useDispatch();
  const { appProduct } = useParams();
  const { getCurrentCategory } = useCategories();
  const productMap = appProduct ? mappingFunction[appProduct.toLowerCase()] : {};
  const productList = useSelector(state => state[productMap.dataKey]);

  const propsRef = useRef({
    ...props,
    ...productMap.initializeState,
  });

  propsRef.current = { ...propsRef.current, ...props };
  const [state, setState] = useMergingState(productMap.initializeState, propsRef);
  const filterDebounce = lodash.debounce(resetData => {
    const { search, filters } = resetData || propsRef.current;
    const allFilter = Object.keys(filters).reduce((acc, key) => {
      const filterValue = filters[key];
      const { customQueryParams } = productMap.filters[key] || {};

      if (customQueryParams != null) {
        return { ...acc, ...customQueryParams(filterValue) };
      }

      if (Array.isArray(filterValue)) {
        if (filterValue.length) acc[key] = filterValue.join(' ');
      } else if (filterValue != null) {
        acc[key] = filterValue;
      }

      return acc;
    }, {});

    if (search.keyword) {
      allFilter[search.type] = search.keyword;
    }

    dispatch(productMap.actions.queryData(allFilter));
  }, 2000);

  const handlers = useHandlers({
    handleSearchTypeChange: e => {
      setState({
        search: {
          ...propsRef.current.search,
          type: e.target.value,
        },
      });
    },

    onSearchKeywordChange: e => {
      setState({
        search: {
          ...propsRef.current.search,
          keyword: e.target.value,
        },
      });
    },

    onRemoveSearchKeyword: () => {
      setState({
        search: {
          ...propsRef.current.search,
          keyword: '',
        },
      });
      filterDebounce();
    },

    handleToSearch: () => filterDebounce(),

    onFilterChange: (filterKey, value) => {
      const newFilters = { ...propsRef.current.filters };

      if (Array.isArray(filterKey)) {
        filterKey.forEach((key, index) => {
          const dynamicValue = Array.isArray(value) ? value[index] : value;
          if (dynamicValue) newFilters[key] = dynamicValue;
        });
      } else {
        newFilters[filterKey] = value;
      }
      setState({ filters: newFilters });
      filterDebounce();
    },
  });

  useEffect(() => {
    const resetData = { ...productMap.initializeState };
    if (getCurrentCategory()?.id) {
      resetData.filters.category = [getCurrentCategory().id];
    }
    setState(resetData);
    filterDebounce(resetData);
  }, [window.location.pathname, appProduct]);

  return {
    state,
    handlers,
    appProduct: appProduct.toLowerCase(),
    productMap,
    productList,
    currentCategory: getCurrentCategory(),
  };
};

useScreenLogic.propTypes = {
  searchTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  filterTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default useScreenLogic;
