import { Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import 'react-multi-carousel/lib/styles.css';

// Components
import ImageCardCluster from 'components/ImageCardCluster';
import ProductFlexCard from 'components/ProductFlexCard';

// Utils
import { getLangString } from 'utils/common';

// Redux
import useCategories from 'hooks/useCategories';
import useLangHistory from 'hooks/useLangHistory';
import { useSelector } from 'react-redux';
import styles from './styles';

const useStyle = makeStyles(styles);

const HotProducts = () => {
  const classes = useStyle();
  const { push } = useLangHistory();
  const { getParentPath, getChildCategoryIds } = useCategories();
  const { lang } = window;
  const golfTourList = useSelector(state => state.golfTourList);
  const travelList = useSelector(state => state.travelList);
  const domesticIds = getChildCategoryIds(1);

  const data = useMemo(
    () => [
      golfTourList.status === 'SUCCESS' &&
        golfTourList.data
          ?.filter(it => domesticIds.includes(it.categoryId))
          ?.map(product => (
            <ProductFlexCard
              key={product.name}
              avatarImgUrl={product.avatarImageUrl}
              contentChildren={
                <>
                  <Typography variant='subtitle1' className={classes.productTitle}>
                    {getLangString(product.name, lang)}
                  </Typography>
                  <Typography variant='subtitle2' className={classes.productContent}>
                    {`${product.numOfDay} days ${product.numOfNight} nights`}
                  </Typography>
                  <Typography variant='subtitle2' className={classes.productContent}>
                    {`Available: ${product.golfTourDeparture[0].availableSeats}`}
                  </Typography>
                  <Typography variant='subtitle2' className={classes.productContent}>
                    {`Transport: ${getLangString(product.transport)}`}
                  </Typography>
                </>
              }
              regularPrice={product?.golfTourDeparture[0]?.price}
              buttonProps={{
                onClick: () => {
                  push(
                    `product-detail/golf-tour/${getParentPath(
                      product.categoryId,
                      product.customPath
                    )}`
                  );
                },
              }}
            />
          )),
      travelList.status === 'SUCCESS' &&
        travelList.data
          ?.filter(it => domesticIds.includes(it.categoryId))
          ?.map(product => (
            <ProductFlexCard
              key={product.name}
              avatarImgUrl={product.avatarImageUrl}
              contentChildren={
                <Typography variant='subtitle1' className={classes.productTitle}>
                  {getLangString(product.name, lang)}
                </Typography>
              }
              regularPrice={product.regularPrice.toLocaleString('en')}
              salePrice={product.salePrice.toLocaleString('en')}
              buttonProps={{
                onClick: () => {
                  push(
                    `product-detail/travel/${getParentPath(product.categoryId, product.customPath)}`
                  );
                },
              }}
            />
          )),
    ],
    [golfTourList, travelList, domesticIds]
  );

  return (
    <main id='hot-products'>
      <ImageCardCluster
        title='Hot Products'
        background='hotproduct-background.jpg'
        buttonProps={{
          id: 'btn-hot-product-more-products',
          onClick: () => {
            push('products/golf-course');
          },
        }}
        productCard={data}
      />
    </main>
  );
};

export default HotProducts;
