import { APP_PRODUCT } from 'constants/Enums';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from 'reduxs/store';
import { CACHING_PARAMS } from 'utils/DOM';

const getRoutePath = pathname => (pathname.includes('routes.') ? pathname : `routes.${pathname}`);

const translatePath = pathname => {
  const { t } = i18next;
  if (pathname === '/') return `/${window.lang}`;
  let customPath = pathname.charAt(0) === '/' ? pathname.slice(1) : pathname;
  if (pathname.includes('/')) {
    customPath = customPath
      .split('/')
      .map(path => {
        const translatePathname = t(getRoutePath(path));
        return translatePathname !== getRoutePath(path) ? translatePathname : path;
      })
      .join('/');
  } else {
    const translatePathname = t(getRoutePath(customPath));
    customPath = translatePathname !== getRoutePath(customPath) ? translatePathname : customPath;
  }
  return `/${t(customPath)}`;
};

const getDetailProduct = product => {
  const lang = window.lang.toUpperCase();
  if (product === APP_PRODUCT.GOLF_COURSE[lang]) {
    return store.getState().golfCourseDetail.data;
  }

  if (product === APP_PRODUCT.GOLF_TOUR[lang]) {
    return store.getState().golfTourDetail.data;
  }

  if (product === APP_PRODUCT.BLOG[lang]) {
    return store.getState().blogDetail.data;
  }

  if (product === APP_PRODUCT.TRAVEL[lang]) {
    return store.getState().travelDetail.data;
  }

  return null;
};

const useLangHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const categories = useSelector(state => state.categories.data);

  return {
    isMatchedPath: pathname => history.location.pathname === translatePath(pathname),

    getLangPath(pathname) {
      return translatePath(pathname);
    },

    push(pathname, state) {
      const isExternalLink = pathname.includes('http') || pathname.includes('https');

      if (isExternalLink) {
        window.open(pathname, '_blank');
        return;
      }

      history.push(translatePath(pathname), state);
    },

    changeLanguagePath(newLang) {
      try {
        const currentLang = window.lang;
        if (currentLang === newLang) return;
        const { origin, pathname } = window.location;
        const separationPathname = pathname.split('/').slice(2, 4);

        if (!categories?.length) {
          window.open(`${origin}/${newLang}`, '_self');
          return;
        }

        const url = new URL(window.location.href);
        url.pathname = newLang;

        if (separationPathname.length === 0) {
          window.open(url.toString(), '_self');
          return;
        }

        separationPathname.forEach(path => {
          url.pathname += '/' + t('routes.' + decodeURIComponent(path), { lng: newLang });
        });

        const currentParams = JSON.parse(sessionStorage.getItem(CACHING_PARAMS));

        if (currentParams.region) {
          const region = decodeURIComponent(currentParams.region);
          const newRegion = categories.find(cat => cat.path[currentLang] === region).path[newLang];
          url.pathname += '/' + newRegion;
        }

        if (currentParams.country) {
          const country = decodeURIComponent(currentParams.country);
          const newCountry = categories.find(cat => cat.path[currentLang] === country).path[
            newLang
          ];
          url.pathname += '/' + newCountry;
        }

        if (currentParams.city) {
          const city = decodeURIComponent(currentParams.city);
          const newCity = categories.find(cat => cat.path[currentLang] === city).path[newLang];
          url.pathname += '/' + newCity;
        }

        if (currentParams.productPath) {
          const currentProduct = decodeURIComponent(separationPathname[1]);
          const product = getDetailProduct(currentProduct);
          const newProductPath = product?.customPath?.[newLang];
          url.pathname += '/' + newProductPath;
        }

        window.open(url.toString(), '_self');
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('error', error);
        }
      }
    },
  };
};

export default useLangHistory;
