import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { BLOG_QUERY_DETAIL_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryBlogDetailData(request) {
  try {
    const response = yield call(query, apis.getBlogDetail, request.payload);
    const { data } = response;

    yield put({
      type: request.response.success,
      payload: data,
    });
    request.callback?.handleSuccess?.({ data });
  } catch (error) {
    request.callback?.handleFail?.();
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryBlogDetailData() {
  yield takeLatest(REQUEST(BLOG_QUERY_DETAIL_DATA), doQueryBlogDetailData);
}
