import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react';
// Import Swiper React components
// import required modules
import { useTranslation } from 'react-i18next';
import 'swiper/swiper.scss';
import BannerItem from './Item';
import './styles.scss';

const GOLF_SLIDE = {
  title: 'Golf Courses',
  slides: [
    'https://images.unsplash.com/photo-1611374243147-44a702c2d44c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGdvbGZ8ZW58MHx8MHx8&w=1000&q=80',
    'https://static.vinwonders.com/2022/05/san-golf-phu-quoc-thump.jpeg',
    'https://images.unsplash.com/photo-1629673120178-53a664eec9e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
  ],
};

const AIRLINE_SLIDE = {
  title: 'Airlines',
  slides: [
    'https://images.unsplash.com/photo-1607857854125-dda25b1c31dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWVyb2Zsb3R8ZW58MHx8MHx8&w=1000&q=80',
    'https://www.inspiringtravel.co.uk/other-shores/wp-content/uploads/2021/10/A332-ONLY-CGI_Upper2.jpg',
    'https://www.airlineratings.com/wp-content/uploads/uploads/VAcabin1.jpg',
  ],
};

const HOTEL_SLIDE = {
  title: 'Hotel',
  slides: [
    'https://media.istockphoto.com/id/104731717/photo/luxury-resort.jpg?s=612x612&w=0&k=20&c=cODMSPbYyrn1FHake1xYz9M8r15iOfGz9Aosy9Db7mI=',
    'https://www.redrockresort.com/wp-content/uploads/2020/04/RR-King-Bedroom.jpg',
    'https://motogo.vn/wp-content/uploads/2019/07/Hungryfish-house-da-lat-4.jpg',
  ],
};

const FullScreenVideo = () => {
  const { t } = useTranslation();

  return (
    <div className='FullScreenVideoBanner'>
      <GridContainer className='FullScreenVideoBanner__VideoContainer'>
        <video className='FullScreenVideoBanner__VideoContainer__Video' autoPlay loop muted>
          <source src={window.location.origin + '/video/banner.mp4'} type='video/mp4' />
          <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
        </video>
      </GridContainer>
      <GridContainer className='FullScreenVideoBanner__ContentContainer'>
        <GridItem sm={5} className='FullScreenVideoBanner__ContentContainer__ContentLeft'>
          <h1 className='m-0 FullScreenVideoBanner__ContentContainer__ContentLeft__TextWhite'>
            {t('Booking golf course through the world')}
          </h1>
          <h5 className='m-0 FullScreenVideoBanner__ContentContainer__ContentLeft__TextWhite'>
            {t('Banner description')}
          </h5>
        </GridItem>
        <GridItem sm={7} className='FullScreenVideoBanner__ContentContainer__ContentRight'>
          <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer-1'>
            <BannerItem slider={GOLF_SLIDE} />
          </div>
          <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer-2'>
            <BannerItem slider={AIRLINE_SLIDE} />
          </div>
          <div className='FullScreenVideoBanner__ContentContainer__ContentRight__SliderContainer-3'>
            <BannerItem slider={HOTEL_SLIDE} />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default FullScreenVideo;
