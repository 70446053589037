import PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const SplitImageCarousel = ({ sliders }) => (
  <div className='SplitImageBanner__ImageContainer Border AutoResize'>
    <Carousel
      responsive={responsive}
      ssr
      infinite
      keyBoardControl
      renderButtonGroupOutside
      arrows
      autoPlay
      autoPlaySpeed={5000}
      pauseOnHover
      containerClass='SplitImageBanner__ImageContainer__Carousel'
      sliderClass='SplitImageBanner__ImageContainer__Carousel__Slider'
      itemClass='SplitImageBanner__ImageContainer__Carousel__Slider__Item'
    >
      {sliders.map(slider => (
        <img
          key={slider.imgUrl}
          alt='banner-carousel-item'
          src={window.location.origin + slider.imgUrl}
        />
      ))}
    </Carousel>
  </div>
);

SplitImageCarousel.propTypes = {
  sliders: PropTypes.arrayOf(
    PropTypes.shape({
      imgUrl: PropTypes.string,
    })
  ),
};

export default SplitImageCarousel;
