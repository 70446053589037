import { ReducerStatus } from 'constants/Enums';
import { useSelector } from 'react-redux';

const useCategories = () => {
  const { lang } = window;
  const categories = useSelector(state => state.categories);

  return {
    getParentPath: (categoryId, customPath) => {
      if (!customPath) return '/';
      if (!categoryId) {
        return customPath[lang];
      }

      const category = categories.data?.find(it => it.id === categoryId);
      const parentPath = category?.ancestorIds
        .map(it => {
          const parent = categories.data.find(cat => cat.id === it);
          return parent?.path?.[lang] || '';
        })
        .join('/');

      if (!parentPath) return '/';

      return `${parentPath}/${category.path[lang]}/${customPath[lang]}`;
    },

    getChildCategoryIds: categoryId => {
      if (!categoryId || !categories.data) return [];
      return [
        categoryId,
        ...categories.data.filter(it => it.ancestorIds.includes(categoryId)).map(it => it.id),
      ];
    },

    getCurrentCategory: () => {
      if (categories.status !== ReducerStatus.SUCCESS || !categories.data) return null;
      if (window.location.pathname === '/') return null;
      const path = window.location.pathname.split('/').slice(-1)[0];
      return categories.data.find(it => it.path[lang] === decodeURIComponent(path));
    },
  };
};

export default useCategories;
