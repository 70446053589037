export default {
  searchTypeContainer: {},
  selectStyle: {
    width: '100%',
    textAlign: 'center',
  },

  searchTxtContainer: {
    margin: '1rem 0',
    '@media (min-width: 960px)': {
      margin: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  searchTxtField: {
    width: '100%',
  },
  searchButton: {
    marginTop: 0,
  },
};
