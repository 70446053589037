import { call, put, takeLatest } from 'redux-saga/effects';
import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { query } from './api';

export function generateAlertAction(type) {
  return payload => ({
    type,
    payload,
  });
}

export function generateAction(type) {
  return (payload, callback) => ({
    type: REQUEST(type),
    response: {
      success: SUCCESS(type),
      failed: FAILURE(type),
    },
    payload,
    callback,
  });
}

export function createNormalApiQuerySaga({ api, actionKey, callbackSuccess, callbackFailure }) {
  function queryApi(params) {
    return query(api, params);
  }
  function* handlingSaga(request) {
    const { payload: queryParams, callback } = request;
    try {
      const response = yield call(queryApi, queryParams);

      if (callbackSuccess) {
        yield callbackSuccess(request, response);
      } else if (request.response) {
        yield put({
          type: request.response.success,
          payload: response.data,
        });
      }

      callback?.handleSuccess?.(response);

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('Response from saga ' + actionKey, response.data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error from saga ' + actionKey, error);
      }
      if (callbackFailure) {
        yield callbackFailure(request);
      } else if (request.response) {
        // eslint-disable-next-line no-console
        console.log('Error from saga ' + actionKey, error);
        yield put({
          type: request.response.failed,
          payload: error.code || error.message,
        });
      }
      callback?.handleFail?.();
    }
  }

  return function* watchSaga() {
    yield takeLatest(REQUEST(actionKey), handlingSaga);
  };
}
