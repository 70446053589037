import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { TEE_TIMES_QUERY_LIST_DATA } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQueryTeeTimeData(request) {
  try {
    const response = yield call(query, apis.getTeetimeList, request.payload);
    const { data } = response;
    request.callback?.handleSuccess?.({ data });
    yield put({
      type: request.response.success,
      payload: data,
    });
  } catch (error) {
    console.error('Query teetime fail', error);
    if (request.response) {
      yield put({
        type: request.response.failed,
        payload: error.code || error.message,
      });
    }
  }
}

export default function* watchQueryTeeTimesData() {
  yield takeLatest(REQUEST(TEE_TIMES_QUERY_LIST_DATA), doQueryTeeTimeData);
}
