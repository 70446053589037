import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import RegularButton from 'components/CustomButtons';
import { ReducerStatus } from 'constants/Enums';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DatePicker from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TeetimeTable = ({ status, teetimes, selectedId, onItemClick }) => {
  if (status === ReducerStatus.LOADING || status === ReducerStatus.FAILURE || teetimes == null) {
    return [1, 2, 3, 4].map(el => (
      <div key={el} className='skeleton-item'>
        <Skeleton variant='rect' width='100%' height={40} />
      </div>
    ));
  }

  return teetimes.length === 0 ? (
    <div className='not-found-item'>
      <Typography variant='body1'>No tee time available</Typography>
    </div>
  ) : (
    teetimes.map((teetime, idx) => (
      <RegularButton
        key={idx}
        className='content-item'
        color={selectedId === teetime.uuid ? 'success' : 'transparent'}
        onClick={() => onItemClick(teetime)}
      >
        <div className='table-block'>
          <Typography variant='subtitle1'>{teetime.time}</Typography>
        </div>
        <div className='table-block'>
          <Typography variant='subtitle1'>{teetime.price.toLocaleString('en')}</Typography>
        </div>
      </RegularButton>
    ))
  );
};

TeetimeTable.propTypes = {
  status: PropTypes.string,
  teetimes: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      time: PropTypes.string,
      price: PropTypes.number,
    })
  ),
  selectedId: PropTypes.string,
  onItemClick: PropTypes.func,
};

const players = [1, 2, 3, 4];
const BookingCard = ({
  bookingData,
  onDateChange,
  onPlayerChange,
  onChooseTeeTimeClick,
  onBooking,
}) => {
  const { t } = useTranslation();
  const teeTimeList = useSelector(state => state.teetimeList);
  // const teetimeNormalList = teeTimeList.data?.teetimes?.filter(el => !el.hotDeal);
  // const teetimeWithHotDealList = teeTimeList.data?.teetimes?.filter(el => el.hotDeal);

  const playerPickerButton = useMemo(
    () => (
      <div>
        {players.map(player => (
          <RegularButton
            key={player}
            size='sm'
            onClick={() => onPlayerChange(player)}
            className={`player1${player.toString()}`}
            justIcon
            round
            color={
              bookingData.numOfGuest === player || bookingData.numOfGuest > player
                ? 'success'
                : 'transparent'
            }
          >
            <AccessibilityNewIcon
              htmlColor={classNames({
                white: bookingData.numOfGuest === player || bookingData.numOfGuest > player,
                '#4caf50': bookingData.numOfGuest !== player || bookingData.numOfGuest <= player,
              })}
            />
          </RegularButton>
        ))}
      </div>
    ),
    [bookingData.numOfGuest]
  );

  const onSubmit = e => {
    e.preventDefault();
    onBooking();
  };

  return (
    <Card className='tee-time-booking-card'>
      <form onSubmit={onSubmit}>
        <CardContent>
          <Typography variant='h6' align='center' className='card-title'>
            {t('Select Date, Player & Tee Time')}
          </Typography>
          <div className='booking-option'>
            <div className='booking-option__date-picker'>
              <Typography variant='subtitle2'>{t('Select Date')}</Typography>
              <DatePicker
                onChange={onDateChange}
                inputProps={{
                  placeholder: 'Choose your date',
                  className: 'form-control text-center custom-date-picker',
                }}
                closeOnSelect
                closeOnClickOutside
                timeFormat={false}
                value={moment(bookingData.startDate)}
                dateFormat='MMMM DD YYYY'
              />
            </div>
            <div className='booking-option__player-picker'>
              <Typography variant='subtitle2'>{t('Select Player')}</Typography>
              {playerPickerButton}
            </div>
          </div>

          <div className='tee-time-table'>
            <div className='table-header'>
              <div className='table-block'>
                <Typography variant='subtitle2'>{t('Tee Time')}</Typography>
              </div>
              <div className='table-block'>
                <Typography variant='subtitle2'>{t('Price')}</Typography>
              </div>
            </div>
            <div className='table-content'>
              <TeetimeTable
                status={teeTimeList.status}
                teetimes={teeTimeList.data?.teetimes}
                selectedId={bookingData.product?.uuid}
                onItemClick={onChooseTeeTimeClick}
              />
            </div>
          </div>
        </CardContent>

        <CardActions className='card-actions'>
          <RegularButton
            id='btn-golf-course-detail-book-now'
            type='submit'
            color='success'
            disabled={bookingData.product == null}
          >
            {t('Book Now')}
          </RegularButton>
        </CardActions>
      </form>
    </Card>
  );
};

BookingCard.propTypes = {
  bookingData: PropTypes.shape({
    productTypeCode: PropTypes.string,
    product: PropTypes.object,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    numOfGuest: PropTypes.number,
    player: PropTypes.number,
    nonPlayer: PropTypes.number,
    quantity: PropTypes.number,
    unitPrice: PropTypes.number,
    currencyCode: PropTypes.string,
    hotDealOptions: PropTypes.object,
    avatarImg: PropTypes.string,
  }),
  onDateChange: PropTypes.func,
  onPlayerChange: PropTypes.func,
  onChooseTeeTimeClick: PropTypes.func,
  onBooking: PropTypes.func,
};

export default BookingCard;
