import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// import 'assets/css/material-kit-react.css';
// import 'assets/scss/global-styles.scss?v=1.9.0';
import 'assets/css/material-kit-react.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import smoothScroll from 'smoothscroll-polyfill';
import { getBaseLang } from 'utils/common';
import App from './app';
import store, { persistor } from './reduxs/store';

smoothScroll.polyfill();

window.addEventListener('load', () => {
  caches
    .keys()
    .then(cacheNames => Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))))
    .then(() => {
      console.log('All caches are deleted');
    })
    .catch(error => {
      console.error('Error deleting caches:', error);
    });
});

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter history={createBrowserHistory()} basename={getBaseLang() || '/vi'}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);
