import { Slider, Typography, makeStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

const FilterSliderPicker = ({
  label,
  value,
  valueLabelFormat,
  valueLabelDisplay,
  onChange,
  max,
  min,
  step,
  onChangeCommitted,
  getValueFormat,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <GridContainer className={classes.container}>
        <Typography>{t(label)}</Typography>
        <Typography variant='caption'>{getValueFormat?.(value)}</Typography>
      </GridContainer>
      <Slider
        value={value}
        valueLabelFormat={valueLabelFormat}
        onChange={onChange}
        valueLabelDisplay={valueLabelDisplay}
        min={min}
        max={max}
        step={step}
        onChangeCommitted={onChangeCommitted}
      />
    </div>
  );
};

FilterSliderPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  valueLabelFormat: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  onChangeCommitted: PropTypes.func,
  valueLabelDisplay: PropTypes.oneOf(['on', 'off', 'auto']),
  getValueFormat: PropTypes.func,
};

export default FilterSliderPicker;
