import { container } from 'assets/jss/global-styles';

const Styles = {
  container,
  main: {
    background: '#FFFFFF',
  },
  title: {
    fontSize: '3.75rem',
    lineHeight: 1.167,
    fontWeight: 'bold',
  },
};

export default Styles;
