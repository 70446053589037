/**
 * Appends REQUEST async action type
 */

export const REQUEST = actionType => `${actionType}_PENDING`;

/**
 * Appends SUCCESS async action type
 */

export const SUCCESS = actionType => `${actionType}_SUCCESS`;

/**
 * Appends FAILURE async action type
 */

export const FAILURE = actionType => `${actionType}_FAILURE`;
