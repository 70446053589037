export const partners = [
  {
    name: 'INTERCONTINENTIAL DANANG',
    logo: 'img/Partners/intercontinential-danang.png',
  },
  {
    name: 'EASTIN GRAND SAIGON',
    logo: 'img/Partners/eastin-grand-saigon.jpg',
  },
  {
    name: 'PENINSULA DA NANG',
    logo: 'img/Partners/peninsula-danang.png',
  },
  {
    name: 'MUONG THANH LUXURY HOTEL',
    logo: 'img/Partners/muong-thanh-luxury-hotel.png',
  },
  {
    name: 'Hoi An Rosemary Boutique Hotel & Spa',
    logo: 'img/Partners/hoi-an-hotel-spa.png',
  },
  {
    name: 'New World Hoiana',
    logo: 'img/Partners/hoiana-resort-golf.png',
  },
];

export const paymentMethod = [
  {
    name: 'Partner name',
    logo: 'img/Payment/visa.jpg',
  },
  {
    name: 'Partner name',
    logo: 'img/Payment/master.jpg',
  },
  {
    name: 'Partner name',
    logo: 'img/Payment/americanExpress.jpg',
  },
  {
    name: 'Partner name',
    logo: 'img/Payment/jcb.jpg',
  },
  {
    name: 'Partner name',
    logo: 'img/Payment/paypal.png',
  },
];
