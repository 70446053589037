const headerDropdownDesktopStyles = {
  drawer: {
    // zIndex: '1000 !important',
  },
  paper: {
    // paddingTop: '4.5rem',
  },
  boxHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  boxContent: {
    display: 'flex',
    width: '100%',
    borderTop: '1px solid #e0e0e0',
    paddingTop: '0.5rem',
  },
  closeIcon: {
    width: '1.5rem !important',
    height: '1.5rem !important',
  },

  tabPanelContainer: {
    width: '100%',
  },
  tabPanelItemContainer: {
    paddingRight: 0,
  },
  menuItem: {
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuLink: {
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    width: 'fit-content',
  },

  menuTitle: {
    fontWeight: 600,
    fontSize: '0.9rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'left',
    width: 'fit-content',
    '&:hover': {
      color: '#00acc1',
      textShadow: '0 0 1px #00acc1',
      textDecoration: 'underline',
    },
  },

  menuLinkText: {
    color: '#333',
    textTransform: 'capitalize',
    fontSize: '0.8rem',
    textAlign: 'left',
    width: 'fit-content',
    '&:hover': {
      color: '#00acc1',
      textShadow: '0 0 1px #00acc1',
      textDecoration: 'underline',
    },
  },
};

export default headerDropdownDesktopStyles;
