import { Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import MainFrame from 'components/MainFrame';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { partners, paymentMethod } from './OurPartnerList';
import './style.scss';

const OurPartner = () => {
  const { t } = useTranslation();
  return (
    <main id='our-partner'>
      <div className='OurPartner'>
        <MainFrame className='OurPartner__Container'>
          <Typography variant='h4' className='OurPartner__Container__Title'>
            {t('Our Partner')}
          </Typography>

          <GridContainer className='OurPartner__Container__LogoContainer'>
            {partners.map((partner, idx) => (
              <div key={idx} className='OurPartner__Container__LogoContainer__GridItemLogo'>
                <Typography
                  variant='subtitle1'
                  className='OurPartner__Container__LogoContainer__GridItemLogo__Container__Title'
                >
                  {t(partner.name)}
                </Typography>

                <div className='OurPartner__Container__LogoContainer__GridItemLogo__Container__LogoBlock'>
                  <img
                    src={`${window.location.origin}/${partner.logo}`}
                    alt={'partner-logo-' + partner.name.toLowerCase()}
                    className='OurPartner__Container__LogoContainer__GridItemLogo__Container__LogoBlock__Logo'
                  />
                </div>
              </div>
            ))}
          </GridContainer>

          <div className='OurPartner__Container__PaymentMethodContainer'>
            <Typography
              variant='h4'
              className='OurPartner__Container__PaymentMethodContainer__Title'
            >
              {t('Payment Method')}
            </Typography>
            <GridContainer className='OurPartner__Container__PaymentMethodContainer__LogoContainer'>
              {paymentMethod.map((partner, idx) => (
                <div
                  key={idx}
                  className='OurPartner__Container__PaymentMethodContainer__LogoContainer__GridItemLogo'
                >
                  <img
                    src={`${window.location.origin}/${partner.logo}`}
                    alt={'partner-logo-' + partner.name.toLowerCase()}
                    className='OurPartner__Container__PaymentMethodContainer__LogoContainer__GridItemLogo__Logo'
                  />
                </div>
              ))}
            </GridContainer>
          </div>
        </MainFrame>
      </div>
    </main>
  );
};

export default OurPartner;
