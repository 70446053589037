import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import 'react-multi-carousel/lib/styles.css';

// Components
import ImageCardCluster from 'components/ImageCardCluster';
import ProductFlexCard from 'components/ProductFlexCard';

// Utils
import { getActionId, getLangString } from 'utils/common';

// Redux
import useCategories from 'hooks/useCategories';
import useLangHistory from 'hooks/useLangHistory';
import { useSelector } from 'react-redux';
import styles from './styles';

const useStyle = makeStyles(styles);

const VietNamTourTravel = () => {
  const classes = useStyle();
  const { getParentPath } = useCategories();
  const { push } = useLangHistory();
  const { lang } = window;
  const travelList = useSelector(state => state.travelList);

  return (
    <main id='viet-nam-tour-travel'>
      <ImageCardCluster
        title='Thailand Tour Travel'
        background='thailand-tour-background.jpg'
        buttonProps={{
          id: 'btn-home-vietnam-tour-more-products',
          onClick: () => {
            push('products/travel');
          },
        }}
        productCard={travelList.data
          ?.filter(it => it.categoryId === 7)
          ?.map(travel => (
            <ProductFlexCard
              key={travel.name}
              avatarImgUrl={travel.avatarImageUrl}
              contentChildren={
                <Typography variant='subtitle1' className={classes.productTitle}>
                  {getLangString(travel.name, lang)}
                </Typography>
              }
              regularPrice={travel.regularPrice}
              salePrice={travel.salePrice}
              buttonProps={{
                id: getActionId('btn', 'vietnam-tour-book-now', getLangString(travel.name, lang)),
                onClick: () => {
                  push(
                    `product-detail/travel/${getParentPath(travel.categoryId, travel.customPath)}`
                  );
                },
              }}
            />
          ))}
      />
    </main>
  );
};

export default VietNamTourTravel;
