import apis from 'configs/apis';
import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST } from 'reduxs/action-type-utils';
import { AUTHENTICATION_QUERY_SIGN_IN } from 'reduxs/actions';
import { query } from 'utils/api';

function* doQuerySignIn(request) {
  try {
    const response = yield call(query, apis.postSignIn, request.payload);
    const { data } = response;

    // if (data != null && !data.errors && Object.entries(data).length > 0) {
    //   yield put({
    //     type: request.response.success,
    //     payload: data,
    //   });
    //   request.callback?.handleSuccess?.();
    // } else if (data.errors?.length) {
    //   yield put({
    //     type: request.response.failed,
    //   });
    //   request.callback?.handleFailed?.({ message: data.errors?.code });
    // }

    const mapActions = {
      onSuccess: {
        putParams: {
          type: request.response.success,
          payload: data,
        },
        callbackAction: request.callback?.handleSuccess,
      },
      onFailed: {
        putParams: {
          type: request.response.failed,
        },
        callbackAction: request.callback?.handleFailed,
      },
    };

    if (data && !data.errors && Object.entries(data).length > 0) {
      yield put(mapActions.onSuccess.putParams);
      mapActions.onSuccess.callbackAction?.();
    } else if (data.errors?.length) {
      yield put(mapActions.onFailed.putParams);
      mapActions.onFailed.callbackAction?.({
        message: data.errors
          .reduce((prev, curr) => {
            if (!prev.includes(curr.code)) {
              prev.push(curr.code);
            }
            return prev;
          }, [])
          .join(', '),
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Query sign in failed', error.message);
    }

    yield put({ type: request.response.failed });

    request.callback?.handleFailed?.({ message: 'INTERNAL_SERVER_ERROR' });
  }
}

export default function* watchQuerySignIn() {
  yield takeLatest(REQUEST(AUTHENTICATION_QUERY_SIGN_IN), doQuerySignIn);
}
