import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const FilterCheckbox = ({ label, options, onChange, defaultExpanded }) => {
  const { t } = useTranslation();
  const classes = makeStyles(styles)();

  return (
    <Accordion
      defaultExpanded={defaultExpanded ?? true}
      className={classes.filterCheckbox__container}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={classes.filterCheckbox__summary}
      >
        <Typography variant='body2'>{t(label)}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.filterCheckbox__detail}>
        <FormGroup>
          {options?.map(category => (
            <FormControlLabel
              key={category.value}
              checked={category.checked}
              control={
                <Checkbox
                  className={classes.filterCheckbox__detail__formControlLabel__checkbox}
                  size='small'
                  color='default'
                />
              }
              label={capitalize(
                t(category.label, {
                  value:
                    typeof category.value === 'string' || typeof category.value === 'number'
                      ? category.value
                      : undefined,
                })
              )}
              onChange={onChange}
              value={category.value}
              className={classes.filterCheckbox__detail__formControlLabel}
            />
          ))}
        </FormGroup>
      </AccordionDetails>
      <Divider />
    </Accordion>
  );
};

FilterCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      // Value is number or string
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultExpanded: PropTypes.bool,
};

export default memo(FilterCheckbox);
