import { Typography } from '@material-ui/core';
import { queryGolfList } from 'reduxs/global-actions';
import { isWeekend } from 'utils/common';
import { FILTER_INPUT_TYPE } from '../enum';

export default {
  initializeState: {
    search: {
      keyword: '',
      type: 'name',
    },
    filters: {
      teeDate: new Date().getTime(),
      region: null,
      country: null,
      state: null,
      city: null,
      time: [5, 21],
      priceRange: [0, 20000000],
      numOfPlayers: null,
      numOfHoles: null,
      categories: [],
    },
  },
  searchTypes: [
    {
      label: 'Name',
      name: 'name',
    },
  ],
  filters: {
    teeDate: {
      inputType: FILTER_INPUT_TYPE.DATE_PICKER,
      label: 'Tee Date',
    },
    location: {
      inputType: FILTER_INPUT_TYPE.LOCATION_PICKER,
      label: 'Location',
    },
    time: {
      inputType: FILTER_INPUT_TYPE.SLIDER,
      label: 'Time',
      range: {
        min: 5,
        max: 21,
        step: 1,
      },
      customQueryParams: value => ({ fromTime: value[0], toTime: value[1] }),
      valueLabelFormat: value => `${value}:00`,
      getValueFormat: value => `${value?.[0]}:00 - ${value?.[1]}:00`,
    },
    priceRange: {
      inputType: FILTER_INPUT_TYPE.SLIDER,
      label: 'Price Range',
      range: {
        min: 0,
        max: 20000000,
        step: 100000,
      },
      customQueryParams: value => ({ fromPrice: value[0], toPrice: value[1] }),
      valueLabelFormat: value => `${value.toLocaleString('en')} VND`,
      getValueFormat: value =>
        `${value?.[0]?.toLocaleString('en')} VND - ${value?.[1]?.toLocaleString('en')} VND`,
    },
    numOfPlayers: {
      inputType: FILTER_INPUT_TYPE.CHECKBOX,
      label: 'Number of Players',
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ],
    },
    numOfHoles: {
      inputType: FILTER_INPUT_TYPE.CHECKBOX,
      label: 'Number of Holes',
      options: [
        { label: '9', value: 9 },
        { label: '18', value: 18 },
        { label: '27', value: 27 },
        { label: '36', value: 36 },
        { label: '54', value: 54 },
      ],
    },
    categories: {
      inputType: FILTER_INPUT_TYPE.CHECKBOX,
      label: 'Categories',
      options: [
        // { label: 'online payment', value: 'online_payment' },
        { label: 'driving range', value: 'driving_range' },
        { label: 'putting green', value: 'putting_green' },
        { label: 'chipping green', value: 'chipping_green' },
        { label: 'practice bunker', value: 'practice_bunker' },
        { label: 'motor cart', value: 'motor_cart' },
        { label: 'pull cart', value: 'pull_cart' },
        { label: 'rental', value: 'rental' },
        { label: 'fitting', value: 'fitting' },
        { label: 'pro shop', value: 'pro_shop' },
        { label: 'lessons', value: 'lessons' },
        { label: 'caddie hire', value: 'caddie_hire' },
        { label: 'restaurant', value: 'restaurant' },
        { label: 'reception hall', value: 'reception_hall' },
        { label: 'changing room', value: 'changing_room' },
        { label: 'lockers', value: 'lockers' },
        { label: 'lodging on site', value: 'lodging_on_site' },
      ],
    },
  },
  specialField: {
    product: {
      id: 'id',
      name: 'name',
      avatarUrl: 'avatarImageUrl',
      buttonConfig: {
        label: 'Book Now',
      },
      getSubTitle: (product, classes) => (
        <Typography variant='caption' className={classes.productContent}>
          {product.address}
        </Typography>
      ),
      getRegularPrice: product =>
        Math.max(product.twilightPrice, product.weekendPrice, product.weekdayPrice).toLocaleString(
          'en'
        ),
      getSalePrice: product =>
        isWeekend()
          ? Math.min(
              product.weekendPrice,
              product.weekdayPrice,
              product.twilightPrice
            ).toLocaleString('en')
          : undefined,
      href: 'product-detail/golf-course',
    },
    props: {
      isRating: true,
    },
  },

  dataKey: 'golfCourseList',

  actions: { queryData: queryGolfList },
};
