import useLangHistory from 'hooks/useLangHistory';
import React from 'react';
import SplitImageCarousel from './SplitImageCarousel';
import SplitImageFrame from './SplitImageFrame';
import './styles.scss';

const SplitImage = () => {
  const { push } = useLangHistory();

  return (
    <div className='SplitImageBanner'>
      <SplitImageFrame
        position='top'
        imgUrl='/img/Banner/golfcourse.jpg'
        title='Choose your destination'
        buttonIcon='/img/Banner/map.png'
        buttonText='Choose your destination'
        buttonProps={{
          id: 'btn-home-banner-choose-your-destination',
          onClick: () => push('products/golf-tour'),
        }}
      />
      <SplitImageFrame
        position='bottom'
        imgUrl='/img/Banner/golfcourse.jpg'
        title='The best golf course'
        buttonIcon='/img/Banner/birdie.png'
        buttonText='Pick your course'
        buttonProps={{
          id: 'btn-home-banner-pick-your-course',
          onClick: () => push('products/golf-course'),
        }}
      />
      <SplitImageCarousel
        sliders={[
          { imgUrl: '/img/Banner/golfcourse.jpg' },
          { imgUrl: '/img/Banner/golfcourse.jpg' },
          { imgUrl: '/img/Banner/golfcourse.jpg' },
        ]}
      />
    </div>
  );
};

export default SplitImage;
