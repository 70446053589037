import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// Sections

import image from 'assets/image/golf-may.jpg';

// redux
import { ReducerStatus } from 'constants/Enums';
import { useDispatch, useSelector } from 'react-redux';
import { postActivated } from 'reduxs/global-actions';
import styles from './styles';

const useStyles = makeStyles(styles);

function ActivatedPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeUser = useSelector(state => state.activeUser);

  useEffect(() => {
    dispatch(postActivated({ uuid: props.match.params.uuid }));
  }, []);

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent='center'>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
              {activeUser.isActive && activeUser.status === ReducerStatus.SUCCESS ? (
                <h1>Activate account success</h1>
              ) : (
                <h1>Activate account failed</h1>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

ActivatedPage.propTypes = {
  match: PropTypes.object,
};

export default ActivatedPage;
