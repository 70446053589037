/* MODULES */
import { Container, Typography, makeStyles } from '@material-ui/core';

/* COMPONENTS */
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MainFrame from 'components/MainFrame';
import ProductFlexCard from 'components/ProductFlexCard';
import { ReducerStatus } from 'constants/Enums';
import useCategories from 'hooks/useCategories';
import useLangHistory from 'hooks/useLangHistory';
import { useContext } from 'react';
import { getActionId, getLangString } from 'utils/common';
import FilterSideBar from './components/FilterSideBar';
import SearchInput from './components/SearchInput';

/* STYLES */
import { ProductListPageContext } from '.';
import { getProductTitle } from './ProductListPage.Helper';
import styles from './styles';

const useStyles = makeStyles(styles);

const ProductListPageView = () => {
  const { push } = useLangHistory();
  const { getParentPath } = useCategories();
  const classes = useStyles();
  const { lang } = window;
  const { appProduct, productList, productMap, currentCategory } =
    useContext(ProductListPageContext);
  const {
    specialField: {
      product: { name, avatarUrl, buttonConfig, getSubTitle, getRegularPrice, getSalePrice, href },
      props,
    },
    filters,
  } = productMap;
  const { status, data } = productList;
  const isSuccess = status === ReducerStatus.SUCCESS;
  const notIncludeFilters = filters == null || Object.keys(filters).length === 0;
  const title = `"${getProductTitle(appProduct, getLangString(currentCategory?.name))}"`;

  return (
    <MainFrame className={classes.main}>
      <Container className={classes.container}>
        <SearchInput />

        <GridContainer>
          {notIncludeFilters ? null : (
            <GridItem md={2}>
              <FilterSideBar />
            </GridItem>
          )}

          <GridItem md={notIncludeFilters ? 12 : 10}>
            <h2 className={classes.productName}>{title}</h2>
            <GridContainer>
              {isSuccess &&
                data?.map(product => (
                  <GridItem key={product.name} md={4} className={classes.productCardContainer}>
                    <ProductFlexCard
                      avatarImgUrl={product[avatarUrl]}
                      contentChildren={
                        <>
                          <Typography
                            variant='subtitle1'
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 3,
                            }}
                            className={classes.productTitle}
                          >
                            {getLangString(product[name], lang)}
                          </Typography>
                          {getSubTitle?.(product, classes, lang) ?? null}
                        </>
                      }
                      regularPrice={getRegularPrice?.(product)}
                      salePrice={getSalePrice?.(product)}
                      buttonLabel={buttonConfig.label}
                      buttonProps={{
                        id: getActionId('btn', 'product-card', getLangString(product[name], lang)),
                        onClick: () => {
                          push(`${href}/${getParentPath(product.categoryId, product.customPath)}`);
                        },
                      }}
                      {...props}
                    />
                  </GridItem>
                ))}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </MainFrame>
  );
};

export default ProductListPageView;
