import React, { useCallback, useContext, useMemo } from 'react';

// @material-ui/core components
import { Box, Drawer, Hidden, Tab, Tabs, makeStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import RegularButton from 'components/CustomButtons';
import { ReducerStatus } from 'constants/Enums';
import { useTranslation } from 'react-i18next';
import { getLangString } from 'utils/common';
import { HeaderDropdownContext } from '../..';
import TabPanel from './TabPanel';
import styles from './styles';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);

const DrawerDropdown = () => {
  const { lang } = window;
  const { t } = useTranslation();
  const classes = useStyles();
  const parentContext = useContext(HeaderDropdownContext);
  const { onClick, anchorEl, setAnchorEl, categories, href } = parentContext;

  const [value, setValue] = React.useState(0);

  const handleClose = param => {
    setAnchorEl(param);
    onClick?.(param);
  };

  const handleCloseAway = event => {
    if (!anchorEl?.contains?.(event.target)) {
      handleClose(null);
    }
    setValue(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const parentTabData = useMemo(() => {
    const { data, status } = categories || {};
    if (data == null || status !== ReducerStatus.SUCCESS) return [];
    const result = data
      .filter(it => it.ancestorIds.length === 0)
      .map(el => ({
        name: el.id,
        label: t(el.name),
        path: el.path,
      }));

    return result;
  }, [categories]);

  const getTabItemData = useCallback(
    tabId => categories?.data?.filter(it => it.ancestorIds.includes(tabId)) ?? [],
    [parentTabData]
  );

  return (
    <Drawer
      open={Boolean(anchorEl)}
      anchor='top'
      variant='temporary'
      className={classes.drawer}
      PaperProps={{ className: classes.paper }}
      onClose={() => handleClose(null)}
    >
      <ClickAwayListener onClickAway={handleCloseAway}>
        <Box>
          <Hidden smUp>
            <div className={classes.boxHeader}>
              <RegularButton color='transparent' onClick={handleCloseAway}>
                <CloseIcon className={classes.closeIcon} />
              </RegularButton>
            </div>
          </Hidden>
          <div className={classes.boxContent}>
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
            >
              {parentTabData.map((el, idx) => (
                <Tab key={el.name} label={getLangString(el.label)} {...a11yProps(idx)} />
              ))}
            </Tabs>
            {parentTabData.map((el, idx) => (
              <TabPanel
                key={el.name}
                value={value}
                index={idx}
                tabItemData={getTabItemData(el.name)}
                href={href + `/${el.path[lang]}`}
              />
            ))}
          </div>
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
};

export default DrawerDropdown;
