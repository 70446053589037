import { Container, Typography } from '@material-ui/core';
import MainFrame from 'components/MainFrame';
import ReducerHelmet from 'components/ReducerHelmet';
import { META_TAG_OPTION, META_TAG_PAGE, META_TAG_PRODUCT_TYPE } from 'constants/MetaTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const PrivacyPolicyContent = [
  {
    topic: 'Privacy Policy',
    topicContents: [
      {
        title: 'Purpose',
        content: 'PRIVACY_POLICY_PURPOSE_CONTENT',
      },
      {
        title: 'Information Collection and Use',
        content: 'INFORMATION_COLLECTION_AND_USE_CONTENT',
      },
      {
        title: 'Security',
        content: 'SECURITY_CONTENT',
      },
    ],
  },
  {
    topic: 'Terms and Conditions of Use',
    topicContents: [
      {
        title: 'Purpose',
        content: 'TERMS_AND_CONDITIONS_OF_USE_PURPOSE_CONTENT',
      },
      {
        title: 'Intellectual Property Rights',
        content: 'INTELLECTUAL_PROPERTY_RIGHTS_CONTENT',
      },
      {
        title: 'Limitation of Liability',
        content: 'LIMITATION_OF_LIABILITY_CONTENT',
      },
    ],
  },
  {
    topic: 'Cookie Policy',
    topicContents: [
      {
        title: 'Purpose',
        content: 'COOKIE_POLICY_PURPOSE_CONTENT',
      },
      {
        title: 'Cookies',
        content: 'COOKIES_CONTENT',
      },
      {
        title: 'Cookie Management',
        content: 'SECURITY_CONTENT',
      },
    ],
  },
  {
    topic: 'Feedback Policy',
    topicContents: [
      {
        title: 'Purpose',
        content: 'FEEDBACK_POLICY_PURPOSE_CONTENT',
      },
      {
        title: 'Feedback',
        content: 'INFORMATION_COLLECTION_AND_USE_CONTENT',
      },
      {
        title: 'Contact',
        content: 'SECURITY_CONTENT',
      },
    ],
  },
  {
    topic: 'Security Policy',
    topicContents: [
      {
        title: 'Purpose',
        content: 'SECURITY_POLICY_PURPOSE_CONTENT',
      },
      {
        title: 'Security Measures',
        content: 'INFORMATION_COLLECTION_AND_USE_CONTENT',
      },
    ],
  },
];

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <ReducerHelmet
        option={META_TAG_OPTION.PAGE}
        page={META_TAG_PAGE.PRIVACY_POLICY}
        productType={META_TAG_PRODUCT_TYPE.PRIVACY_POLICY_PAGE}
        id='privacy_policy_page'
      />
      <MainFrame className='privacy-policy'>
        <Container className='privacy-policy-container'>
          {PrivacyPolicyContent.map((content, index) => (
            <div
              id={content.topic.toLowerCase().replace(/ /g, '-')}
              key={content.topic}
              className='privacy-policy-topic'
            >
              <Typography variant='h3'>
                {index + 1}. {t(content.topic)}
              </Typography>
              {content.topicContents.map(topic => (
                <div key={topic.title} className='topic-content-container'>
                  <Typography variant='h5'>{t(topic.title)}:</Typography>
                  <Typography variant='body1'>{t(topic.content)}</Typography>
                </div>
              ))}
            </div>
          ))}
        </Container>
      </MainFrame>
    </>
  );
};

export default PrivacyPolicy;
