import {
  LOCATION_CITY_QUERY_LIST_DATA,
  LOCATION_COUNTRY_QUERY_LIST_DATA,
  LOCATION_REGION_QUERY_LIST_DATA,
  LOCATION_STATE_QUERY_LIST_DATA,
} from 'reduxs/actions';
import { generateAction } from 'utils/createApiSaga';

export const queryRegionList = generateAction(LOCATION_REGION_QUERY_LIST_DATA);

export const queryCountryList = generateAction(LOCATION_COUNTRY_QUERY_LIST_DATA);

export const queryStateList = generateAction(LOCATION_STATE_QUERY_LIST_DATA);

export const queryCityList = generateAction(LOCATION_CITY_QUERY_LIST_DATA);
