import { colors, container } from 'assets/jss/global-styles';

export default {
  main: {
    backgroundColor: colors.WHITE,
    marginTop: '2rem',
    padding: '5rem 0 3rem 0 !important',
  },
  container,

  productCardContainer: {
    marginTop: '2rem',
    paddingRight: 0,
  },

  productName: {
    color: colors.EERIE_BLACK,
    fontFamily: 'Montserrat',
    fontSize: '34px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: '0.25px',
  },

  productTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal', // Sử dụng 'normal' để tránh cắt ngắn và hiển thị '...'
    maxHeight: 60,
    fontWeight: '500',
  },

  productContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    maxHeight: 60,
  },
};
