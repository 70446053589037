import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import { Drawer } from '@material-ui/core';
import MainFrame from 'components/MainFrame';
import { useDispatch, useSelector } from 'react-redux';
import { headerVisibleMobileDraw } from 'reduxs/global-actions';
import HeaderLinks from './HeaderLink';
import HeaderLogo from './HeaderLogo';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function Header({ changeColorOnScroll, color, fixed, absolute }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const visibleMobileDrawer = useSelector(state => state.visibleMobileDrawer);
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  const headerColorChange = () => {
    const windowsScrollTop = window.scrollY;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }

    return () => {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    dispatch(headerVisibleMobileDraw({ visible: !visibleMobileDrawer.visible }));
  };

  const hideMobileDraw = () => {
    dispatch(headerVisibleMobileDraw({ visible: false }));
  };

  return (
    <MainFrame>
      <AppBar className={appBarClasses}>
        {/* DESKTOP */}
        <Toolbar className={classes.container}>
          <HeaderLogo />

          <Hidden smDown>
            <HeaderLinks />
          </Hidden>

          <Hidden mdUp implementation='css'>
            <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>

        {/* MOBILE */}
        <Hidden mdUp>
          <Drawer
            anchor='right'
            variant='temporary'
            open={visibleMobileDrawer.visible}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={hideMobileDraw}
          >
            <div className={classes.appResponsive}>
              <HeaderLinks />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </MainFrame>
  );
}

Header.defaultProp = {
  color: 'white',
};

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
    'platinum',
  ]),
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'rose',
      'dark',
    ]).isRequired,
  }),
};
