import { FAILURE, REQUEST, SUCCESS } from 'reduxs/action-type-utils';
import { ORDER_GOLF_CART, ORDER_GOLF_CONFIRM } from 'reduxs/actions';

const initialState = {
  status: {},
};

// eslint-disable-next-line import/prefer-default-export
export const OrderGolfData = (state = initialState, action) => {
  switch (action.type) {
    /* REQUEST */
    case REQUEST(ORDER_GOLF_CONFIRM):
    case REQUEST(ORDER_GOLF_CART):
      return {
        status: { isLoading: true },
      };

    /* FAILED */
    case FAILURE(ORDER_GOLF_CONFIRM):
    case FAILURE(ORDER_GOLF_CART):
      return {
        status: { isFailed: true },
      };

    /* SUCCESS */
    case SUCCESS(ORDER_GOLF_CONFIRM):
    case SUCCESS(ORDER_GOLF_CART):
      return {
        status: { isSuccess: true },
      };

    default:
      return state;
  }
};
