import { useCallback } from 'react';

const useHandlers = input => {
  const object = Object.keys(input);

  return (
    object.length > 0 &&
    object.reduce((handlers, keyHandler) => {
      if (typeof input[keyHandler] === 'function') {
        // eslint-disable-next-line no-param-reassign
        handlers[keyHandler] = useCallback(input[keyHandler], []);
      } else {
        // eslint-disable-next-line no-param-reassign
        handlers[keyHandler] = input[keyHandler];
      }

      return handlers;
    }, {})
  );
};

export default useHandlers;
