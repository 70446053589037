import React, { createContext, useEffect, useMemo, useState } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

// core components
import Button from 'components/CustomButtons';

import { useSelector } from 'react-redux';
import DrawerDropdown from './components/DrawerDropdown';
import PopperDropdown from './components/PopperDropdown';
import styles from './styles';

const useStyles = makeStyles(styles);

export const MENU_TYPE = {
  LINK: 'LINK',
  HASH_LINK: 'HASH_LINK',
  DRAWER_DROPDOWN: 'DRAWER',
  POPPER_DROPDOWN: 'POPPER',
};

export const HeaderDropdownContext = createContext({});

export default function HeaderDropdown(props) {
  const { buttonText, ButtonIcon, buttonProps, caret, rtlActive, drawer } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const categories = useSelector(state => state.categories);
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: Boolean(anchorEl),
    [classes.caretRTL]: rtlActive,
  });

  useEffect(() => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  }, [window.location.pathname]);

  const handleClick = event => {
    setAnchorEl(anchorEl?.contains?.(event.target) ? null : event.currentTarget);
  };

  const getIcon = () => {
    if (ButtonIcon === undefined) return null;

    const iconMap = {
      object: <ButtonIcon className={classes.ButtonIcon} />,
      string: <Icon className={classes.ButtonIcon}>{ButtonIcon}</Icon>,
    };

    return iconMap[typeof ButtonIcon] ?? null;
  };

  const contextValues = useMemo(
    () => ({ ...props, categories, anchorEl, setAnchorEl }),
    [props, categories, anchorEl, setAnchorEl]
  );

  return (
    <>
      <Button
        aria-label='Notifications'
        aria-owns={anchorEl ? 'menu-list' : null}
        aria-haspopup='true'
        {...buttonProps}
        onClick={handleClick}
      >
        {getIcon()}
        {buttonText ?? null}
        {caret ? <b className={caretClasses} /> : null}
      </Button>

      {/* WHITE PAPER */}
      <HeaderDropdownContext.Provider value={contextValues}>
        {drawer ? <DrawerDropdown /> : <PopperDropdown />}
      </HeaderDropdownContext.Provider>
    </>
  );
}

HeaderDropdown.defaultProps = {
  caret: true,
  hoverColor: 'primary',
  drawer: false,
};

HeaderDropdown.propTypes = {
  hoverColor: PropTypes.oneOf(['black', 'primary', 'info', 'success', 'warning', 'danger', 'rose']),
  buttonText: PropTypes.node,
  ButtonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dropdownList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  onClick: PropTypes.func,
  useEventClick: PropTypes.bool,
  drawer: PropTypes.bool,
  applyRedux: PropTypes.shape({
    action: PropTypes.func,
    reducer: PropTypes.string,
  }),
  href: PropTypes.string,
};
