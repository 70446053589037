import { useRef } from 'react';

const useConstructor = actions => {
  const isLoaded = useRef(false);

  if (!isLoaded.current) {
    actions();
    isLoaded.current = true;
  }
};

export default useConstructor;
