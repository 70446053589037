import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { getKey } from 'utils/localStorage';

// Page
import ActivatedPage from 'views/ActivatedPage';
import AuthenticationPage from 'views/AuthenticationPage';
import CompleteBookingModal from 'views/CompleteBookingPage';
import DetailBlogPage from 'views/DetailPage/BlogDetail';
import DetailGolfPage from 'views/DetailPage/GolfCourseDetailPage';
import DetailGolfTourPage from 'views/DetailPage/GolfTourDetailPage';
import DetailTravelPage from 'views/DetailPage/TravelDetailPage';
import HomePage from 'views/HomePage';
import PaymentInfoPage from 'views/PaymentInfoPage';
import PrivacyPolicy from 'views/PrivacyPolicy';
import ProductListingPage from 'views/ProductListPage';

// Component
import Header from 'components/Header';
import HeaderLinks from 'components/Header/HeaderLink';
import HeaderLogo from 'components/Header/HeaderLogo';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getGeoLocation, initLocalization, queryGolfTourCategoryList } from 'reduxs/global-actions';
import store from 'reduxs/store';
// config
import Footer from 'components/Footer/Footer';
import configs from 'configs';
import { useTranslation } from 'react-i18next';
import { cachingParam, generateRoute, getRoutePath } from 'utils/DOM';
import { getBaseLang } from 'utils/common';

store.dispatch(initLocalization());

// Handle event
// ReactGA.event({
//   category: 'User Interaction
//   action: 'Clicked Button',',
//   label: 'Homepage Button',
// });

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector(state => state.authData);
  if (
    !sessionStorage.getItem('accountHasBeenFetched') &&
    (getKey('access_token') || sessionStorage.getItem('access_token'))
  ) {
    sessionStorage.setItem('accountHasBeenFetched', true);
  }

  useEffect(() => {
    ReactGA.initialize(configs.ga);
    dispatch(getGeoLocation());
    dispatch(queryGolfTourCategoryList());

    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
  }, []);

  useEffect(() => {
    ReactGA.send({ page: window.location.pathname + window.location.search });
  }, [window.location]);

  useEffect(() => {
    const historyListener = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return historyListener;
  }, [history]);

  if (getBaseLang() == null) {
    window.open('/vi', '_self');
    return null;
  }

  return (
    <>
      <Header leftLinks={<HeaderLogo />} rightLinks={<HeaderLinks />} fixed color='platinum' />

      <Switch>
        {generateRoute([], HomePage)}
        {generateRoute(
          ['routes.product-detail', 'routes.golf-course', ':productPath'],
          DetailGolfPage
        )}
        {generateRoute(['routes.product-detail', 'routes.blog', ':productPath'], DetailBlogPage)}
        {generateRoute(
          ['routes.product-detail', 'routes.golf-tour', ':region', ':country', ':productPath'],
          DetailGolfTourPage
        )}
        {generateRoute(
          [
            'routes.product-detail',
            'routes.golf-tour',
            ':region',
            ':country',
            ':city',
            ':productPath',
          ],
          DetailGolfTourPage
        )}
        {generateRoute(
          ['routes.product-detail', 'routes.travel', ':region', ':country', ':productPath'],
          DetailTravelPage
        )}
        {generateRoute(
          [
            'routes.product-detail',
            'routes.travel',
            ':region',
            ':country',
            ':city',
            ':productPath',
          ],
          DetailTravelPage
        )}
        {generateRoute(
          [
            'routes.product-detail',
            'routes.travel',
            ':region',
            ':country',
            ':city',
            ':productPath',
          ],
          DetailTravelPage
        )}
        {generateRoute(
          ['routes.products', ':appProduct', ':region', ':country', ':city'],
          ProductListingPage
        )}
        {generateRoute(['routes.verify', ':uuid'], ActivatedPage)}
        {generateRoute(['routes.complete-booking'], CompleteBookingModal)}
        {generateRoute(['routes.payment-info'], PaymentInfoPage)}
        {generateRoute(['routes.privacy-policy'], PrivacyPolicy)}

        <RestrictedRoute
          path={getRoutePath(t('routes.login'))}
          exact
          component={AuthenticationPage}
          isRestricted={!!authData.data?.accessToken}
        />

        <Route
          path='/*'
          exact
          render={props => {
            // eslint-disable-next-line react/prop-types
            cachingParam(props?.match?.params);
            return <Redirect to={{ pathname: '/' }} />;
          }}
        />
      </Switch>
      {!window.location.pathname.includes(t('routes.login')) && <Footer whiteFont />}
      <ScrollToTop />
    </>
  );
}

export default App;
