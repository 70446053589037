import { APP_PRODUCT } from 'constants/Enums';
import blogMap from './blogMap';
import golfCourseMap from './golfCourseMap';
import golfTourMap from './golfTourMap';
import travelMap from './travelMap';

export default {
  [APP_PRODUCT.GOLF_COURSE.EN]: golfCourseMap,
  [APP_PRODUCT.GOLF_COURSE.VI]: golfCourseMap,
  [APP_PRODUCT.GOLF_COURSE.ZH]: golfCourseMap,

  [APP_PRODUCT.GOLF_TOUR.EN]: golfTourMap,
  [APP_PRODUCT.GOLF_TOUR.VI]: golfTourMap,
  [APP_PRODUCT.GOLF_TOUR.ZH]: golfTourMap,

  [APP_PRODUCT.BLOG.EN]: blogMap,
  [APP_PRODUCT.BLOG.VI]: blogMap,
  [APP_PRODUCT.BLOG.ZH]: blogMap,

  [APP_PRODUCT.TRAVEL.EN]: travelMap,
  [APP_PRODUCT.TRAVEL.VI]: travelMap,
  [APP_PRODUCT.TRAVEL.ZH]: travelMap,
};
